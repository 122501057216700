import styled from "styled-components/macro";

export default styled.select`
  font-size: 14px;
  padding: 8px 16px;
  color: #4d4d4d;
  line-height: 1.43;
  border-radius: 4px;
  border: solid 1px #b3b3b3;
  background-color: #ffffff;
  width: 100%;
  display: inline-block;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background-repeat: no-repeat no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEzLjQxNCAxNS42NTdMMTkuMDcxIDEwbC0xLjQxNC0xLjQxNEwxMiAxNC4yNDMgNi4zNDMgOC41ODYgNC45MyAxMCAxMiAxNy4wNzFsMS40MTQtMS40MTR6Ii8+Cjwvc3ZnPgo=");

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border: 1px solid #aaa;
  }

  & option {
    font-weight: 500;
  }
`;
