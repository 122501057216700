import React, { useCallback, useState } from "react";
import { performCompanyAssetsPost, performHostnamesResolutionPost } from "../../api";
import { withDefaultErrorHandling } from "../../utils/error-handling";
import AddAssetsStep1Dialog from "./AddAssetsStep1Dialog";
import AddAssetsStep2Dialog from "./AddAssetsStep2Dialog";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../components/Toast";

export default ({ companyId, onFinish, onCancel }) => {
  const [ipsFromStep1, setIpsFromStep1] = useState(undefined);
  const [ipRangesFromStep1, setIpRangesFromStep1] = useState(undefined);
  const [subnetsFromStep1, setSubnetsFromStep1] = useState(undefined);
  const [assetsForStep2, setAssetsForStep2] = useState(undefined);

  const submitAssets = useCallback(
    async (assets, ipRanges, subnets) => {
      try {
        const response = await performCompanyAssetsPost(companyId, { assets, ipRanges, subnets });

        if (response.warnings.duplicatesCount > 0) {
          showWarningToast(`${response.warnings.duplicatesCount} duplicate ip addresses were skipped.`);
        }

        showSuccessToast(`${response.data.length} projects were added!`);
        onFinish();
      } catch (error) {
        showErrorToast(error.message);
      }
    },
    [onFinish, companyId]
  );

  const handleStep1Submit = useCallback(
    async (ips, ipRanges, subnets, hostnames) =>
      await withDefaultErrorHandling(async () => {
        if (hostnames.length > 0) {
          setIpsFromStep1(ips);
          setIpRangesFromStep1(ipRanges);
          setSubnetsFromStep1(subnets);

          const hostnamesRequestData = hostnames.map(hostname => ({ hostname }));

          const response = await performHostnamesResolutionPost(hostnamesRequestData);
          setAssetsForStep2(response.data);
        } else {
          const assets = ips.map(ip => ({ ip, hostnames: [] }));
          await submitAssets(assets, ipRanges, subnets);
        }
      }),
    [submitAssets]
  );

  const handleStep2Submit = useCallback(
    async additionalIpsAndHostnamePairs => {
      const assets = ipsFromStep1.map(ip => ({ ip, hostnames: [] }));

      additionalIpsAndHostnamePairs.forEach(({ ip, hostname }) => {
        const knownAsset = assets.find(asset => asset.ip === ip);

        if (knownAsset) {
          knownAsset.hostnames.push(hostname);
        } else {
          assets.push({ ip, hostnames: [hostname] });
        }
      });

      await submitAssets(assets, ipRangesFromStep1, subnetsFromStep1);
    },
    [ipsFromStep1, ipRangesFromStep1, subnetsFromStep1, submitAssets]
  );

  return (
    <React.Fragment>
      {!assetsForStep2 && <AddAssetsStep1Dialog onSubmit={handleStep1Submit} onCancel={onCancel} />}
      {assetsForStep2 && (
        <AddAssetsStep2Dialog assets={assetsForStep2} onSubmit={handleStep2Submit} onCancel={onCancel} />
      )}
    </React.Fragment>
  );
};
