import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import styled from "styled-components/macro";
import dragImagePath from "../../assets/images/drag.svg";
import ContentBlock, { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import LoadingContainer from "../../components/containers/LoadingContainer";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import Main from "../../components/layout/Main";
import { loadWidgetTypesRequest, saveEnabledWidgetsRequest } from "../../redux/settings/actions";
import { arrayCopyWithMovedElement, arrayCopyWithoutElement } from "../../utils/collections";
import { pageTitle } from "../../utils/ui";
import useActiveUser from "../../utils/useActiveUser";

const WidgetSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WidgetList = styled.div`
  flex: 0 0 45%;
`;

const WidgetListHeader = styled.h3`
  font-size: 20px;
  font-weight: 600;
`;

const WidgetOption = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 16px;
  margin-bottom: 3px;
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const DragHandleContainer = styled.div`
  margin-right: 16px;
  cursor: pointer;
`;

const DragHandleImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const SortableWidgetDragHandle = SortableHandle(() => (
  <DragHandleContainer>
    <DragHandleImage src={dragImagePath} />
  </DragHandleContainer>
));

const SortableWidgetOption = SortableElement(({ index, value, onClick }) => (
  <WidgetOption onClick={() => onClick(index)}>
    <SortableWidgetDragHandle />
    <span>{value.name}</span>
  </WidgetOption>
));

const SortableWidgetOptionsList = SortableContainer(({ onClick, items }) => {
  return (
    <div>
      {items.map((widgetType, index) => (
        <div key={`selected-${widgetType.name}`}>
          <SortableWidgetOption onClick={onClick} index={index} value={widgetType} />
        </div>
      ))}
    </div>
  );
});

const DashboardSettingsPage = ({
  allWidgetTypes,
  enabledWidgets,
  loading,
  loadingError,
  saving,
  savingError,
  loadWidgetTypes,
  saveEnabledWidgets
}) => {
  const userId = useActiveUser().id;

  const [availableWidgetTypes, setAvailableWidgetTypes] = useState([]);
  const [selectedWidgetTypes, setSelectedWidgetTypes] = useState([]);

  useEffect(() => {
    loadWidgetTypes();
  }, [loadWidgetTypes]);

  useEffect(() => {
    if (savingError) {
      window.alert(JSON.stringify(savingError));
    }
  }, [savingError]);

  useEffect(() => {
    if (allWidgetTypes === null || enabledWidgets === null) {
      return;
    }

    const selectedWidgetTypes = enabledWidgets.map(widget =>
      allWidgetTypes.find(widgetType => widgetType.name === widget)
    );
    const availableWidgetTypes = allWidgetTypes.filter(widgetType => !selectedWidgetTypes.includes(widgetType));

    setSelectedWidgetTypes(selectedWidgetTypes);
    setAvailableWidgetTypes(availableWidgetTypes);
  }, [allWidgetTypes, enabledWidgets]);

  const selectWidgetType = useCallback(
    index => {
      const widgetType = availableWidgetTypes[index];

      setAvailableWidgetTypes(arrayCopyWithoutElement(availableWidgetTypes, widgetType));
      setSelectedWidgetTypes([...selectedWidgetTypes, widgetType]);
    },
    [availableWidgetTypes, selectedWidgetTypes]
  );

  const deselectWidgetType = useCallback(
    index => {
      const widgetType = selectedWidgetTypes[index];

      setSelectedWidgetTypes(arrayCopyWithoutElement(selectedWidgetTypes, widgetType));
      setAvailableWidgetTypes([...availableWidgetTypes, widgetType]);
    },
    [availableWidgetTypes, selectedWidgetTypes]
  );

  const reorderSelectedWidgetType = useCallback(
    ({ oldIndex, newIndex }) => {
      setSelectedWidgetTypes(arrayCopyWithMovedElement(selectedWidgetTypes, oldIndex, newIndex));
    },
    [selectedWidgetTypes]
  );

  const saveSettings = useCallback(
    event => {
      event.preventDefault();

      saveEnabledWidgets(userId, selectedWidgetTypes);
    },
    [userId, selectedWidgetTypes, saveEnabledWidgets]
  );

  const title = "Dashboard Widgets";

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle(title)}</title>
      </Helmet>

      <Main>
        <form onSubmit={saveSettings}>
          <ContentBlock>
            <ContentBlockTitle>{title}</ContentBlockTitle>

            <ContentSection>
              <LoadingContainer
                loading={loading}
                hasError={!!loadingError}
                renderError={() => <div>Got error {JSON.stringify(loadingError)}</div>}
                renderData={() => (
                  <WidgetSelectionContainer>
                    <WidgetList>
                      <WidgetListHeader>Available widgets</WidgetListHeader>

                      {availableWidgetTypes.map((widgetType, index) => (
                        <WidgetOption onClick={() => selectWidgetType(index)} key={`available-${widgetType.name}`}>
                          {widgetType.name}
                        </WidgetOption>
                      ))}
                    </WidgetList>

                    <WidgetList>
                      <WidgetListHeader>Selected widgets</WidgetListHeader>

                      <SortableWidgetOptionsList
                        useDragHandle={true}
                        lockAxis="y"
                        items={selectedWidgetTypes}
                        onClick={deselectWidgetType}
                        onSortEnd={reorderSelectedWidgetType}
                      />
                    </WidgetList>
                  </WidgetSelectionContainer>
                )}
              />
            </ContentSection>

            <ContentSection>
              <ButtonsRow>
                <Button disabled={saving} as="input" accented={true} type="submit" value="Save" />
              </ButtonsRow>
            </ContentSection>
          </ContentBlock>
        </form>
      </Main>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.settings.widgetTypes.loading,
    loadingError: state.settings.widgetTypes.error,
    saving: state.settings.widgetSettings.saving,
    savingError: state.settings.widgetSettings.error,
    allWidgetTypes: state.settings.widgetTypes.allWidgetTypes,
    enabledWidgets: state.settings.widgetSettings.enabledWidgets
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadWidgetTypes: () => dispatch(loadWidgetTypesRequest()),
    saveEnabledWidgets: (userId, widgetTypes) => dispatch(saveEnabledWidgetsRequest(userId, widgetTypes))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardSettingsPage);
