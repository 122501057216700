import React from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';

// export default class CodeBlock extends React.PureComponent {
//   static propTypes = {
//     value: PropTypes.string.isRequired,
//     language: PropTypes.string,
//   }

//   static defaultProps = {
//     language: null,
//   }

//   render() {
//     const { language, value } = this.props;

//     return (
//       <SyntaxHighlighter 
//         language={language}
//         showLineNumbers={true}
//         attr={prop}
//         // lineProps={lineNumber => (
//         //   lineNumber == 6 ? {style: { font-weight: 'bold' }} : {style: {}}
//         //   )}
//         wrapLines={true}
//         lineProps={lineNumber => ({
//                 style: { backgroundColor: lineNumber == 7 ? 'yellow' : 'unset'}
//               })}
//       >
//         {value}
        
//       </SyntaxHighlighter>
//     );
//   }
// }



export default function CodeBlockFactory(line) {
return ({language, value}) =>  {
  return (
      <SyntaxHighlighter 
        language={language}
        showLineNumbers={true}
        startingLineNumber={Math.max(1, line-6)}
        // lineProps={lineNumber => (
        //   lineNumber == 6 ? {style: { font-weight: 'bold' }} : {style: {}}
        //   )}
        wrapLines={true}
        lineProps={lineNumber => ({
                style: { backgroundColor: lineNumber == 7 ? 'yellow' : 'unset'}
              })}
      >
        {value}
        
      </SyntaxHighlighter>
  );
}
}