import React from "react";
import styled from "styled-components/macro";
import Content from "./Content";

const Main = styled.main`
  padding: 40px 0;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export default ({ children }) => {
  return (
    <Main>
      <Content>{children}</Content>
    </Main>
  );
};
