import React, { useCallback } from "react";
import styled from "styled-components/macro";
import Checkbox from "../../components/controls/Checkbox";
import { DeleteButton, EditButton } from "../../components/controls/IconButtons";
import Spinner from "../../components/misc/Spinner";
import { USER_ROLE_BIZONE_ADMIN, userRoleToString } from "../../utils/enums";

const CardShape = styled.div`
  flex: 0 0 24%;
  padding: 20px 25px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;

  & > *:not(:last-of-type) {
    margin-right: 16px;
  }
`;

const CardName = styled.h2`
  font-size: 24px;
  line-height: 1.33;
  color: #1696cc;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CardBody = styled.p``;

const CardAnnotation = styled.p`
  color: #999999;
  margin-bottom: 24px;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

const CardActiveLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  margin-left: -6px;
  height: 20px;
`;

const CardUserActiveSpinnerWrapper = styled.span`
  margin: 0 8px 0 6px;
`;

export default ({ user, updatingActivation, loggedInUser, onActivationToggle, onEdit, onDelete }) => {
  const handleActivationCheckboxClick = useCallback(() => {
    onActivationToggle(user);
  }, [user, onActivationToggle]);

  const isAdmin = loggedInUser.role === USER_ROLE_BIZONE_ADMIN;
  const isCardForLoggedInUser = loggedInUser.id === user.id;

  return (
    <CardShape>
      <CardHeader>
        <CardTitle>{userRoleToString(user.role)}</CardTitle>

        <CardActions>
          {(isAdmin || isCardForLoggedInUser) && <EditButton onClick={() => onEdit(user)} />}
          {isAdmin && <DeleteButton onClick={() => onDelete(user)} />}
        </CardActions>
      </CardHeader>

      <CardAnnotation visible={!!user.companyContact}>Company contact</CardAnnotation>

      <CardName title={user.fullname}>{user.fullname}</CardName>

      <CardBody>{user.phoneNumber}</CardBody>
      <CardBody>{user.email}</CardBody>

      {isAdmin && (
        <CardActiveLabel>
          {updatingActivation ? (
            <CardUserActiveSpinnerWrapper>
              <Spinner size={6} />
            </CardUserActiveSpinnerWrapper>
          ) : (
            <Checkbox checked={user.active} onChange={handleActivationCheckboxClick} />
          )}

          {user.active ? "User is active" : "User is deactivated"}
        </CardActiveLabel>
      )}
    </CardShape>
  );
};
