import * as Observable from "rxjs";
import { catchError, map, mergeAll, switchMap } from "rxjs/operators";
import { ajaxPostUser } from "../../api";
import { storeUsers } from "../entities/actions";
import { ADD_USER_REQUEST, addUserFailure, addUserSuccess, closeAddUserDialog } from "./actions";

export const addUserEpic = action$ =>
  action$.ofType(ADD_USER_REQUEST).pipe(
    switchMap(action =>
      ajaxPostUser(action.userData).pipe(
        map(result => result.response.data),
        map(user => [storeUsers([user]), addUserSuccess(user), closeAddUserDialog()]),
        mergeAll(),
        catchError(error => Observable.of(addUserFailure(error.response)))
      )
    )
  );
