const ACTIVE_COMPANY_LOCAL_STORAGE_KEY = "bizone-active-company";
const ACTIVE_COMPANY_LOCAL_STORAGE_VERSION = 1;

export const tryToLoadActiveCompanyFromLocalStorage = () => {
  const activeCompanyJson = window.localStorage.getItem(ACTIVE_COMPANY_LOCAL_STORAGE_KEY);

  if (!activeCompanyJson) {
    return undefined;
  }

  try {
    const parsed = JSON.parse(activeCompanyJson);
    if (parsed.version || parsed.version === ACTIVE_COMPANY_LOCAL_STORAGE_VERSION) {
      return parsed.data;
    }
  } catch (error) {}

  return undefined;
};

export const saveActiveCompanyToLocalStorage = activeCompany => {
  window.localStorage.setItem(
    ACTIVE_COMPANY_LOCAL_STORAGE_KEY,
    JSON.stringify({
      version: ACTIVE_COMPANY_LOCAL_STORAGE_VERSION,
      data: activeCompany
    })
  );
};

export const clearLocalStorage = () => {
  window.localStorage.setItem(ACTIVE_COMPANY_LOCAL_STORAGE_KEY, "{}");
};
