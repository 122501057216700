import { saveActiveCompanyToLocalStorage, tryToLoadActiveCompanyFromLocalStorage } from "../local-storage";
import { CHOOSE_ACTIVE_COMPANY, STORE_AVAILABLE_COMPANIES } from "./actions";

const defaultState = {
  availableCompanies: undefined,
  activeCompany: tryToLoadActiveCompanyFromLocalStorage()
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STORE_AVAILABLE_COMPANIES: {
      const newlyAvailableCompanies = action.companies;

      const previouslyActiveCompany = state.activeCompany;
      let newActiveCompany = undefined;

      if (previouslyActiveCompany) {
        newActiveCompany = newlyAvailableCompanies.find(company => company.id === previouslyActiveCompany.id);
      }

      if (!newActiveCompany && newlyAvailableCompanies.length > 0) {
        newActiveCompany = newlyAvailableCompanies[0];
      }

      return {
        ...state,
        loadingAvailableCompanies: false,
        availableCompanies: newlyAvailableCompanies,
        activeCompany: newActiveCompany
      };
    }

    case CHOOSE_ACTIVE_COMPANY: {
      saveActiveCompanyToLocalStorage(action.company);
      return { ...state, activeCompany: action.company };
    }

    default: {
      return state;
    }
  }
};
