import React from "react";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import TopActionBar, { TopActionBarButtonsContainer, TopActionBarRight } from "../../components/layout/TopActionBar";

export default ({ onCreate }) => {
  return (
    <TopActionBar>
      <TopActionBarRight>
        <TopActionBarButtonsContainer>
          <ButtonsRow>
            <Button onClick={onCreate} accented={true} type="submit">
              Create API Key
            </Button>
          </ButtonsRow>
        </TopActionBarButtonsContainer>
      </TopActionBarRight>
    </TopActionBar>
  );
};
