import React from "react";
import { Helmet } from "react-helmet";
import TwoColumnLayout, { TwoColumnLayoutColumn } from "../../components/containers/TwoColumnLayout";
import Main from "../../components/layout/Main";
import { pageTitle } from "../../utils/ui";
import CompaniesList from "./CompaniesList";
import UsersList from "./UsersList";

export default () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle("Administration")}</title>
      </Helmet>

      <Main>
        <TwoColumnLayout>
          <TwoColumnLayoutColumn>
            <CompaniesList />
          </TwoColumnLayoutColumn>

          <TwoColumnLayoutColumn>
            <UsersList />
          </TwoColumnLayoutColumn>
        </TwoColumnLayout>
      </Main>
    </React.Fragment>
  );
};
