import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { deleteUserActivation, postUserActivation } from "../../api";
import LoadingContainer from "../../components/containers/LoadingContainer";
import Main from "../../components/layout/Main";
import Spinner from "../../components/misc/Spinner";
import { showErrorToast } from "../../components/Toast";
import {
  fetchCompanyRequest,
  fetchCompanyUsersRequest,
  toggleUserActivationSuccess
} from "../../redux/company-view/actions";
import { openEditUserDialog } from "../../redux/user-edit/actions";
import { deleteUserRequest } from "../../redux/user-list/actions";
import { arrayCopyWithElement, arrayCopyWithoutElement } from "../../utils/collections";
import { pageTitle } from "../../utils/ui";
import useActiveUser from "../../utils/useActiveUser";
import UserCard from "./UserCard";

const UserCardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 32%);
  justify-content: space-between;
`;

const PageHeader = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-right: 8px;
  }
`;

export default withRouter(({ match, history }) => {
  const companyId = +match.params.companyId;

  if (!companyId) {
    history.replace("/404");
  }

  const loggedInUser = useActiveUser();

  const companiesById = useSelector(state => state.entities.companiesById);
  const usersById = useSelector(state => state.entities.usersById);

  const companyLoading = useSelector(state => state.companyView.company.loading);
  const usersLoading = useSelector(state => state.companyView.users.loading);

  const companyError = useSelector(state => state.companyView.company.error);
  const usersError = useSelector(state => state.companyView.users.error);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanyRequest(companyId));
    dispatch(fetchCompanyUsersRequest(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyError && companyError.statusCode === 404) {
      history.replace("/404");
    }
  }, [companyError, history]);

  const handleUserDeletion = user => {
    if (window.confirm(`Are you sure you want to delete user "${user.fullname}"?`)) {
      dispatch(deleteUserRequest(user));
    }
  };

  // @TODO: Handle through Redux store and not locally.
  const [activationUpdatesUserIds, setActivationUpdatesUserIds] = useState([]);

  const handleUserActivationToggle = async user => {
    setActivationUpdatesUserIds(arrayCopyWithElement(activationUpdatesUserIds, user.id));

    const apiCall = user.active ? deleteUserActivation : postUserActivation;

    try {
      await apiCall(user.id);
      dispatch(toggleUserActivationSuccess(user.id, !user.active));
    } catch (error) {
      showErrorToast(`Unable to activate/deactivate a user. ${error.message}`);
    } finally {
      setActivationUpdatesUserIds(arrayCopyWithoutElement(activationUpdatesUserIds, user.id));
    }
  };

  const company = companiesById[companyId];

  const showWholePageLoadingIndicator = !company;
  const showUsersLoadingIndicator = company && company.userIds.length === 0 && usersLoading;
  const showLoadingIndicatorNearCompanyName = company && companyLoading;

  return (
    <Main>
      <LoadingContainer
        loading={showWholePageLoadingIndicator}
        hasError={!!companyError}
        renderError={() => <div>Got error {JSON.stringify(companyError)}</div>}
        renderData={() => {
          return (
            <React.Fragment>
              <Helmet>
                <title>{pageTitle(company.name)}</title>
              </Helmet>

              <PageHeader>
                <span>{company.name}</span>
                {showLoadingIndicatorNearCompanyName && <Spinner size={7} />}
              </PageHeader>

              <LoadingContainer
                loading={showUsersLoadingIndicator}
                hasError={!!companyError}
                renderError={() => <div>Got error {JSON.stringify(usersError)}</div>}
                renderData={() => {
                  const users = company.userIds.map(userId => usersById[userId]);

                  if (!usersLoading && users.length === 0) {
                    return <div>No users to show.</div>;
                  }

                  return (
                    <UserCardsGrid>
                      {users.map(user => (
                        <UserCard
                          key={user.id}
                          user={user}
                          loggedInUser={loggedInUser}
                          updatingActivation={activationUpdatesUserIds.includes(user.id)}
                          onActivationToggle={handleUserActivationToggle}
                          onEdit={() => dispatch(openEditUserDialog(user.id))}
                          onDelete={handleUserDeletion}
                        />
                      ))}
                    </UserCardsGrid>
                  );
                }}
              />
            </React.Fragment>
          );
        }}
      />
    </Main>
  );
});
