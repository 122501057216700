import React from "react";
import { css } from "styled-components";
import styled from "styled-components/macro";
import NavigationLink from "./NavigationLink";

const NavigationPopup = styled.div`
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 800;
  min-width: 100px;
  border-radius: 0 0 4px 4px;
  background: #ffffff;
  border: 1px solid #b3b3b3;
  border-top: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  max-height: 620px;
  overflow-y: scroll;
  transform: translateY(-3px);
  opacity: 0;
  transition: transform 0.15s ease-in-out;

  ${({ fixedWidth }) =>
    fixedWidth &&
    css`
      width: ${fixedWidth};
    `}
`;

const NavigationPopupMenuInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: stretch;
  justify-content: stretch;
  flex: 1 1 auto;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:hover ${NavigationPopup} {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;

export default ({ text, icon, children, fixedWidth }) => {
  return (
    <NavigationPopupMenuInner>
      <NavigationLink fixedWidth={fixedWidth} as="div">
        {icon}
        <span>{text}</span>
      </NavigationLink>
      <NavigationPopup fixedWidth={fixedWidth}>{children}</NavigationPopup>
    </NavigationPopupMenuInner>
  );
};
