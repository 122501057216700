import {
  CLOSE_EDIT_USER_DIALOG,
  OPEN_EDIT_USER_DIALOG,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
} from "./actions";

const initialState = {
  dialogOpen: false,
  userId: null,

  submitting: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_EDIT_USER_DIALOG: {
      return {
        ...state,
        dialogOpen: true,
        userId: action.userId,
        submitting: false,
        error: null
      };
    }

    case CLOSE_EDIT_USER_DIALOG: {
      return {
        ...state,
        dialogOpen: false,
        userId: null,
        submitting: false,
        error: null
      };
    }

    case UPDATE_USER_REQUEST: {
      return {
        ...state,
        userId: action.userId,
        submitting: true,
        error: null
      };
    }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        submitting: false,
        error: null
      };
    }

    case UPDATE_USER_FAILURE: {
      return {
        ...state,
        submitting: false,
        error: action.error
      };
    }

    default: {
      return state;
    }
  }
};
