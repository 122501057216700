import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;

  & > *:not(:last-of-type) {
    margin-right: 16px;
  }
`;
