export const arrayCopyWithElement = (array, element) => {
  const index = array.indexOf(element);
  return index === -1 ? array.concat(element) : [...array];
};

export const arrayCopyWithoutElement = (array, element) => {
  const index = array.indexOf(element);

  const newArray = [...array];

  if (index !== -1) {
    newArray.splice(index, 1);
  }

  return newArray;
};

export const arrayCopyWithMovedElement = (array, from, to) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};
