import React from "react";
import { Router, Redirect, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Main from "../../components/layout/Main";
import { pageTitle } from "../../utils/ui";
import AssetsAndVulnerabilitiesStatsContainer from "./widgets/AssetsAndVulnerabilitiesStatsContainer";
import EventsFeedWidget from "./widgets/EventsFeedWidget";
import OldestUnfixedVulnerabilitiesWidget from "./widgets/OldestUnfixedVulnerabilitiesWidget";
import RecentlyFixedVulnerabilitiesWidget from "./widgets/RecentlyFixedVulnerabilitiesWidget";

const mapWidgetTypeToWidgetComponent = (widgetType, companyId) => {
  const props = { companyId };

  switch (widgetType) {
    case "assets_vulnerabilities_changes":
      return <AssetsAndVulnerabilitiesStatsContainer {...props} />;
    case "oldest_not_fixed_vulnerabilities":
      return <OldestUnfixedVulnerabilitiesWidget {...props} />;
    case "recently_fixed_vulnerabilities":
      return <RecentlyFixedVulnerabilitiesWidget {...props} />;
    case "events_feed":
      return <EventsFeedWidget {...props} />;
    default:
      return null;
  }
};

export default withRouter(({history}) => {
  history.push("/assets/agreed");
  const companyId = useSelector(state =>
    state.companySwitcher.activeCompany ? state.companySwitcher.activeCompany.id : undefined
  );
  const enabledWidgets = useSelector(state => state.settings.widgetSettings.enabledWidgets);

  
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle("Dashboard")}</title>
      </Helmet>

      <Main>
        <h1>Dashboard</h1>

        {enabledWidgets.map((widgetType, index) => {
          return <div key={`widget-${index}`}>{mapWidgetTypeToWidgetComponent(widgetType, companyId)}</div>;
        })}
      </Main>
    </React.Fragment>
  );
});