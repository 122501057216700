import "normalize.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { ToastCloseButton } from "./components/Toast";
import "./global.css";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";

toast.configure({
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeButton: <ToastCloseButton />,
  className: "toasts-container",
  toastClassName: "toast-body",
  newestOnTop: true
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
