import React, { useEffect, useState } from "react";
import { performCompanyAssetsAndServicesAssetStatusStatsGet } from "../../api";
import HavingUserRolesExcept from "../../components/access/HavingUserRolesExcept";
import Button from "../../components/controls/Button";
import TopActionBar, { TopActionBarLeft, TopActionBarRight } from "../../components/layout/TopActionBar";
import Tab from "../../components/Tab";
import {
  ASSET_STATUS_APPROVED,
  ASSET_STATUS_APPROVED_BY_BIZONE,
  ASSET_STATUS_APPROVED_BY_CLIENT,
  ASSET_STATUS_NOT_APPROVED,
  USER_ROLE_COMPANY_EMPLOYEE,
  USER_ROLE_COMPANY_MANAGER,
  USER_ROLE_COMPANY_ADMIN
} from "../../utils/enums";

export default ({ onAddAssetsClick, userRole, companyId, reloadStats }) => {
  const [agreedCount, setAgreedCount] = useState(undefined);
  const [inCoordinationCount, setInCoordinationCount] = useState(undefined);

  useEffect(() => {
    const loadStats = async () => {
      try {
        const response = await performCompanyAssetsAndServicesAssetStatusStatsGet(companyId);
        const stats = response.data;

        setAgreedCount(stats[ASSET_STATUS_APPROVED]);
        setInCoordinationCount(
          stats[ASSET_STATUS_NOT_APPROVED] +
            stats[ASSET_STATUS_APPROVED_BY_BIZONE] +
            stats[ASSET_STATUS_APPROVED_BY_CLIENT]
        );
      } finally {
      }
    };

    if (reloadStats) {
      loadStats().then();
    }
  }, [companyId, reloadStats]);

  return (
    <TopActionBar>
      <HavingUserRolesExcept roles={[USER_ROLE_COMPANY_EMPLOYEE, USER_ROLE_COMPANY_MANAGER, USER_ROLE_COMPANY_ADMIN]} userRole={userRole}>
        <TopActionBarRight>
          <Button onClick={onAddAssetsClick} accented={true} type="submit">
            Add projects
          </Button>
        </TopActionBarRight>
      </HavingUserRolesExcept>
    </TopActionBar>
  );
};
