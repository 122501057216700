import { arrayCopyWithoutElement } from "../../utils/collections";
import { ADD_COMPANY_ADMIN_SUCCESS } from "../company-new/actions";
import { ADD_USER_SUCCESS } from "../user-add/actions";
import { DELETE_USER_SUCCESS, LOAD_ALL_USERS_FAILURE, LOAD_ALL_USERS_REQUEST, LOAD_ALL_USERS_SUCCESS } from "./actions";

const initialState = {
  loading: true,
  userIds: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL_USERS_REQUEST: {
      return {
        ...state,
        loading: true,
        userIds: null,
        error: null
      };
    }

    case LOAD_ALL_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userIds: action.users.map(user => user.id),
        error: null
      };
    }

    case LOAD_ALL_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        userIds: null,
        error: action.error
      };
    }

    case ADD_USER_SUCCESS: {
      return {
        ...state,
        userIds: [action.user.id, ...state.userIds]
      };
    }

    case ADD_COMPANY_ADMIN_SUCCESS: {
      return {
        ...state,
        userIds: [action.user.id, ...state.userIds]
      };
    }

    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        userIds: arrayCopyWithoutElement(state.userIds, action.userId)
      };
    }

    default: {
      return state;
    }
  }
};
