import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

const TabLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 18px 20px 16px;
  margin-right: 16px;
  font-size: 14px;
  color: #4d4d4d;
  position: relative;
  min-height: 56px;
  text-decoration: none;

  &:hover::after,
  &.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 4px;
    background: #18a8cc;
    transition: background 0.2s ease-in-out;
  }

  &:hover {
    text-decoration: none;

    &::after {
      background: #0c84cc;
    }
  }
`;

const TabCounterContainer = styled.div`
  margin-left: 8px;
  padding: 2px 8px;
  min-width: 32px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const TabCounter = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4d4d4d;
`;

export default ({ text, href, showCounter, counter }) => {
  return (
    <TabLink activeClassName="active" to={href}>
      <span>{text}</span>

      {showCounter && (
        <TabCounterContainer>
          <TabCounter>{counter}</TabCounter>
        </TabCounterContainer>
      )}
    </TabLink>
  );
};
