import * as Observable from "rxjs/index";
import { catchError, map, mergeAll, switchMap, tap } from "rxjs/operators/index";
import { ajaxDeleteUser, ajaxGetUsers } from "../../api";
import { showSuccessToast } from "../../components/Toast";
import { storeUsers } from "../entities/actions";
import {
  DELETE_USER_REQUEST,
  deleteUserFailure,
  deleteUserSuccess,
  LOAD_ALL_USERS_REQUEST,
  loadAllUsersFailure,
  loadAllUsersSuccess
} from "./actions";

export const loadAllUsersEpic = action$ =>
  action$.ofType(LOAD_ALL_USERS_REQUEST).pipe(
    switchMap(() =>
      ajaxGetUsers().pipe(
        map(result => result.response.data),
        map(users => [storeUsers(users), loadAllUsersSuccess(users)]),
        mergeAll(),
        catchError(error => Observable.of(loadAllUsersFailure(error.response)))
      )
    )
  );

export const deleteUserEpic = action$ =>
  action$.ofType(DELETE_USER_REQUEST).pipe(
    switchMap(action =>
      ajaxDeleteUser(action.user.id).pipe(
        map(() => deleteUserSuccess(action.user.id)),
        tap(() => showSuccessToast(`User "${action.user.fullname}" was deleted`)),
        catchError(error => Observable.of(deleteUserFailure(error.response)))
      )
    )
  );
