import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CLOSE_CHANGE_PASSWORD_DIALOG,
  OPEN_CHANGE_PASSWORD_DIALOG
} from "./actions";

const initialState = {
  dialogOpen: false,
  userId: null,
  submitting: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHANGE_PASSWORD_DIALOG: {
      return {
        dialogOpen: true,
        userId: action.userId,
        submitting: false,
        error: null
      };
    }

    case CLOSE_CHANGE_PASSWORD_DIALOG: {
      return {
        dialogOpen: false,
        userId: null,
        submitting: false,
        error: null
      };
    }

    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        submitting: true
      };
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        submitting: false,
        error: null
      };
    }

    case CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        submitting: false,
        error: action.error
      };
    }

    default: {
      return state;
    }
  }
};
