export const LOAD_WIDGET_TYPES_REQUEST = "LOAD_WIDGET_TYPES_REQUEST";
export const LOAD_WIDGET_TYPES_FAILURE = "LOAD_WIDGET_TYPES_FAILURE";
export const LOAD_WIDGET_TYPES_SUCCESS = "LOAD_WIDGET_TYPES_SUCCESS";

export const SAVE_ENABLED_WIDGETS_REQUEST = "SAVE_ENABLED_WIDGETS_REQUEST";
export const SAVE_ENABLED_WIDGETS_FAILURE = "SAVE_ENABLED_WIDGETS_FAILURE";
export const SAVE_ENABLED_WIDGETS_SUCCESS = "SAVE_ENABLED_WIDGETS_SUCCESS";

export const loadWidgetTypesRequest = () => {
  return { type: LOAD_WIDGET_TYPES_REQUEST };
};

export const loadWidgetTypesFailure = error => {
  return { type: LOAD_WIDGET_TYPES_FAILURE, error };
};

export const loadWidgetTypesSuccess = widgetTypes => {
  return { type: LOAD_WIDGET_TYPES_SUCCESS, widgetTypes };
};

export const saveEnabledWidgetsRequest = (userId, widgetTypes) => {
  return { type: SAVE_ENABLED_WIDGETS_REQUEST, userId, widgetTypes };
};

export const saveEnabledWidgetsFailure = error => {
  return { type: SAVE_ENABLED_WIDGETS_FAILURE, error };
};

export const saveEnabledWidgetsSuccess = widgetTypes => {
  return { type: SAVE_ENABLED_WIDGETS_SUCCESS, widgetTypes };
};
