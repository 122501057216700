import React, { useCallback, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { performAssetStatusPut, performCompanyAssetDelete } from "../../api";
import ContentBlock, { ContentBlockTitle, ContentSection } from "../../components/containers/ContentBlock";
import LoadingContainer from "../../components/containers/LoadingContainer";
import Input from "../../components/controls/Input";
import Main from "../../components/layout/Main";
import { changeShownAssetsKind, loadAssetsOrSnapshotsPageRequest } from "../../redux/assets-list/actions";
import {
  ASSET_STATUS_APPROVED_BY_BIZONE,
  ASSET_STATUS_APPROVED_BY_CLIENT,
  userRoleIsOnBizoneSide
} from "../../utils/enums";
import { withDefaultErrorHandling } from "../../utils/error-handling";
import { pageTitle } from "../../utils/ui";
import useActiveUser from "../../utils/useActiveUser";
import AddAssetsWizard from "./AddAssetsWizard";
import AssetApprovalDialog from "./AssetApprovalDialog";
import AssetsTable from "./AssetsTable";
import AssetsTopBar from "./AssetsTopBar";
import ServiceSnapshotsTable from "./ServiceSnapshotsTable";

const ContentBlockTitleControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

const SearchInputContainer = styled.div`
  margin-left: 20px;
  width: 200px;
`;

export default withRouter(({ match, history }) => {
  const requestedAssetsKind = match.params.status;

  const companyId = useSelector(state =>
    state.companySwitcher.activeCompany ? state.companySwitcher.activeCompany.id : undefined
  );

  const [needsAssetsStatsRefresh, setNeedsAssetsStatsRefresh] = useState(true);

  const [sortField, setSortField] = useState(undefined);
  const [sortDirection, setSortDirection] = useState(undefined);
  const [textSearch, setTextSearch] = useState(undefined);

  const userProfile = useActiveUser();

  const needsRefresh = useSelector(state => state.assetsList.needsRefresh);

  const items = useSelector(state => state.assetsList.items);
  const loading = useSelector(state => state.assetsList.loading);
  const error = useSelector(state => state.assetsList.error);

  const lastLoadedPage = useSelector(state => state.assetsList.lastLoadedPage);
  const pagesCount = useSelector(state => state.assetsList.pagesCount);

  const showSnapshots = useSelector(
    state => state.assetsList.selectedLeftScanId !== null && state.assetsList.selectedRightScanId !== null
  );

  const dispatch = useDispatch();

  const [assetToApprove, setAssetToApprove] = useState(undefined);
  const [addAssetsWizardOpen, setAddAssetsWizardOpen] = useState(false);

  const loadContentPage = (page = 1) => {
    dispatch(loadAssetsOrSnapshotsPageRequest(companyId, page, textSearch, sortField, sortDirection));
  };

  const loadNextContentPage = () => {
    const nextPage = !!lastLoadedPage ? lastLoadedPage + 1 : 1;
    loadContentPage(nextPage);
  };

  const handleSortingChanged = useCallback((sortField, sortDirection) => {
    setSortField(sortField);
    setSortDirection(sortDirection);
  }, []);

  const openAssetApprovalDialog = asset => {
    setAssetToApprove(asset);
  };

  const closeAssetApprovalDialog = () => {
    setAssetToApprove(undefined);
  };

  const approveAsset = asset =>
    withDefaultErrorHandling(async () => {
      const nextStatus = userRoleIsOnBizoneSide(userProfile.role)
        ? ASSET_STATUS_APPROVED_BY_BIZONE
        : ASSET_STATUS_APPROVED_BY_CLIENT;

      await performAssetStatusPut(asset.id, companyId, nextStatus);

      closeAssetApprovalDialog();
      loadContentPage();
      setNeedsAssetsStatsRefresh(true);
    });

  const deleteAsset = async assetId => {
    if (!window.confirm("Are you sure you want to delete this asset?")) {
      return;
    }

    try {
      await performCompanyAssetDelete(companyId, assetId);
      loadContentPage();
      setNeedsAssetsStatsRefresh(true);
    } catch (error) {
      window.alert(JSON.stringify(error));
    }
  };

  const openAddAssetsWizard = () => {
    setAddAssetsWizardOpen(true);
  };

  const closeAddAssetsWizard = () => {
    setAddAssetsWizardOpen(false);
  };

  const finishAddAssetsWizard = () => {
    loadContentPage();
    setNeedsAssetsStatsRefresh(true);
    setAddAssetsWizardOpen(false);
  };

  useEffect(() => {
    setSortField(undefined);
    setSortDirection(undefined);
  }, [showSnapshots]);

  useEffect(() => {
    dispatch(changeShownAssetsKind(requestedAssetsKind));
  }, [dispatch, requestedAssetsKind]);

  useEffect(() => {
    loadContentPage();
  }, [companyId, textSearch, sortField, sortDirection]);

  useEffect(() => {
    if (needsRefresh) {
      loadContentPage();
    }
  }, [needsRefresh]);

  useEffect(() => {
    setNeedsAssetsStatsRefresh(true);
  }, [companyId]);

  useEffect(() => {
    if (needsAssetsStatsRefresh) {
      setNeedsAssetsStatsRefresh(false);
    }
  }, [needsAssetsStatsRefresh]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle("Projects")}</title>
      </Helmet>

      <AssetsTopBar
        companyId={companyId}
        onAddAssetsClick={openAddAssetsWizard}
        userRole={userProfile.role}
        reloadStats={needsAssetsStatsRefresh}
      />

      <Main>
        {!companyId ? (
          <div>There is no active company selected. Nothing to show.</div>
        ) : (
          <ContentBlock>
            <ContentBlockTitle>
              Projects
              <ContentBlockTitleControls>
                <SearchInputContainer>
                  <DebounceInput
                    element={Input}
                    minLength={2}
                    debounceTimeout={500}
                    type="text"
                    placeholder="What are we looking for?"
                    onChange={event => setTextSearch(event.target.value)}
                  />
                </SearchInputContainer>
              </ContentBlockTitleControls>
            </ContentBlockTitle>

            <ContentSection>
              <LoadingContainer
                hasError={pagesCount === null && error}
                loading={pagesCount === null && !error}
                renderError={() => `Got error ${JSON.stringify(error)}`}
                renderData={() => (
                  <React.Fragment>
                    {items && items.length > 0 ? (
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={loadNextContentPage}
                        hasMore={pagesCount > lastLoadedPage && !loading}
                        initialLoad={true}
                        loader={
                          <div className="loader" key={"loader"}>
                            Loading ...
                          </div>
                        }
                      >
                        {showSnapshots ? (
                          <ServiceSnapshotsTable
                            serviceSnapshots={items}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            onSortingChanged={handleSortingChanged}
                          />
                        ) : (
                          <AssetsTable
                            history={history}
                            assetsAndServices={items}
                            userRole={userProfile.role}
                            sortField={sortField}
                            sortDirection={sortDirection}
                            onSortingChanged={handleSortingChanged}
                            onAssetDelete={deleteAsset}
                            onAssetApprove={openAssetApprovalDialog}
                          />
                        )}
                      </InfiniteScroll>
                    ) : (
                      <div>No projects to show.</div>
                    )}
                  </React.Fragment>
                )}
              />
            </ContentSection>
          </ContentBlock>
        )}
      </Main>

      {assetToApprove && (
        <AssetApprovalDialog asset={assetToApprove} onApprove={approveAsset} onClose={closeAssetApprovalDialog} />
      )}

      {addAssetsWizardOpen && (
        <AddAssetsWizard companyId={companyId} onFinish={finishAddAssetsWizard} onCancel={closeAddAssetsWizard} />
      )}
    </React.Fragment>
  );
});
