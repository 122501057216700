import { useEffect } from "react";

export default (ref, enabled = true, dependencies = []) => {
  dependencies = [ref, enabled, ...dependencies];

  useEffect(() => {
    if (enabled && ref.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
