const yupErrorsToPropertyErrors = yupErrors => {
  let propertyErrors = {};

  yupErrors.forEach(yupError => {
    propertyErrors[yupError.path] = { reason: yupError.message, nested: yupErrorsToPropertyErrors(yupError.inner) };
  });

  return propertyErrors;
};

export const validate = async (schema, data) => {
  try {
    return await schema.validate(data, {
      abortEarly: false,
      stripUnknown: false
    });
  } catch (yupError) {
    throw yupErrorsToPropertyErrors(yupError.inner);
  }
};

export const preprocessPhoneNumber = phoneNumber => {
  return phoneNumber.replace(/[()\s-]/g, "");
};

export const isValidPhoneNumber = text => {
  const regex = /^[+][0-9]{11}/;
  return regex.test(text);
};
