import { css } from "styled-components";
import styled from "styled-components/macro";

export default styled.div`
  padding: 0 16px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-items: stretch;
  justify-content: stretch;
  background: #ffffff;
  color: #4d4d4d;
  font-weight: normal;

  & span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  ${({ selected }) =>
    selected &&
    css`
      background: rgba(24, 168, 204, 0.12);
    `}

  & a, & a:hover {
    color: #4d4d4d;
    text-decoration: none;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    flex-direction: row;
    align-items: center;
  }
`;
