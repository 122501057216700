export const OPEN_USER_COMPANIES_DIALOG = "OPEN_USER_COMPANIES_DIALOG";
export const CLOSE_USER_COMPANIES_DIALOG = "CLOSE_USER_COMPANIES_DIALOG";

export const CHANGE_USER_COMPANIES_REQUEST = "CHANGE_USER_COMPANIES_REQUEST";
export const CHANGE_USER_COMPANIES_SUCCESS = "CHANGE_USER_COMPANIES_SUCCESS";
export const CHANGE_USER_COMPANIES_FAILURE = "CHANGE_USER_COMPANIES_FAILURE";

export const openUserCompaniesDialog = userId => ({ type: OPEN_USER_COMPANIES_DIALOG, userId });

export const closeUserCompaniesDialog = () => ({ type: CLOSE_USER_COMPANIES_DIALOG });

export const changeUserCompaniesRequest = (userId, companyIds) => ({ type: CHANGE_USER_COMPANIES_REQUEST, userId, companyIds });

export const changeUserCompaniesSuccess = () => ({ type: CHANGE_USER_COMPANIES_SUCCESS });

export const changeUserCompaniesFailure = (error) => ({ type: CHANGE_USER_COMPANIES_FAILURE, error });
