import React, { useCallback, useEffect, useState } from "react";
import { Cell, Label, Pie, PieChart } from "recharts";
import styled from "styled-components/macro";
import {
  performCompanyVulnerabilitiesSeverityStatsGet,
  performCompanyVulnerabilitiesStatusStatsGet
} from "../../../api";
import ContentBlock, { ContentBlockTitle, ContentSection } from "../../../components/containers/ContentBlock";
import LoadingContainer from "../../../components/containers/LoadingContainer";
import TwoColumnLayout, { TwoColumnLayoutColumn } from "../../../components/containers/TwoColumnLayout";
import Factoid from "../../../components/misc/Factoid";
import FactoidDelta from "../../../components/misc/FactoidDelta";
import Circle from "../../../components/shapes/Circle";
import Rectangle from "../../../components/shapes/Rectangle";
import {
  allVulnerabilitySeverities,
  getVulnerabilitySeverityColor,
  VULNERABILITY_STATUS_PENDING,
  VULNERABILITY_STATUS_RESOLVE_APPROVED
} from "../../../utils/enums";

const VulnerabilitiesStatsContainer = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VulnerabilitiesNumbersContainer = styled.div`
  flex: 0 1 40%;
`;

const VulnerabilitiesPieChartContainer = styled.div`
  flex: 1 1;
`;

const VulnerabilitiesStatsLegendContainer = styled.div`
  flex: 0 1 190px;
`;

const VulnerabilityLegend = styled.div`
  font-size: 12px;

  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export default ({ companyId }) => {
  const [currentStatusStats, setCurrentStatusStats] = useState(undefined);
  const [weekAgoStatusStats, setWeekAgoStatusStats] = useState(undefined);

  const [severityStats, setSeverityStats] = useState(undefined);
  const [totalCount, setTotalCount] = useState(undefined);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const loadVulnerabilitiesStats = useCallback(async () => {
    setLoading(true);

    try {
      const currentStatusStatsResponse = await performCompanyVulnerabilitiesStatusStatsGet(companyId);
      setCurrentStatusStats(currentStatusStatsResponse.data);

      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 7);

      const weekAgoStatusStatsResponse = await performCompanyVulnerabilitiesStatusStatsGet(companyId, {
        before: weekAgo.toISOString()
      });
      setWeekAgoStatusStats(weekAgoStatusStatsResponse.data);

      const statusStatsEntries = Object.entries(currentStatusStatsResponse.data);
      setTotalCount(statusStatsEntries.reduce((count, [_, value]) => count + value, 0));

      const severityStatsResponse = await performCompanyVulnerabilitiesSeverityStatsGet(companyId);
      const severityStatsEntries = Object.entries(severityStatsResponse.data);
      setSeverityStats(severityStatsEntries.map(([name, value]) => ({ name, value })));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    loadVulnerabilitiesStats();
  }, [loadVulnerabilitiesStats]);

  return (
    <ContentBlock>
      <ContentBlockTitle>Vulnerabilities</ContentBlockTitle>

      <ContentSection>
        <LoadingContainer
          loading={loading}
          hasError={!!error}
          renderError={() => <div>Got error {JSON.stringify(error)}</div>}
          renderData={() => {
            const approvedDelta =
              currentStatusStats[VULNERABILITY_STATUS_RESOLVE_APPROVED] -
              weekAgoStatusStats[VULNERABILITY_STATUS_RESOLVE_APPROVED];

            return (
              <VulnerabilitiesStatsContainer>
                <VulnerabilitiesNumbersContainer>
                  <TwoColumnLayout>
                    <TwoColumnLayoutColumn>
                      <div>
                        <Circle size={8} color="#3cc151" />
                        Resolve Approved
                      </div>
                      <Factoid primary>
                        {currentStatusStats[VULNERABILITY_STATUS_RESOLVE_APPROVED]}
                        <FactoidDelta delta={approvedDelta} />
                      </Factoid>
                    </TwoColumnLayoutColumn>
                    <TwoColumnLayoutColumn>
                      <div>
                        <Circle size={8} color="#999999" />
                        Pending
                      </div>
                      <Factoid>{currentStatusStats[VULNERABILITY_STATUS_PENDING]}</Factoid>
                    </TwoColumnLayoutColumn>
                  </TwoColumnLayout>
                </VulnerabilitiesNumbersContainer>

                <VulnerabilitiesPieChartContainer>
                  <PieChart width={240} height={220}>
                    <Pie
                      data={severityStats}
                      isAnimationActive={true}
                      animationDuration={750}
                      labelLine={true}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label
                    >
                      {severityStats.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={getVulnerabilitySeverityColor(entry.name)} />
                      ))}
                      <Label value={`Total: ${totalCount}`} position="center" />
                    </Pie>
                  </PieChart>
                </VulnerabilitiesPieChartContainer>

                <VulnerabilitiesStatsLegendContainer>
                  {allVulnerabilitySeverities.map((severity, index) => {
                    return (
                      <VulnerabilityLegend key={index}>
                        <Rectangle size={15} color={getVulnerabilitySeverityColor(severity)} /> {severity}
                      </VulnerabilityLegend>
                    );
                  })}
                </VulnerabilitiesStatsLegendContainer>
              </VulnerabilitiesStatsContainer>
            );
          }}
        />
      </ContentSection>
    </ContentBlock>
  );
};
