import styled from "styled-components/macro";
import { css } from "styled-components";

export const TwoColumnLayoutColumn = styled.div`
  flex: 0 0 ${({ percentage }) => percentage || "49%"};
  max-width: ${({ percentage }) => percentage || "49%"};
  display: flex;
  flex-direction: column;

  ${({ fillVertically }) =>
    fillVertically &&
    css`
      & > * {
        flex: 1;
      }
    `}
`;

export default styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
