export const LOAD_ALL_USERS_REQUEST = "LOAD_ALL_USERS_REQUEST";
export const LOAD_ALL_USERS_SUCCESS = "LOAD_ALL_USERS_SUCCESS";
export const LOAD_ALL_USERS_FAILURE = "LOAD_ALL_USERS_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const loadAllUsersRequest = () => ({ type: LOAD_ALL_USERS_REQUEST });

export const loadAllUsersSuccess = users => ({ type: LOAD_ALL_USERS_SUCCESS, users });

export const loadAllUsersFailure = error => ({ type: LOAD_ALL_USERS_FAILURE, error });

export const deleteUserRequest = user => ({ type: DELETE_USER_REQUEST, user });

export const deleteUserSuccess = userId => ({ type: DELETE_USER_SUCCESS, userId });

export const deleteUserFailure = error => ({ type: DELETE_USER_FAILURE, error });

