import {
  CLEAR_ACTIVE_USER_PROFILE,
  FETCH_ACTIVE_USER_PROFILE_FAILURE,
  FETCH_ACTIVE_USER_PROFILE_REQUEST,
  FETCH_ACTIVE_USER_PROFILE_SUCCESS
} from "./actions";
import { clearLocalStorage } from "../local-storage";

const initialState = {
  pending: true,
  userId: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_USER_PROFILE_REQUEST:
      return {
        pending: true,
        userId: null,
        error: null
      };

    case FETCH_ACTIVE_USER_PROFILE_SUCCESS:
      return {
        pending: false,
        userId: action.userProfile.id,
        error: null
      };

    case FETCH_ACTIVE_USER_PROFILE_FAILURE:
      return {
        pending: false,
        userId: null,
        error: null
      };

    case CLEAR_ACTIVE_USER_PROFILE: {
      clearLocalStorage();
      return { pending: false, userId: null, error: null };
    }

    default: {
      return state;
    }
  }
};
