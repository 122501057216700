export const USER_ROLE_BIZONE_ADMIN = "bizone_admin";
export const USER_ROLE_BIZONE_EMPLOYEE = "bizone_employee";
export const USER_ROLE_COMPANY_ADMIN = "company_admin";
export const USER_ROLE_COMPANY_EMPLOYEE = "company_employee";
export const USER_ROLE_COMPANY_MANAGER = "company_manager";

export const allUserRoles = [
  USER_ROLE_BIZONE_ADMIN,
  USER_ROLE_BIZONE_EMPLOYEE,
  USER_ROLE_COMPANY_ADMIN,
  USER_ROLE_COMPANY_EMPLOYEE,
  USER_ROLE_COMPANY_MANAGER
];

export const userRoleToString = userRole => {
  switch (userRole) {
    case USER_ROLE_BIZONE_ADMIN:
      return "BiZone Administrator";
    case USER_ROLE_BIZONE_EMPLOYEE:
      return "BiZone Employee";
    case USER_ROLE_COMPANY_ADMIN:
      return "Company Admin";
    case USER_ROLE_COMPANY_EMPLOYEE:
      return "Company Employee";
    case USER_ROLE_COMPANY_MANAGER:
      return "Company Manager";
    default:
      return "Unknown Role";
  }
};

export const userRoleIsOnBizoneSide = role => {
  return role === USER_ROLE_BIZONE_ADMIN || role === USER_ROLE_BIZONE_EMPLOYEE;
};

export const ASSET_STATUS_NOT_APPROVED = "not_approved";
export const ASSET_STATUS_APPROVED_BY_BIZONE = "approved_by_bizone";
export const ASSET_STATUS_APPROVED_BY_CLIENT = "approved_by_client";
export const ASSET_STATUS_APPROVED = "approved";

export const assetStatusToString = status => {
  switch (status) {
    case ASSET_STATUS_NOT_APPROVED:
      return "Not approved";
    case ASSET_STATUS_APPROVED_BY_BIZONE:
      return "Approved by Bi.Zone";
    case ASSET_STATUS_APPROVED_BY_CLIENT:
      return "Approved by client";
    case ASSET_STATUS_APPROVED:
      return "Approved";
    default:
      return "Unknown Status";
  }
};

export const SERVICE_STATUS_OPEN = "open";
export const SERVICE_STATUS_CLOSED = "closed";
export const SERVICE_STATUS_FILTERED = "filtered";

export const serviceStatusToString = status => {
  switch (status) {
    case SERVICE_STATUS_OPEN:
      return "Open";
    case SERVICE_STATUS_CLOSED:
      return "Closed";
    case SERVICE_STATUS_FILTERED:
      return "Filtered";
    default:
      return "Unknown Status";
  }
};

export const getServiceStatusColor = status => {
  switch (status) {
    case SERVICE_STATUS_OPEN:
      return "#4bb04f";
    case SERVICE_STATUS_CLOSED:
      return "#ff6057";
    case SERVICE_STATUS_FILTERED:
      return "#ebaf0a";
    default:
      return "#000000";
  }
};

export const VULNERABILITY_STATUS_NEW = "new";
export const VULNERABILITY_STATUS_PENDING = "pending";
export const VULNERABILITY_STATUS_RESOLVED = "resolved";
export const VULNERABILITY_STATUS_RESOLVE_APPROVED = "resolve_approved";
export const VULNERABILITY_STATUS_WONT_FIX = "wont_fix";
export const VULNERABILITY_STATUS_FALSE_POSITIVE = "false_positive";

export const allVulnerabilityStatuses = [
  VULNERABILITY_STATUS_NEW,
  VULNERABILITY_STATUS_PENDING,
  VULNERABILITY_STATUS_RESOLVED,
  VULNERABILITY_STATUS_RESOLVE_APPROVED,
  VULNERABILITY_STATUS_WONT_FIX,
  VULNERABILITY_STATUS_FALSE_POSITIVE
];

export const vulnerabilityStatusToString = status => {
  switch (status) {
    case VULNERABILITY_STATUS_NEW:
      return "New";
    case VULNERABILITY_STATUS_PENDING:
      return "Pending";
    case VULNERABILITY_STATUS_RESOLVED:
      return "Resolved";
    case VULNERABILITY_STATUS_RESOLVE_APPROVED:
      return "Resolve Approved";
    case VULNERABILITY_STATUS_WONT_FIX:
      return "Won't fix";
    case VULNERABILITY_STATUS_FALSE_POSITIVE:
      return "False positive";
    default:
      return "Unknown Status";
  }
};

export const VULNERABILITY_SEVERITY_INFO = "info";
export const VULNERABILITY_SEVERITY_LOW = "low";
export const VULNERABILITY_SEVERITY_MEDIUM = "medium";
export const VULNERABILITY_SEVERITY_HIGH = "high";
export const VULNERABILITY_SEVERITY_CRITICAL = "critical";

export const allVulnerabilitySeverities = [
  VULNERABILITY_SEVERITY_INFO,
  VULNERABILITY_SEVERITY_LOW,
  VULNERABILITY_SEVERITY_MEDIUM,
  VULNERABILITY_SEVERITY_HIGH,
  VULNERABILITY_SEVERITY_CRITICAL
];

export const vulnerabilitySeverityToString = severity => {
  switch (severity) {
    case VULNERABILITY_SEVERITY_INFO:
      return "Info";
    case VULNERABILITY_SEVERITY_LOW:
      return "Low";
    case VULNERABILITY_SEVERITY_MEDIUM:
      return "Medium";
    case VULNERABILITY_SEVERITY_HIGH:
      return "High";
    case VULNERABILITY_SEVERITY_CRITICAL:
      return "Critical";
    default:
      return "Unknown Severity";
  }
};

export const vulnerabilitySeverityToInt = severity => {
  switch (severity) {
    case VULNERABILITY_SEVERITY_INFO:
      return 0;
    case VULNERABILITY_SEVERITY_LOW:
      return 1;
    case VULNERABILITY_SEVERITY_MEDIUM:
      return 2;
    case VULNERABILITY_SEVERITY_HIGH:
      return 3;
    case VULNERABILITY_SEVERITY_CRITICAL:
      return 5;
    default:
      return 0;
  }
};


export const vulnerabilitySeverityFromInt = severity => {
  if (severity < 1)
    return VULNERABILITY_SEVERITY_INFO;
  else if (severity < 2)
    return VULNERABILITY_SEVERITY_LOW;
  else if (severity < 3)
    return VULNERABILITY_SEVERITY_MEDIUM;
  else if (severity < 5)
    return VULNERABILITY_SEVERITY_HIGH;
  return VULNERABILITY_SEVERITY_CRITICAL
};


export const vulnerabilitySecurityLevelFromInt = severity => {
  if (severity < 1)
    return VULNERABILITY_SEVERITY_CRITICAL;
  else if (severity < 2)
    return VULNERABILITY_SEVERITY_HIGH;
  else if (severity < 3)
    return VULNERABILITY_SEVERITY_MEDIUM;
  else if (severity < 5)
    return VULNERABILITY_SEVERITY_LOW;
  return VULNERABILITY_SEVERITY_INFO
};


export const getVulnerabilitySeverityColor = severity => {
  switch (severity) {
    case VULNERABILITY_SEVERITY_INFO:
      return "#18a8cc";
    case VULNERABILITY_SEVERITY_LOW:
      return "#4bb04f";
    case VULNERABILITY_SEVERITY_MEDIUM:
      return "#ebaf0a";
    case VULNERABILITY_SEVERITY_HIGH:
      return "#eb6f1d";
    case VULNERABILITY_SEVERITY_CRITICAL:
      return "#df4a3f";
    default:
      return "#000000";
  }
};

export const EVENT_TYPE_CREATE_VULNERABILITY = "create_vulnerability";
export const EVENT_TYPE_CREATE_VULNERABILITY_COMMENT = "create_vulnerability_comment";
export const EVENT_TYPE_DELETE_VULNERABILITY = "delete_vulnerability";
export const EVENT_TYPE_DELETE_ASSET = "delete_asset";
export const EVENT_TYPE_CHANGE_ASSET_STATUS = "change_asset_status";
export const EVENT_TYPE_CREATE_ASSET = "create_asset";
export const EVENT_TYPE_CHANGE_VULNERABILITY_STATUS = "change_vulnerability_status";
