import * as Observable from "rxjs";
import { catchError, map, mergeAll, switchMap } from "rxjs/operators";
import { ajaxPostUserCompanies } from "../../api";
import { showErrorToast } from "../../components/Toast";
import { storeUsers } from "../entities/actions";
import {
  CHANGE_USER_COMPANIES_REQUEST,
  changeUserCompaniesFailure,
  changeUserCompaniesSuccess,
  closeUserCompaniesDialog
} from "./action";

export const changeUserCompaniesEpic = action$ =>
  action$.ofType(CHANGE_USER_COMPANIES_REQUEST).pipe(
    switchMap(action =>
      ajaxPostUserCompanies(action.userId, { companyIds: action.companyIds }).pipe(
        map(result => result.response.data),
        map(user => [storeUsers([user]), changeUserCompaniesSuccess(), closeUserCompaniesDialog()]),
        mergeAll(),
        catchError(error => {
          showErrorToast(`Unable to change user companies. ${error.message}`);
          return Observable.of(changeUserCompaniesFailure(error.response));
        })
      )
    )
  );
