import React from "react";
import { DeleteButton } from "../../components/controls/IconButtons";
import Grid from "../../components/grid/Grid";
import GridRowHoverReveal from "../../components/grid/GridRowHoverReveal";
import { formatDate } from "../../utils/ui";

const gridSchema = {
  columns: [
    {
      key: "key",
      name: "Key",
      minWidth: "200px",
      widthPercentage: 100
    },
    {
      key: "date",
      name: "Date",
      minWidth: "120px"
    },
    {
      key: "delete",
      align: "center",
      minWidth: "50px",
      notPadded: true
    }
  ]
};

export default ({ apiKeys, onDelete }) => {
  return (
    <Grid
      schema={gridSchema}
      items={apiKeys}
      getItemKey={apiKey => apiKey.id}
      renderItem={(apiKey, columnKey) => {
        switch (columnKey) {
          case "key":
            return apiKey.key;

          case "date":
            return formatDate(apiKey.createdAt);

          case "delete":
            return (
              <GridRowHoverReveal>
                <DeleteButton
                  onClick={event => {
                    event.stopPropagation();
                    onDelete(apiKey);
                  }}
                />
              </GridRowHoverReveal>
            );

          default:
            break;
        }
      }}
    />
  );
};
