import React from "react";
import styled from "styled-components/macro";
import checkboxMarkedSvgPath from "../../assets/images/checkbox-marked.svg";
import checkboxSvgPath from "../../assets/images/checkbox.svg";

const CheckboxHiddenInput = styled.input.attrs({ type: "checkbox" })`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  width: 30px;
  height: 30px;
`;

const CheckboxShape = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 3px;
  top: 3px;
  opacity: 0.4;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
`;

const CheckboxMarkedShape = styled.img`
  opacity: 0;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 2;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${CheckboxHiddenInput}:checked:disabled ~ ${CheckboxMarkedShape} {
    opacity: 0.4;
  }

  ${CheckboxHiddenInput}:hover ~ ${CheckboxMarkedShape} {
    filter: brightness(0.9);
  }

  ${CheckboxHiddenInput}:checked ~ ${CheckboxMarkedShape} {
    opacity: 1;
  }

  ${CheckboxHiddenInput}:disabled ~ ${CheckboxShape} {
    opacity: 0.16;
  }

  ${CheckboxHiddenInput}:hover ~ ${CheckboxShape} {
    opacity: 0.6;
  }

  ${CheckboxHiddenInput}:checked ~ ${CheckboxShape} {
    visibility: hidden;
  }
`;

export default ({ name, checked, onClick, onBlur, onChange }) => {
  return (
    <CheckboxContainer>
      <CheckboxHiddenInput name={name} checked={checked} onClick={onClick} onBlur={onBlur} onChange={onChange} />
      <CheckboxMarkedShape src={checkboxMarkedSvgPath} />
      <CheckboxShape src={checkboxSvgPath} />
    </CheckboxContainer>
  );
};
