import React from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { performLogoutPost } from "../../api";
import userProfileSvgPath from "../../assets/images/profile.svg";
import { clearActiveUserProfile } from "../../redux/auth/actions";
import { openEditUserDialog } from "../../redux/user-edit/actions";
import useActiveUser from "../../utils/useActiveUser";
import NavigationLinkIcon from "./NavigationLinkIcon";
import NavigationPopupMenu from "./NavigationPopupMenu";
import NavigationPopupMenuRow from "./NavigationPopupMenuRow";

export default withRouter(({ history }) => {
  const userProfile = useActiveUser();

  const dispatch = useDispatch();

  const editActiveUser = () => {
    dispatch(openEditUserDialog(userProfile.id));
  };

  const signOut = async () => {
    await performLogoutPost();
    dispatch(clearActiveUserProfile());
    history.replace("/");
  };

  return (
    <NavigationPopupMenu
      fixedWidth="200px"
      text={userProfile.fullname}
      icon={<NavigationLinkIcon src={userProfileSvgPath} />}
    >
      <React.Fragment>
        <NavigationPopupMenuRow onClick={editActiveUser}>Edit profile</NavigationPopupMenuRow>
        <NavigationPopupMenuRow>
          <Link to="/settings/dashboard">Settings</Link>
        </NavigationPopupMenuRow>
        <NavigationPopupMenuRow onClick={signOut}>Sign Out</NavigationPopupMenuRow>
      </React.Fragment>
    </NavigationPopupMenu>
  );
});
