import React from "react";
import styled from "styled-components/macro";

const FormFieldContainer = styled.div`
  position: relative;
  left: -6px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const FormLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  & span {
    margin-left: 4px;
  }
`;

const ValidationError = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: #c8473b;
`;

export default ({ label, input, error }) => {
  return (
    <FormFieldContainer>
      <FormLabel>
        {input}
        <span>{label}</span>
      </FormLabel>
      {error && <ValidationError>{error.reason}</ValidationError>}
    </FormFieldContainer>
  );
};
