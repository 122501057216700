import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { performCompanyVulnerabilitiesGet } from "../../../api";
import CenteredVertically from "../../../components/containers/CenteredVertically";
import ContentBlock, { ContentSection, ContentBlockTitle } from "../../../components/containers/ContentBlock";
import LoadingIndicator from "../../../components/misc/LoadingIndicator";
import TextWithColorIndicator from "../../../components/misc/TextWithColorIndicator";
import Table, { TableCell } from "../../../components/Table";
import {
  getVulnerabilitySeverityColor,
  VULNERABILITY_STATUS_RESOLVE_APPROVED,
  vulnerabilitySeverityToString
} from "../../../utils/enums";
import { formatDate, formatVulnerabilityServiceName } from "../../../utils/ui";

export default withRouter(({ history, companyId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [vulnerabilities, setVulnerabilities] = useState(undefined);

  const loadEvents = useCallback(async () => {
    setLoading(true);

    try {
      const params = {
        status: [VULNERABILITY_STATUS_RESOLVE_APPROVED],
        page: 1,
        perPage: 3,
        sortField: "updated_status_at",
        sortDirection: "desc"
      };
      const response = await performCompanyVulnerabilitiesGet(companyId, params);
      setVulnerabilities(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  const navigateToVulnerability = useCallback(
    vulnerability => {
      history.push(`/vulnerabilities/view/${vulnerability.id}`);
    },
    [history]
  );

  return (
    <ContentBlock>
      <ContentBlockTitle>Recently fixed vulnerabilities</ContentBlockTitle>

      <ContentSection flex={"1 0"}>
        <CenteredVertically expand>
          {loading && <LoadingIndicator />}

          {loading && error && <React.Fragment>{JSON.stringify(error)}</React.Fragment>}

          {!loading && !error && (
            <React.Fragment>
              {vulnerabilities.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Project</th>
                      <th>Name</th>
                      <th>Severity</th>
                      <th>CVSS</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vulnerabilities.map(vulnerability => (
                      <tr onClick={() => navigateToVulnerability(vulnerability)} key={vulnerability.id}>
                        <TableCell>{formatVulnerabilityServiceName(vulnerability)}</TableCell>
                        <TableCell>{vulnerability.name}</TableCell>
                        <TableCell>
                          <TextWithColorIndicator
                            color={getVulnerabilitySeverityColor(vulnerability.severity)}
                            text={vulnerabilitySeverityToString(vulnerability.severity)}
                          />
                        </TableCell>
                        <TableCell>{vulnerability.cvss.toFixed(1)}</TableCell>
                        <TableCell>{formatDate(vulnerability.createdAt)}</TableCell>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div>Nothing to show</div>
              )}
            </React.Fragment>
          )}
        </CenteredVertically>
      </ContentSection>
    </ContentBlock>
  );
});
