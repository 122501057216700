import styled from "styled-components/macro";

export default styled.div`
  display: inline-block;
  font-size: ${({size}) => size ? `${size}px` : "10px"};
  text-indent: -9999em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: #2397ca;
  background: -moz-linear-gradient(left, #2397ca 10%, rgba(255, 23, 68, 0) 42%);
  background: -webkit-linear-gradient(left, #2397ca 10%, rgba(255, 23, 68, 0) 42%);
  background: -o-linear-gradient(left, #2397ca 10%, rgba(255, 23, 68, 0) 42%);
  background: -ms-linear-gradient(left, #2397ca 10%, rgba(255, 23, 68, 0) 42%);
  background: linear-gradient(to right, #2397ca 10%, rgba(255, 23, 68, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: #2397ca;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  &:after {
    background: #f9f9f9;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
