import * as Observable from "rxjs";
import { catchError, map, mergeAll, switchMap } from "rxjs/operators";
import { ajaxPutUser } from "../../api";
import { showErrorToast } from "../../components/Toast";
import { storeUsers } from "../entities/actions";
import { closeEditUserDialog, UPDATE_USER_REQUEST, updateUserFailure, updateUserSuccess } from "./actions";

export const updateUserEpic = action$ =>
  action$.ofType(UPDATE_USER_REQUEST).pipe(
    switchMap(action =>
      ajaxPutUser(action.userId, action.userData).pipe(
        map(result => result.response.data),
        map(user => [closeEditUserDialog(), storeUsers([user]), updateUserSuccess(user)]),
        mergeAll(),
        catchError(error => {
          showErrorToast(`Unable to update user "${action.userData.fullname}". ${error.message}`);
          return Observable.of(updateUserFailure(error.response));
        })
      )
    )
  );
