import {
  deleteRequest,
  getRequest,
  performDelete,
  performGet,
  performPost,
  performPut,
  postRequest,
  putRequest
} from "./utils/fetch";

// See /public/env.js.
const BASE_API_URL = window.API_URL;

const apiUrl = relativeUrl => {
  return `${BASE_API_URL}/${relativeUrl}`;
};

// API keys

export const performApiKeysGet = () => {
  return performGet(apiUrl("api-keys"));
};

export const performApiKeyPost = () => {
  return performPost(apiUrl("api-keys"));
};

export const performApiKeyDelete = apiKeyId => {
  return performDelete(apiUrl(`api-keys/${apiKeyId}`));
};

// Login/logout

export const performLoginPost = data => {
  return performPost(apiUrl("login"), data);
};

export const performLogoutPost = () => {
  return performPost(apiUrl("logout"));
};

// Users

export const getMe = () => {
  return performGet(apiUrl("me"));
};

export const postUserConfirmation = (userId, data) => {
  return performPost(apiUrl(`users/${userId}/confirmation`), data);
};

export const ajaxGetUsers = (id, params) => {
  return getRequest(apiUrl("users"), params);
};

export const performUsersGet = filters => {
  return performGet(apiUrl("users"), filters);
};

export const ajaxGetCompanyUsers = companyId => {
  return getRequest(apiUrl(`companies/${companyId}/users`));
};

export const ajaxPostUser = data => {
  return postRequest(apiUrl("users"), data);
};

export const ajaxPostUserCompanies = (userId, data) => {
  return postRequest(apiUrl(`users/${userId}/companies`), data);
};

export const ajaxPutUser = (userId, data) => {
  return putRequest(apiUrl(`users/${userId}`), data);
};

export const ajaxDeleteUser = id => {
  return deleteRequest(apiUrl(`users/${id}`));
};

export const postUserWidgets = (id, data) => {
  return postRequest(apiUrl(`users/${id}/widgets`), data);
};

export const ajaxPostUserPassword = (userId, data) => {
  return postRequest(apiUrl(`users/${userId}/password`), data);
};

export const postUserActivation = id => {
  return performPost(apiUrl(`users/${id}/activation`), {});
};

export const deleteUserActivation = id => {
  return performDelete(apiUrl(`users/${id}/activation`));
};

// Companies

export const ajaxGetCompanies = () => {
  return getRequest(apiUrl("companies"));
};

export const performCompaniesGet = () => {
  return performGet(apiUrl("companies"));
};

export const ajaxGetSingleCompany = companyId => {
  return getRequest(apiUrl(`companies/${companyId}`));
};

export const ajaxPostCompany = data => {
  return postRequest(apiUrl("companies"), data);
};

export const ajaxDeleteCompany = id => {
  return deleteRequest(apiUrl(`companies/${id}`));
};

// Assets

export const getCompanyAssets = (companyId, params) => {
  return getRequest(apiUrl(`companies/${companyId}/assets`), params);
};

export const getCompanyAssetsAndServices = (companyId, params) => {
  return getRequest(apiUrl(`companies/${companyId}/assets-services`), params);
};

export const performCompanyAssetsGet = (companyId, params) => {
  return performGet(apiUrl(`companies/${companyId}/assets`), params);
};

export const performCompanyAssetIpsGet = (companyId, params) => {
  return performGet(apiUrl(`companies/${companyId}/assets/ips`), params);
};

export const performCompanyAssetsPost = (companyId, data) => {
  return performPost(apiUrl(`companies/${companyId}/assets`), data);
};

export const performCompanyAssetDelete = (companyId, assetId) => {
  return performDelete(apiUrl(`companies/${companyId}/assets/${assetId}`));
};

// Assets stats

export const performCompanyAssetsStatusStatsGet = (companyId, queryParams = {}) => {
  return performGet(apiUrl(`companies/${companyId}/assets/stats/status`), queryParams);
};

// Assets+services stats

export const performCompanyServicesAssetStatusStatsGet = companyId => {
  return performGet(apiUrl(`companies/${companyId}/services/stats/asset-status`));
};

// Services stats

export const performCompanyServicesStatusStatsGet = (companyId, queryParams = {}) => {
  return performGet(apiUrl(`companies/${companyId}/services/stats/status`), queryParams);
};

// Assets + services stats

export const performCompanyAssetsAndServicesAssetStatusStatsGet = companyId => {
  return performGet(apiUrl(`companies/${companyId}/assets-services/stats/status`));
};

// Asset status

export const performAssetStatusPut = (assetId, companyId, status) => {
  return performPut(apiUrl(`companies/${companyId}/assets/${assetId}/status`), { status });
};

// Discovery scans

export const performCompanyDiscoveryScansGet = companyId => {
  return performGet(apiUrl(`companies/${companyId}/scans/discovery`));
};

export const getCompanyDiscoveryScansComparison = (companyId, leftScanId, rightScanId, queryParams) => {
  return getRequest(apiUrl(`companies/${companyId}/scans/discovery/${leftScanId}/${rightScanId}`), queryParams);
};

// Vulnerabilities

export const performCompanyVulnerabilitiesGet = (companyId, queryParams) => {
  return performGet(apiUrl(`companies/${companyId}/vulnerabilities`), queryParams);
};

export const performSingleVulnerabilityGet = (companyId, vulnerabilityId) => {
  return performGet(apiUrl(`companies/${companyId}/vulnerabilities/${vulnerabilityId}`));
};

export const performVulnerabilityPut = (companyId, vulnerabilityId, data) => {
  return performPut(apiUrl(`companies/${companyId}/vulnerabilities/${vulnerabilityId}`), data);
};

export const performCompanyVulnerabilityPost = (companyId, vulnerabilityData) => {
  return performPost(apiUrl(`companies/${companyId}/vulnerabilities`), vulnerabilityData);
};

export const performVulnerabilityDelete = (companyId, vulnerabilityId) => {
  return performDelete(apiUrl(`companies/${companyId}/vulnerabilities/${vulnerabilityId}`));
};

// Vulnerabilities stats

export const performCompanyVulnerabilitiesStatusStatsGet = (companyId, queryParams = {}) => {
  return performGet(apiUrl(`companies/${companyId}/vulnerabilities/stats/status`), queryParams);
};

export const performCompanyVulnerabilitiesSeverityStatsGet = companyId => {
  return performGet(apiUrl(`companies/${companyId}/vulnerabilities/stats/severity`));
};

// Vulnerability status

export const performVulnerabilityStatusPut = (companyId, vulnerabilityId, status, comment) => {
  return performPut(apiUrl(`companies/${companyId}/vulnerabilities/${vulnerabilityId}/status`), { status, comment });
};

export const performVulnerabilitiesStatusesPut = (companyId, vulnerabilityIdsAndStatuses) => {
  return performPut(apiUrl(`companies/${companyId}/vulnerabilities/status`), vulnerabilityIdsAndStatuses);
};

// Vulnerability comments

export const performVulnerabilityCommentsGet = (companyId, vulnerabilityId) => {
  return performGet(apiUrl(`companies/${companyId}/vulnerabilities/${vulnerabilityId}/comments`));
};

export const performVulnerabilityCommentPost = (companyId, vulnerabilityId, commentData) => {
  return performPost(apiUrl(`companies/${companyId}/vulnerabilities/${vulnerabilityId}/comments`), commentData);
};

export const performVulnerabilityCommentDelete = (companyId, commentId) => {
  return performDelete(apiUrl(`companies/${companyId}/vulnerabilities/comments/${commentId}`));
};

// Events

export const performEventsGet = companyId => {
  return performGet(apiUrl(`companies/${companyId}/events`));
};

// Misc

export const getWidgetTypes = () => {
  return getRequest(apiUrl(`widget-types`));
};

export const performHostnamesResolutionPost = data => {
  return performPost(apiUrl(`resolve-hostnames`), data);
};
