import React, { useCallback, useEffect, useState } from "react";
import { performCompanyAssetsStatusStatsGet, performCompanyServicesStatusStatsGet } from "../../../api";
import CenteredVertically from "../../../components/containers/CenteredVertically";
import ContentBlock, { ContentBlockTitle, ContentSection } from "../../../components/containers/ContentBlock";
import LoadingContainer from "../../../components/containers/LoadingContainer";
import TwoColumnLayout, { TwoColumnLayoutColumn } from "../../../components/containers/TwoColumnLayout";
import StatisticsNumber from "../../../components/misc/Factoid";
import FactoidDelta from "../../../components/misc/FactoidDelta";
import Circle from "../../../components/shapes/Circle";
import {
  ASSET_STATUS_APPROVED,
  ASSET_STATUS_APPROVED_BY_BIZONE,
  ASSET_STATUS_APPROVED_BY_CLIENT,
  ASSET_STATUS_NOT_APPROVED,
  SERVICE_STATUS_OPEN
} from "../../../utils/enums";

export default ({ companyId }) => {
  const [currentAssetsStatusStats, setCurrentAssetsStatusStats] = useState(undefined);
  const [weekAgoAssetsStatusStats, setWeekAgoAssetsStatusStats] = useState(undefined);

  const [currentServicesStatusStats, setCurrentServicesStatusStats] = useState(undefined);
  const [weekAgoServicesStatusStats, setWeekAgoServicesStatusStats] = useState(undefined);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const loadAssetsStats = useCallback(async () => {
    setLoading(true);

    try {
      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 7);

      const currentAssetsStatsResponse = await performCompanyAssetsStatusStatsGet(companyId);
      setCurrentAssetsStatusStats(currentAssetsStatsResponse.data);

      const weekAgoAssetsStatusStatsResponse = await performCompanyAssetsStatusStatsGet(companyId, {
        before: weekAgo.toISOString()
      });
      setWeekAgoAssetsStatusStats(weekAgoAssetsStatusStatsResponse.data);

      const currentServicesStatsResponse = await performCompanyServicesStatusStatsGet(companyId);
      setCurrentServicesStatusStats(currentServicesStatsResponse.data);

      const weekAgoServicesStatusStatsResponse = await performCompanyServicesStatusStatsGet(companyId, {
        before: weekAgo.toISOString()
      });
      setWeekAgoServicesStatusStats(weekAgoServicesStatusStatsResponse.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    loadAssetsStats();
  }, [loadAssetsStats]);

  return (
    <ContentBlock>
      <ContentBlockTitle>Projects</ContentBlockTitle>

      <ContentSection flex={"1 0"}>
        <CenteredVertically expand>
          <LoadingContainer
            loading={loading}
            hasError={!!error}
            renderError={() => <div>Got error {JSON.stringify(error)}</div>}
            renderData={() => {
              return (
                <TwoColumnLayout>
                  <TwoColumnLayoutColumn>
                    <div>
                      <Circle size={8} color="#3cc151" />
                      Open Ports
                    </div>
                    <StatisticsNumber primary>
                      {currentServicesStatusStats[SERVICE_STATUS_OPEN]}
                      <FactoidDelta
                        delta={
                          currentServicesStatusStats[SERVICE_STATUS_OPEN] -
                          weekAgoServicesStatusStats[SERVICE_STATUS_OPEN]
                        }
                      />
                    </StatisticsNumber>
                  </TwoColumnLayoutColumn>
                  <TwoColumnLayoutColumn>
                    <div>
                      <Circle size={8} color="#999999" />
                      In coordination
                    </div>
                    <StatisticsNumber>
                      {currentAssetsStatusStats[ASSET_STATUS_NOT_APPROVED] +
                        currentAssetsStatusStats[ASSET_STATUS_APPROVED_BY_BIZONE] +
                        currentAssetsStatusStats[ASSET_STATUS_APPROVED_BY_CLIENT]}
                    </StatisticsNumber>
                  </TwoColumnLayoutColumn>
                </TwoColumnLayout>
              );
            }}
          />
        </CenteredVertically>
      </ContentSection>
    </ContentBlock>
  );
};
