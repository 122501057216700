import React, { useCallback } from "react";
import Checkbox from "../../components/controls/Checkbox";
import { DeleteButton } from "../../components/controls/IconButtons";
import Grid from "../../components/grid/Grid";
import GridRowHoverReveal from "../../components/grid/GridRowHoverReveal";
import TextWithColorIndicator from "../../components/misc/TextWithColorIndicator";
import GridAction from "../../components/grid/GridAction";
import { arrayCopyWithoutElement } from "../../utils/collections";
import {
  getVulnerabilitySeverityColor,
  USER_ROLE_COMPANY_ADMIN,
  userRoleIsOnBizoneSide,
  VULNERABILITY_STATUS_NEW,
  VULNERABILITY_STATUS_PENDING,
  VULNERABILITY_STATUS_RESOLVED,
  vulnerabilitySeverityToString
} from "../../utils/enums";
import { formatDate, formatVulnerabilityServiceName } from "../../utils/ui";

const gridSchema = {
  columns: [
    {
      key: "check",
      align: "center",
      minWidth: "50px",
      notPadded: true
    },
    {
      key: "asset_ip",
      name: "Project",
      minWidth: "200px",
      widthPercentage: 50,
      sortable: true
    },
    {
      key: "name",
      name: "Name",
      minWidth: "200px",
      widthPercentage: 50,
      sortable: true
    },
    {
      key: "severity",
      name: "Severity",
      minWidth: "160px",
      sortable: true
    },
    {
      key: "created_at",
      name: "Date",
      minWidth: "140px",
      sortable: true
    },
    {
      key: "status_change",
      align: "center",
      minWidth: "100px"
    },
    {
      key: "delete",
      align: "center",
      minWidth: "50px",
      notPadded: true
    }
  ]
};

export default ({
  history,
  userRole,
  sortField,
  sortDirection,
  onSortingChanged,
  vulnerabilities,
  vulnerabilitiesStatus,
  checkedIndices,
  setCheckedIndices,
  onResolveRequest,
  onApprovalRequest,
  onDeletionRequest
}) => {
  const showCheckboxes = vulnerabilitiesStatus === VULNERABILITY_STATUS_NEW;

  const showResolveButton =
    userRole === USER_ROLE_COMPANY_ADMIN && vulnerabilitiesStatus === VULNERABILITY_STATUS_PENDING;
  const showApproveOrDeclineButton =
    userRoleIsOnBizoneSide(userRole) && vulnerabilitiesStatus === VULNERABILITY_STATUS_RESOLVED;
  const showStatusChangeButton = showResolveButton || showApproveOrDeclineButton;

  const showDeleteButton = userRoleIsOnBizoneSide(userRole);

  const handleResolveClick = useCallback(
    (event, vulnerability) => {
      event.stopPropagation();
      onResolveRequest(vulnerability);
    },
    [onResolveRequest]
  );

  const handleApproveOrCancelClick = useCallback(
    (event, vulnerability) => {
      event.stopPropagation();
      onApprovalRequest(vulnerability);
    },
    [onApprovalRequest]
  );

  const handleCheckboxChange = useCallback(
    (event, vulnerabilityIndex) => {
      if (!event.target.checked) {
        setCheckedIndices(arrayCopyWithoutElement(checkedIndices, vulnerabilityIndex));
      } else {
        setCheckedIndices([...checkedIndices, vulnerabilityIndex]);
      }
    },
    [checkedIndices, setCheckedIndices]
  );

  const handleRowClick = useCallback(
    vulnerability => {
      history.push(`/vulnerabilities/view/${vulnerability.id}`);
    },
    [history]
  );

  return (
    <Grid
      schema={gridSchema}
      shouldShowColumn={columnKey => {
        switch (columnKey) {
          case "check":
            return showCheckboxes;
          case "status_change":
            return showStatusChangeButton;
          case "delete":
            return showDeleteButton;
          default:
            return true;
        }
      }}
      items={vulnerabilities}
      getItemKey={vulnerability => vulnerability.id}
      onItemClick={handleRowClick}
      onSortingChanged={onSortingChanged}
      sortKey={sortField}
      sortDirection={sortDirection}
      renderItem={(vulnerability, columnKey, vulnerabilityIndex) => {
        switch (columnKey) {
          case "check": {
            return (
              <Checkbox
                onClick={event => event.stopPropagation()}
                checked={checkedIndices.includes(vulnerabilityIndex)}
                onChange={event => handleCheckboxChange(event, vulnerabilityIndex)}
              />
            );
          }

          case "asset_ip": {
            return <span>{formatVulnerabilityServiceName(vulnerability)}</span>;
          }

          case "name": {
            return <span>{vulnerability.name}</span>;
          }

          case "severity": {
            return (
              <TextWithColorIndicator
                color={getVulnerabilitySeverityColor(vulnerability.severity)}
                text={vulnerabilitySeverityToString(vulnerability.severity)}
              />
            );
          }

          case "created_at": {
            return formatDate(vulnerability.createdAt);
          }

          case "status_change": {
            return (
              <React.Fragment>
                {showResolveButton && (
                  <GridAction onClick={event => handleResolveClick(event, vulnerability)}>Resolve</GridAction>
                )}
                {showApproveOrDeclineButton && (
                  <GridAction onClick={event => handleApproveOrCancelClick(event, vulnerability)}>
                    Approve/Decline
                  </GridAction>
                )}
              </React.Fragment>
            );
          }

          case "delete": {
            return (
              <GridRowHoverReveal>
                <DeleteButton
                  onClick={event => {
                    event.stopPropagation();
                    onDeletionRequest(vulnerability);
                  }}
                />
              </GridRowHoverReveal>
            );
          }

          default:
            break;
        }
      }}
    />
  );
};
