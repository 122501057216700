import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { css } from "styled-components";

export default styled(Link)`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  color: #fff;
  padding: 0 24px;
  height: 100%;
  transition: background 0.2s ease-in-out;

  & a:hover {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.2);
  }

  & span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${({ fixedWidth }) =>
    fixedWidth &&
    css`
      width: ${fixedWidth};
    `}
`;
