import React from "react";
import styled from "styled-components/macro";
import { USER_ROLE_BIZONE_ADMIN } from "../../utils/enums";
import useActiveUser from "../../utils/useActiveUser";
import BiZoneLogo from "../misc/BiZoneLogo";
import CompanySwitcher from "../navigation/CompanySwitcher";
import NavigationLink from "../navigation/NavigationLink";
import UserPopupMenu from "../navigation/UserPopupMenu";
import Content from "./Content";

const HeaderContainer = styled.header`
  background: #2b3a4d;
`;

const HeaderInner = styled.div`
  height: 54px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NavigationItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 1 auto;
  height: 100%;
`;

const BiZoneLogoContainer = styled.div`
  margin: 0 0 0 0;
`;

export default () => {
  const userProfile = useActiveUser();

  return (
    <HeaderContainer>
      <Content>
        <HeaderInner>
          <NavigationItemsContainer>
            <BiZoneLogoContainer>
              <BiZoneLogo />
            </BiZoneLogoContainer>

            <NavigationLink to="/assets/agreed">Projects</NavigationLink>
            <NavigationLink to="/vulnerabilities/pending">Vulnerabilities</NavigationLink>

            {userProfile.role === USER_ROLE_BIZONE_ADMIN && (
              <React.Fragment>
                <NavigationLink to="/admin">Administrator</NavigationLink>
                <NavigationLink to="/api-keys">API keys</NavigationLink>
              </React.Fragment>
            )}
          </NavigationItemsContainer>

          <NavigationItemsContainer>
            <UserPopupMenu />
            <CompanySwitcher />
          </NavigationItemsContainer>
        </HeaderInner>
      </Content>
    </HeaderContainer>
  );
};
