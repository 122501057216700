import React from "react";
import LoadingIndicator from "../misc/LoadingIndicator";

export default ({ loading, hasError, renderLoading, renderError, renderData }) => {
  if (loading) {
    return renderLoading ? renderLoading() : <LoadingIndicator />;
  } else {
    return hasError ? renderError() : renderData();
  }
};
