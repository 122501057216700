import React from "react";
import CenteredHorizontally from "./CenteredHorizontally";
import CenteredVertically from "./CenteredVertically";

export default ({ children }) => {
  return (
    <CenteredVertically expand>
      <CenteredHorizontally expand>{children}</CenteredHorizontally>
    </CenteredVertically>
  );
};
