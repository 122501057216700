import React from "react";
import styled from "styled-components/macro";
import Spinner from "./Spinner";

const SpinnerContainer = styled.div`
  display: flex;
  justify-self: stretch;
  align-items: center;
  justify-items: center;
  justify-content: center;
  flex: 1 0 auto;
`;

const LoadingIndicator = () => {
  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

export default LoadingIndicator;
