export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";

export const FETCH_COMPANY_USERS_REQUEST = "FETCH_COMPANY_USERS_REQUEST";
export const FETCH_COMPANY_USERS_SUCCESS = "FETCH_COMPANY_USERS_SUCCESS";
export const FETCH_COMPANY_USERS_FAILURE = "FETCH_COMPANY_USERS_FAILURE";

export const TOGGLE_USER_ACTIVATION_SUCCESS = "TOGGLE_USER_ACTIVATION_SUCCESS";

export const fetchCompanyRequest = companyId => ({ type: FETCH_COMPANY_REQUEST, companyId });

export const fetchCompanySuccess = company => ({ type: FETCH_COMPANY_SUCCESS, company });

export const fetchCompanyFailure = error => ({ type: FETCH_COMPANY_FAILURE, error });

export const fetchCompanyUsersRequest = companyId => ({ type: FETCH_COMPANY_USERS_REQUEST, companyId });

export const fetchCompanyUsersSuccess = (companyId, users) => ({ type: FETCH_COMPANY_USERS_SUCCESS, companyId, users });

export const fetchCompanyUsersFailure = error => ({ type: FETCH_COMPANY_USERS_FAILURE, error });

// The actual request to toggle user's activation state is meant to be handled locally in a component (for now in CompanyPage.js).
// After the successful request the component can set "active" user field in the redux store with this action.
export const toggleUserActivationSuccess = (userId, active) => ({
  type: TOGGLE_USER_ACTIVATION_SUCCESS,
  userId,
  active
});
