import {
  ASSET_STATUS_APPROVED,
  ASSET_STATUS_APPROVED_BY_BIZONE,
  ASSET_STATUS_APPROVED_BY_CLIENT,
  ASSET_STATUS_NOT_APPROVED
} from "../../utils/enums";
import {
  ASSETS_KIND_AGREED,
  CHANGE_SHOWN_ASSETS_KIND,
  LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_FAILURE,
  LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_REQUEST,
  LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_SUCCESS,
  SELECT_DISCOVERY_SCANS_FOR_COMPARISON
} from "./actions";

const initialState = {
  needsRefresh: false,
  items: null,
  loading: false,
  error: null,
  lastLoadedPage: null,
  pagesCount: null,
  shownAssetStatuses: null,
  selectedLeftScanId: null,
  selectedRightScanId: null
};

const assetStatusesByAssetKind = kind => {
  return kind === ASSETS_KIND_AGREED
    ? [ASSET_STATUS_APPROVED]
    : [ASSET_STATUS_NOT_APPROVED, ASSET_STATUS_APPROVED_BY_BIZONE, ASSET_STATUS_APPROVED_BY_CLIENT];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SHOWN_ASSETS_KIND: {
      return {
        needsRefresh: true,
        items: null,
        loading: false,
        error: null,
        lastLoadedPage: null,
        pagesCount: null,
        shownAssetStatuses: assetStatusesByAssetKind(action.kind),
        selectedLeftScanId: null,
        selectedRightScanId: null
      };
    }

    case SELECT_DISCOVERY_SCANS_FOR_COMPARISON: {
      const bothScansAreSet = action.leftScanId !== null && action.rightScanId !== null;
      const bothScansWereSet = state.selectedLeftScanId !== null && state.selectedRightScanId !== null;
      const needsRefresh = bothScansWereSet || bothScansAreSet;

      return {
        ...state,
        needsRefresh,
        pagesCount: needsRefresh ? null : state.pagesCount,
        selectedLeftScanId: action.leftScanId,
        selectedRightScanId: action.rightScanId
      };
    }

    case LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_REQUEST: {
      return {
        ...state,
        needsRefresh: false,
        items: action.page === 1 ? [] : state.items,
        loading: true,
        error: null,
        lastLoadedPage: action.page,
        pagesCount: action.page === 1 ? null : state.pagesCount
      };
    }

    case LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_SUCCESS: {
      return {
        ...state,
        items: state.items.concat(action.items),
        pagesCount: action.pagesCount,
        loading: false,
        error: null
      };
    }

    case LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_FAILURE: {
      const error = action.error;

      return {
        ...state,
        loading: false,
        error: error
      };
    }

    default: {
      return state;
    }
  }
};
