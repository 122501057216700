import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { ContentBlockTitle, ContentSection } from "../../components/containers/ContentBlock";
import LoadingContainer from "../../components/containers/LoadingContainer";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import ModalDialog from "../../components/modal/ModalDialog";
import { fetchAllCompaniesRequest } from "../../redux/company-list/actions";
import { changeUserCompaniesRequest, closeUserCompaniesDialog } from "../../redux/user-change-companies/action";
import { arrayCopyWithoutElement } from "../../utils/collections";
import { findPortal } from "../../utils/ui";

const CompaniesSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

const CompaniesList = styled.div`
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

const CompaniesListContent = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

const CompaniesListHeader = styled.h3`
  font-size: 20px;
  font-weight: 600;
`;

const CompanyOption = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 16px;
  margin-bottom: 3px;
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export default () => {
  const isOpen = useSelector(state => state.changeUserCompanies.dialogOpen);

  const isSubmitting = useSelector(state => state.changeUserCompanies.isSubmitting);
  const error = useSelector(state => state.changeUserCompanies.error);

  const userId = useSelector(state => state.changeUserCompanies.userId);

  const companiesById = useSelector(state => state.entities.companiesById);
  const usersById = useSelector(state => state.entities.usersById);

  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCompaniesRequest());
  }, [dispatch]);

  useEffect(() => {
    const user = usersById[userId];

    if (user) {
      const userCompanies = user.companyIds.map(companyId => companiesById[companyId]);
      setSelectedCompanies(userCompanies);

      const allCompanies = Object.keys(companiesById).map(companyId => companiesById[companyId]);
      setAvailableCompanies(
        allCompanies.filter(company => !userCompanies.find(userCompany => company.id === userCompany.id))
      );
    }
  }, [companiesById, usersById, userId]);

  const handleSubmit = event => {
    event.preventDefault();

    const companyIds = selectedCompanies.map(company => company.id);
    dispatch(changeUserCompaniesRequest(userId, companyIds));
  };

  const selectCompany = companyIndex => {
    const company = availableCompanies[companyIndex];

    setAvailableCompanies(arrayCopyWithoutElement(availableCompanies, company));
    setSelectedCompanies([...selectedCompanies, company]);
  };

  const deselectCompany = companyIndex => {
    const company = selectedCompanies[companyIndex];

    setSelectedCompanies(arrayCopyWithoutElement(selectedCompanies, company));
    setAvailableCompanies([...availableCompanies, company]);
  };

  return (
    isOpen &&
    ReactDOM.createPortal(
      <form onSubmit={handleSubmit}>
        <ModalDialog width={800} height={"calc(100vh - 200px)"}>
          <ContentBlockTitle>Change user companies</ContentBlockTitle>

          <ContentSection flex="1 0 0">
            <LoadingContainer
              loading={false}
              hasError={!!error}
              renderError={() => <div>Got error {JSON.stringify(error)}</div>}
              renderData={() => (
                <CompaniesSelectionContainer>
                  <CompaniesList>
                    <CompaniesListHeader>Available companies</CompaniesListHeader>

                    <CompaniesListContent>
                      {availableCompanies.map((company, index) => (
                        <CompanyOption onClick={() => selectCompany(index)} key={`available-${company.id}`}>
                          {company.name}
                        </CompanyOption>
                      ))}
                    </CompaniesListContent>
                  </CompaniesList>

                  <CompaniesList>
                    <CompaniesListHeader>Selected companies</CompaniesListHeader>

                    <CompaniesListContent>
                      {selectedCompanies.map((company, index) => (
                        <CompanyOption onClick={() => deselectCompany(index)} key={`available-${company.id}`}>
                          {company.name}
                        </CompanyOption>
                      ))}
                    </CompaniesListContent>
                  </CompaniesList>
                </CompaniesSelectionContainer>
              )}
            />
          </ContentSection>

          <ContentSection>
            <ButtonsRow>
              <Button disabled={isSubmitting} as="input" accented type="submit" value="Save" />
              <Button onClick={() => dispatch(closeUserCompaniesDialog())}>Cancel</Button>
            </ButtonsRow>
          </ContentSection>
        </ModalDialog>
      </form>,
      findPortal()
    )
  );
};
