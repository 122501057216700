import React, { useCallback } from "react";
import { DeleteButton } from "../../components/controls/IconButtons";
import Grid from "../../components/grid/Grid";
import { withRouter } from "react-router-dom";
import GridRowHoverReveal from "../../components/grid/GridRowHoverReveal";
import TextWithColorIndicator from "../../components/misc/TextWithColorIndicator";
import GridAction from "../../components/grid/GridAction";
import {
  ASSET_STATUS_APPROVED_BY_BIZONE,
  ASSET_STATUS_APPROVED_BY_CLIENT,
  ASSET_STATUS_NOT_APPROVED,
  getServiceStatusColor,
  serviceStatusToString,
  USER_ROLE_BIZONE_ADMIN,
  USER_ROLE_BIZONE_EMPLOYEE,
  USER_ROLE_COMPANY_ADMIN,
  userRoleIsOnBizoneSide
} from "../../utils/enums";
import { formatDate, fullAssetName } from "../../utils/ui";

const gridSchema = {
  columns: [
    {
      key: "ip",
      name: "Project",
      minWidth: "180px",
      widthPercentage: 70,
      sortable: true
    },
    {
      key: "vulnerabilities_count",
      name: "Vulnerabilities",
      align: "right",
      minWidth: "140px",
      sortable: true
    },
    {
      key: "status",
      name: "Status",
      minWidth: "160px",
      sortable: true
    },
    {
      key: "approve",
      align: "center",
      minWidth: "100px"
    },
    {
      key: "delete",
      align: "center",
      minWidth: "50px",
      notPadded: true
    }
  ]
};

export default withRouter(({
  history,
  assetsAndServices,
  userRole,
  sortField,
  sortDirection,
  onSortingChanged,
  onAssetApprove,
  onAssetDelete
}) => {
  const showDeleteColumn = [USER_ROLE_BIZONE_ADMIN, USER_ROLE_BIZONE_EMPLOYEE].includes(userRole);
  const showApproveColumn = [USER_ROLE_BIZONE_ADMIN, USER_ROLE_BIZONE_EMPLOYEE, USER_ROLE_COMPANY_ADMIN].includes(
    userRole
  );

  const handleRowClick = useCallback(
    assetAndService => {
      history.push({pathname: "/vulnerabilities/pending", state: {search:assetAndService.asset.ip}});
    },
    [history]
  );

  return (
    <React.Fragment>
      <Grid
        schema={gridSchema}
        shouldShowColumn={columnKey => {
          switch (columnKey) {
            case "approve":
              return showApproveColumn;
            case "delete":
              return showDeleteColumn;
            default:
              return true;
          }
        }}
        items={assetsAndServices}
        getItemKey={assetAndService =>
          `${assetAndService.asset.id}-${assetAndService.service && assetAndService.service.id}`
        }
        onItemClick={handleRowClick}
        onSortingChanged={onSortingChanged}
        sortKey={sortField}
        sortDirection={sortDirection}
        renderItem={(assetAndService, columnKey) => {
          const asset = assetAndService.asset;
          const service = assetAndService.service;
          const vulnerabilitiesCount = assetAndService.vulnerabilitiesCount;

          switch (columnKey) {
            case "ip": {
              return <span>{fullAssetName(asset)}</span>;
            }

            case "port": {
              return service ? service.port : "---";
            }

            case "name": {
              return service ? service.name : "---";
            }

            case "status": {
              return service ? (
                <TextWithColorIndicator
                  color={getServiceStatusColor(service.status)}
                  text={serviceStatusToString(service.status)}
                />
              ) : (
                "---"
              );
            }

            case "vulnerabilities_count": {
              return vulnerabilitiesCount;
            }
            
            case "approve": {
              const actionableStatusForBizone =
                asset.status === ASSET_STATUS_NOT_APPROVED || asset.status === ASSET_STATUS_APPROVED_BY_CLIENT;
              const actionableStatusForCompany =
                asset.status === ASSET_STATUS_NOT_APPROVED || asset.status === ASSET_STATUS_APPROVED_BY_BIZONE;

              const showApproveButton =
                (actionableStatusForBizone && userRoleIsOnBizoneSide(userRole)) ||
                (actionableStatusForCompany && userRole === USER_ROLE_COMPANY_ADMIN);

              return (
                showApproveButton && (
                  <GridAction
                    onClick={event => {
                      event.stopPropagation();
                      onAssetApprove(asset);
                    }}
                  >
                    Approve
                  </GridAction>
                )
              );
            }

            case "delete": {
              return (
                <GridRowHoverReveal>
                  <DeleteButton
                    onClick={event => {
                      event.stopPropagation();
                      onAssetDelete(assetAndService.asset.id);
                    }}
                  />
                </GridRowHoverReveal>
              );
            }

            default:
              break;
          }
        }}
      />
    </React.Fragment>
  );
});
