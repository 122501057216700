import React, { useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import { performCompanyAssetIpsGet } from "../../api";
import { ContentBlockTitle, ContentSection } from "../../components/containers/ContentBlock";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import FormInput from "../../components/controls/Input";
import FormSelect from "../../components/controls/Select";
import FormTextArea from "../../components/controls/TextArea";
import FormField from "../../components/forms/FormVerticalField";
import FormHiddenField from "../../components/forms/FormHiddenField";
import ModalDialog from "../../components/modal/ModalDialog";
import { showErrorToast } from "../../components/Toast";
import { allVulnerabilitySeverities, ASSET_STATUS_APPROVED, allVulnerabilityStatuses } from "../../utils/enums";
import { findPortal } from "../../utils/ui";
import useFocus from "../../utils/useFocus";
import { validate } from "../../utils/validation";

const validationScheme = yup.object().shape({
  name: yup.string().required("Please enter vulnerability's name"),
  cvss: yup
    .number()
    .typeError("Please enter a number that represents vulnerability's CVSS score")
    .min(0, "CVSS must be a number from 0.0 to 10.0")
    .max(10, "CVSS must be a number from 0.0 to 10.0"),
  cvssVector: yup.string().required("Please enter vulnerability's CVSS vector"),
  ip: yup.string().required("Please enter vulnerability's ip address"),
  port: yup
    .number()
    .typeError("Please enter vulnerability's port number")
    .min(1, "Port must be an integer from 1 to 65535")
    .max(65535, "Port must be an integer from 1 to 65535"),
  description: yup.string().required("Please enter vulnerability's description"),
  fixRecommendations: yup.string().required("Please enter vulnerability's fix recommendations"),
  expertComments: yup.string().required("Please enter comments from triage expert"),
  codeSnippet: yup.string().required("Please enter vulnerable code snippet"),
  vulnLine: yup.string().required("Please enter vulnerable code line number"),
  similarityId: yup.string().required("Please enter similarity id")
});

const VulnerabilityDialog = ({ companySwitcher, editedVulnerability, onSubmit, onClose }) => {
  const [selectedIp, setSelectedIp] = useState(editedVulnerability.service && editedVulnerability.service.asset.ip);
  const [validationErrors, setValidationErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const nameInput = useRef(null);
  const severitySelect = useRef(null);
  const statusSelect = useRef(null);
  const cvssInput = useRef(null);
  const cvssVectorInput = useRef(null);
  const portInput = useRef(null);
  const descriptionTextArea = useRef(null);
  const recommendationsTextArea = useRef(null);
  const expertcommentsTextArea = useRef(null);
  const codeSnippetTextArea = useRef(null);
  const vulnLineTextArea = useRef(null);
  const similarityIdTextArea = useRef(null);

  useFocus(nameInput);

  const activeCompany = companySwitcher.activeCompany;

  const handleSubmit = async event => {
    event.preventDefault();

    const formData = {
      name: nameInput.current.value,
      severity: severitySelect.current.value,
      status: statusSelect.current.value,
      cvss: cvssInput.current.value,
      cvssVector: cvssVectorInput.current.value,
      ip: selectedIp,
      port: portInput.current.value,
      description: descriptionTextArea.current.value,
      fixRecommendations: recommendationsTextArea.current.value,
      expertComments: expertcommentsTextArea.current.value,
      codeSnippet: codeSnippetTextArea.current.value,
      vulnLine: vulnLineTextArea.current.value,
      similarityId: similarityIdTextArea.current.value
    };

    try {
      await validate(validationScheme, formData);
    } catch (errors) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});
    setSubmitting(true);

    const onError = error => {
      if (error.errorCode === "not-found-error" && error.details && error.details.port) {
        showErrorToast(`Unable to find port ${error.details.port} on ip ${formData.ip}`);
      }
      setSubmitting(false);
    };

    onSubmit(formData, onError);
  };

  const loadAssetOptions = (inputValue, callback) => {
    performCompanyAssetIpsGet(activeCompany.id, {
      text: inputValue,
      status: ASSET_STATUS_APPROVED
    }).then(response => {
      callback(response.data.map(ip => ({ value: ip, label: ip })));
    });
  };

  return ReactDOM.createPortal(
    <form onSubmit={handleSubmit}>
      <ModalDialog>
        <ContentBlockTitle>Add vulnerability</ContentBlockTitle>

        <ContentSection scrollable>
          <FormField
            label="Name"
            input={<FormInput ref={nameInput} type="text" defaultValue={editedVulnerability.name} />}
            error={validationErrors.name}
          />

          <FormField
            label="Severity"
            input={
              <FormSelect ref={severitySelect} defaultValue={editedVulnerability.severity}>
                {allVulnerabilitySeverities.map((severity, index) => (
                  <option key={index} value={severity}>
                    {severity}
                  </option>
                ))}
              </FormSelect>
            }
          />

          <FormField
            label="Status"
            input={
              <FormSelect ref={statusSelect} defaultValue={editedVulnerability.status}>
                {allVulnerabilityStatuses.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
              </FormSelect>
            }
          />

          <FormHiddenField
            label="CVSS"
            input={<FormInput ref={cvssInput} type="text" defaultValue="1.0" />}
            error={validationErrors.cvss}
          />

          <FormHiddenField
            label="CVSS Vector"
            input={<FormInput ref={cvssVectorInput} type="text" defaultValue="a" />}
            error={validationErrors.cvssVector}
          />

          <FormHiddenField
            label="Similarity ID"
            input={<FormInput ref={similarityIdTextArea} type="text" defaultValue={editedVulnerability.similarityId} />}
            error={validationErrors.similarityId}
          />

          <FormField
            label="Project"
            input={
              <AsyncSelect
                cacheOptions={false}
                loadOptions={loadAssetOptions}
                defaultOptions={false}
                defaultValue={{ label: selectedIp, value: selectedIp }}
                onChange={selectedOption => setSelectedIp(selectedOption.value)}
              />
            }
            error={validationErrors.ip}
          />

          <FormHiddenField
            label="Service Port"
            input={
              <FormInput
                ref={portInput}
                type="text"
                defaultValue="1"
              />
            }
            error={validationErrors.port}
          />

          <FormField
            label="Description"
            input={<FormTextArea ref={descriptionTextArea} rows={5} defaultValue={editedVulnerability.description} />}
            error={validationErrors.description}
          />

          <FormField
            label="Recommendations"
            input={
              <FormTextArea
                ref={recommendationsTextArea}
                rows={5}
                defaultValue={editedVulnerability.fixRecommendations}
              />
            }
            error={validationErrors.fixRecommendations}
          />

          <FormField
            label="Expert comments"
            input={
              <FormTextArea
                ref={expertcommentsTextArea}
                rows={5}
                defaultValue={editedVulnerability.expertComments}
              />  
            }
            error={validationErrors.expertComments}
          />

          <FormField
            label="Vulnerable code line"
            input={
              <FormTextArea
                ref={vulnLineTextArea}
                rows={5}
                defaultValue={editedVulnerability.vulnLine}
              />  
            }
            error={validationErrors.vulnLine}
          />

          <FormField
            label="Vulnerable code"
            input={
              <FormTextArea
                ref={codeSnippetTextArea}
                rows={5}
                defaultValue={editedVulnerability.codeSnippet}
              />  
            }
            error={validationErrors.codeSnippet}
          />
        </ContentSection>

        <ContentSection>
          <ButtonsRow>
            <Button disabled={submitting} accented={true} type="submit">
              Submit
            </Button>
            <Button onClick={onClose} type="button">
              Cancel
            </Button>
          </ButtonsRow>
        </ContentSection>
      </ModalDialog>
    </form>,
    findPortal()
  );
};

const mapStateToProps = state => {
  return {
    companySwitcher: state.companySwitcher
  };
};

export default connect(
  mapStateToProps,
  null
)(VulnerabilityDialog);
