import React, { useCallback, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import {
  performCompanyVulnerabilitiesGet,
  performCompanyVulnerabilitiesStatusStatsGet,
  performCompanyVulnerabilityPost,
  performVulnerabilitiesStatusesPut,
  performVulnerabilityDelete,
  performVulnerabilityStatusPut
} from "../../api";
import HavingUserRoles from "../../components/access/HavingUserRoles";
import ContentBlock, { ContentBlockTitle, ContentSection } from "../../components/containers/ContentBlock";
import LoadingContainer from "../../components/containers/LoadingContainer";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import Input from "../../components/controls/Input";
import Main from "../../components/layout/Main";
import TextWithColorIndicator from "../../components/misc/TextWithColorIndicator";
import TopActionBar, {
  TopActionBarButtonsContainer,
  TopActionBarLeft,
  TopActionBarRight
} from "../../components/layout/TopActionBar";
import Tab from "../../components/Tab";
import { showSuccessToast } from "../../components/Toast";
import { arrayCopyWithoutElement } from "../../utils/collections";
import {
  allVulnerabilityStatuses,
  USER_ROLE_BIZONE_ADMIN,
  USER_ROLE_BIZONE_EMPLOYEE,
  userRoleIsOnBizoneSide,
  VULNERABILITY_STATUS_NEW,
  VULNERABILITY_STATUS_PENDING,
  VULNERABILITY_STATUS_RESOLVE_APPROVED,
  VULNERABILITY_STATUS_RESOLVED,
  VULNERABILITY_STATUS_WONT_FIX,
  VULNERABILITY_STATUS_FALSE_POSITIVE,
  vulnerabilitySeverityToInt,
  vulnerabilitySeverityFromInt,
  getVulnerabilitySeverityColor,
  vulnerabilitySeverityToString,
  vulnerabilitySecurityLevelFromInt
} from "../../utils/enums";
import { withDefaultErrorHandling } from "../../utils/error-handling";
import { pageTitle } from "../../utils/ui";
import useActiveUser from "../../utils/useActiveUser";
import VulnerabilitiesTable from "./VulnerabilitiesTable";
import ApproveOrCancelVulnerabilityDialog from "./VulnerabilityApprovalDialog";
import VulnerabilityDialog from "./VulnerabilityDialog";

const ContentBlockTitleControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

const SearchInputContainer = styled.div`
  margin-left: 20px;
  width: 200px;
`;

const VulnerabilitiesPage = ({ companyId, match, history, props }) => {
  const userProfile = useActiveUser();

  const vulnerabilitiesStatus = match.params.status;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [checkedVulnerabilitiesIndices, setCheckedVulnerabilitiesIndices] = useState([]);

  const [newVulnerabilityDialogOpen, setNewVulnerabilityDialogOpen] = useState(false);
  const [vulnerabilityOpenForApproval, setVulnerabilityOpenForApproval] = useState(undefined);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(undefined);

  const [sortField, setSortField] = useState(undefined);
  const [sortDirection, setSortDirection] = useState(undefined);
  const [textSearch, setTextSearch] = useState(undefined);

  const [vulnerabilitiesStatusStats, setVulnerabilitiesStatusStats] = useState(undefined);
  const [needsStatusStatsRefresh, setNeedsStatusStatsRefresh] = useState(true);

  const [totalSeverity, setTotalSeverity] = useState(undefined);

  useEffect(() => {
    setNeedsStatusStatsRefresh(true);
  }, [companyId]);

  useEffect(() => {
    if (history.location.state) {
      setTextSearch(history.location.state.search);
    }
  }, []);

  useEffect(() => {
    if (needsStatusStatsRefresh) {
      const load = async () => {
        try {
          const response = await performCompanyVulnerabilitiesStatusStatsGet(companyId);
          setVulnerabilitiesStatusStats(response.data);
        } finally {
        }
      };

      load().then();
      setNeedsStatusStatsRefresh(false);
    }
  }, [needsStatusStatsRefresh]);

  const handleSortingChanged = useCallback((sortField, sortDirection) => {
    setSortField(sortField);
    setSortDirection(sortDirection);
  }, []);

  const loadVulnerabilitiesPage = useCallback(
    async (page, vulnerabilities) => {
      if (!companyId) {
        setVulnerabilities([]);
        setLoading(false);
        return;
      }

      if (page === 1) {
        setCheckedVulnerabilitiesIndices([]);
        setPagesCount(undefined);
      }

      setLoading(true);

      try {
        const params = {
          status: vulnerabilitiesStatus,
          page,
          text: textSearch,
          sortField,
          sortDirection
        };
        const response = await performCompanyVulnerabilitiesGet(companyId, params);

        setVulnerabilities(vulnerabilities.concat(response.data));
        setCurrentPage(page);
        setPagesCount(response.metadata.pagesCount);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [sortField, sortDirection, textSearch, companyId, vulnerabilitiesStatus]
  );

  const loadInitialVulnerabilities = useCallback(async () => {
    loadVulnerabilitiesPage(1, []);
  }, [loadVulnerabilitiesPage]);

  const loadNextVulnerabilitiesPage = useCallback(async () => {
    loadVulnerabilitiesPage(currentPage + 1, vulnerabilities);
  }, [loadVulnerabilitiesPage, currentPage, vulnerabilities]);

  const calculateSeverity = useCallback(async () => {
    try {
      const params = {
          status: [VULNERABILITY_STATUS_PENDING],
          page: 1,
          perPage: 9999999
        };
      const response = await performCompanyVulnerabilitiesGet(companyId, params);

      if (response.data.length === 0) {
        setTotalSeverity(-1)
      } else {

        const total_severity = response.data.reduce((total, current) => total = total + vulnerabilitySeverityToInt(current.severity), 0)

        setTotalSeverity((total_severity / response.data.length).toFixed(1))
      }
    } catch (error) {
      setError(error);
    }
  }, [])

  const openNewVulnerabilityDialog = useCallback(() => {
    setNewVulnerabilityDialogOpen(true);
  }, []);

  const closeNewVulnerabilityDialog = useCallback(() => {
    setNewVulnerabilityDialogOpen(false);
  }, []);

  const createVulnerability = useCallback(
    async (formData, onError) => {
      try {
        await performCompanyVulnerabilityPost(companyId, formData);

        setNewVulnerabilityDialogOpen(false);
        loadInitialVulnerabilities();
        setNeedsStatusStatsRefresh(true);
      } catch (error) {
        onError(error);
      }
    },
    [companyId, loadInitialVulnerabilities]
  );

  const deleteVulnerability = useCallback(
    async vulnerability => {
      if (!window.confirm("Are you sure you want to delete this vulnerability?")) {
        return;
      }

      await withDefaultErrorHandling(async () => {
        await performVulnerabilityDelete(companyId, vulnerability.id);
        setVulnerabilities(arrayCopyWithoutElement(vulnerabilities, vulnerability));
        setNeedsStatusStatsRefresh(true);
      });
    },
    [vulnerabilities, companyId]
  );

  const sendCheckedVulnerabilitiesToPending = useCallback(async () => {
    await withDefaultErrorHandling(async () => {
      const checkedVulnerabilities = [];

      const idsWithStatuses = checkedVulnerabilitiesIndices.map(index => {
        const checkedVulnerability = vulnerabilities[index];
        checkedVulnerabilities.push(checkedVulnerability);

        return { id: checkedVulnerability.id, status: VULNERABILITY_STATUS_PENDING };
      });

      await performVulnerabilitiesStatusesPut(companyId, idsWithStatuses);

      showSuccessToast(`${idsWithStatuses.length} vulnerabilities were send to pending`);

      checkedVulnerabilities.forEach(vulnerability => {
        vulnerabilities.splice(vulnerabilities.indexOf(vulnerability), 1);
      });
      setCheckedVulnerabilitiesIndices([]);
      setVulnerabilities([...vulnerabilities]);
      setNeedsStatusStatsRefresh(true);
    });
  }, [vulnerabilities, checkedVulnerabilitiesIndices, companyId]);

  const resolveVulnerability = useCallback(
    async vulnerability =>
      withDefaultErrorHandling(async () => {
        if (!window.confirm("Are you sure you want to resolve this vulnerability?")) {
          return;
        }

        await performVulnerabilityStatusPut(companyId, vulnerability.id, VULNERABILITY_STATUS_RESOLVED);
        setVulnerabilities(arrayCopyWithoutElement(vulnerabilities, vulnerability));
        setNeedsStatusStatsRefresh(true);
      }),
    [vulnerabilities, companyId]
  );

  const approveOrCancelVulnerability = useCallback(
    async (shouldApprove, comment) =>
      withDefaultErrorHandling(async () => {
        const nextStatus = shouldApprove ? VULNERABILITY_STATUS_RESOLVE_APPROVED : VULNERABILITY_STATUS_PENDING;

        await performVulnerabilityStatusPut(companyId, vulnerabilityOpenForApproval.id, nextStatus, comment);
        setVulnerabilities(arrayCopyWithoutElement(vulnerabilities, vulnerabilityOpenForApproval));
        setVulnerabilityOpenForApproval(undefined);
        setNeedsStatusStatsRefresh(true);
      }),
    [vulnerabilityOpenForApproval, vulnerabilities, companyId]
  );

  const openApprovalDialog = useCallback(vulnerability => {
    setVulnerabilityOpenForApproval(vulnerability);
  }, []);

  const closeApprovalDialog = useCallback(() => {
    setVulnerabilityOpenForApproval(undefined);
  }, []);

  useEffect(() => {
    loadInitialVulnerabilities();
    calculateSeverity();
  }, [loadInitialVulnerabilities]);

  useEffect(() => {
    const unknownStatus = !allVulnerabilityStatuses.includes(vulnerabilitiesStatus);
    const forbiddenStatus =
      !userRoleIsOnBizoneSide(userProfile.role) && vulnerabilitiesStatus === VULNERABILITY_STATUS_NEW;

    if (unknownStatus || forbiddenStatus) {
      history.replace("/404");
    }
  }, [history, userProfile.role, vulnerabilitiesStatus]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle("Vulnerabilities")}</title>
      </Helmet>

      <TopActionBar>
        <TopActionBarLeft>
          <Tab
            text="Pending"
            href="/vulnerabilities/pending"
            showCounter={true}
            counter={vulnerabilitiesStatusStats && vulnerabilitiesStatusStats[VULNERABILITY_STATUS_PENDING]}
          />
          <Tab
            text="Resolved"
            href="/vulnerabilities/resolved"
            showCounter={true}
            counter={vulnerabilitiesStatusStats && vulnerabilitiesStatusStats[VULNERABILITY_STATUS_RESOLVED]}
          />
          <Tab
            text="Resolve Approved"
            href="/vulnerabilities/resolve_approved"
            showCounter={true}
            counter={vulnerabilitiesStatusStats && vulnerabilitiesStatusStats[VULNERABILITY_STATUS_RESOLVE_APPROVED]}
          />
          {userRoleIsOnBizoneSide(userProfile.role) && (
            <Tab
              text="New"
              href="/vulnerabilities/new"
              showCounter={true}
              counter={vulnerabilitiesStatusStats && vulnerabilitiesStatusStats[VULNERABILITY_STATUS_NEW]}
            />
          )}
          {userRoleIsOnBizoneSide(userProfile.role) && (
            <Tab
              text="Won't fix"
              href="/vulnerabilities/wont_fix"
              showCounter={true}
              counter={vulnerabilitiesStatusStats && vulnerabilitiesStatusStats[VULNERABILITY_STATUS_WONT_FIX]}
            />
          )}
          {userRoleIsOnBizoneSide(userProfile.role) && (
            <Tab
              text="False positive"
              href="/vulnerabilities/false_positive"
              showCounter={true}
              counter={vulnerabilitiesStatusStats && vulnerabilitiesStatusStats[VULNERABILITY_STATUS_FALSE_POSITIVE]}
            />
          )}
        </TopActionBarLeft>
        <TopActionBarRight>
          <TopActionBarButtonsContainer>
            <ButtonsRow>
              {vulnerabilitiesStatus === VULNERABILITY_STATUS_NEW && (
                <Button
                  onClick={sendCheckedVulnerabilitiesToPending}
                  disabled={checkedVulnerabilitiesIndices.length === 0}
                  type="submit"
                >
                  Send
                </Button>
              )}
              <HavingUserRoles roles={[USER_ROLE_BIZONE_ADMIN, USER_ROLE_BIZONE_EMPLOYEE]} userRole={userProfile.role}>
                <Button onClick={openNewVulnerabilityDialog} accented={true} type="submit">
                  Add vulnerability
                </Button>
              </HavingUserRoles>
            </ButtonsRow>
          </TopActionBarButtonsContainer>
        </TopActionBarRight>
      </TopActionBar>

      <Main>
        {!companyId ? (
          <div>There is no active company selected. Nothing to display.</div>
        ) : (
          <ContentBlock>
            <ContentBlockTitle>
              Vulnerabilities
              <ContentBlockTitleControls>
                { vulnerabilitiesStatus === VULNERABILITY_STATUS_PENDING && (
                  <LoadingContainer
                    loading={totalSeverity === undefined}
                    hasError={totalSeverity === -1}
                    renderError={() => ("")}
                    renderData={() => (<Button disabled style={ { "background-color": getVulnerabilitySeverityColor(vulnerabilitySeverityFromInt(Math.round(totalSeverity))), "color": "#fff" } }>
                      Average Security Level:&nbsp;
                      { `${vulnerabilitySeverityToString(vulnerabilitySecurityLevelFromInt(Math.round(totalSeverity)))} (${5 - totalSeverity} / 5)` }
                      </Button>
                    )}
                  />
                ) }
                <SearchInputContainer>
                  <DebounceInput
                    value={ textSearch }
                    element={Input}
                    minLength={2}
                    debounceTimeout={500}
                    type="text"
                    placeholder="What are we looking for?"
                    onChange={event => setTextSearch(event.target.value)}
                  />
                </SearchInputContainer>
              </ContentBlockTitleControls>
            </ContentBlockTitle>

            <ContentSection>
              <LoadingContainer
                hasError={!loading && error}
                loading={pagesCount === undefined && loading}
                renderError={() => `Got error ${JSON.stringify(error)}`}
                renderData={() => (
                  <React.Fragment>
                    {vulnerabilities && vulnerabilities.length > 0 ? (
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={loadNextVulnerabilitiesPage}
                        hasMore={pagesCount > currentPage && !loading}
                        initialLoad={false}
                        loader={
                          <div className="loader" key={"loader"}>
                            Loading ...
                          </div>
                        }
                      >
                        <VulnerabilitiesTable
                          history={history}
                          userRole={userProfile.role}
                          sortField={sortField}
                          sortDirection={sortDirection}
                          onSortingChanged={handleSortingChanged}
                          vulnerabilities={vulnerabilities}
                          vulnerabilitiesStatus={vulnerabilitiesStatus}
                          checkedIndices={checkedVulnerabilitiesIndices}
                          setCheckedIndices={setCheckedVulnerabilitiesIndices}
                          onResolveRequest={resolveVulnerability}
                          onApprovalRequest={openApprovalDialog}
                          onDeletionRequest={deleteVulnerability}
                        />
                      </InfiniteScroll>
                    ) : (
                      <div>No vulnerabilities to show.</div>
                    )}
                  </React.Fragment>
                )}
              />
            </ContentSection>
          </ContentBlock>
        )}
      </Main>

      {newVulnerabilityDialogOpen && (
        <VulnerabilityDialog
          editedVulnerability={{}}
          onClose={closeNewVulnerabilityDialog}
          onSubmit={createVulnerability}
        />
      )}

      {vulnerabilityOpenForApproval && (
        <ApproveOrCancelVulnerabilityDialog
          onApproveOrCancel={approveOrCancelVulnerability}
          onClose={closeApprovalDialog}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    companyId: state.companySwitcher.activeCompany ? state.companySwitcher.activeCompany.id : undefined
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(VulnerabilitiesPage)
);
