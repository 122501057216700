import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalDialog from "../../components/modal/ModalDialog";
import { showErrorToast } from "../../components/Toast";
import { openChangePasswordDialog } from "../../redux/change-password/actions";
import { openUserCompaniesDialog } from "../../redux/user-change-companies/action";
import { closeEditUserDialog, updateUserRequest } from "../../redux/user-edit/actions";
import { USER_ROLE_BIZONE_ADMIN } from "../../utils/enums";
import { findPortal } from "../../utils/ui";
import useActiveUser from "../../utils/useActiveUser";
import UserForm from "../administration/UserForm";

export default () => {
  const activeUser = useActiveUser();

  const usersById = useSelector(state => state.entities.usersById);
  const userId = useSelector(state => state.editUser.userId);
  const isOpen = useSelector(state => state.editUser.dialogOpen);
  const isSubmitting = useSelector(state => state.editUser.submitting);
  const error = useSelector(state => state.editUser.error);

  const dispatch = useDispatch();

  const handleSubmit = formData => {
    const userData = { ...formData };
    dispatch(updateUserRequest(userId, userData));
  };

  useEffect(() => {
    if (error) {
      showErrorToast(`Unable to update user. ${error.message}`);
    }
  }, [error]);

  const editedUser = usersById[userId];

  return (
    isOpen &&
    ReactDOM.createPortal(
      <ModalDialog>
        <UserForm
          title={
            editedUser.id === activeUser.id ? "Edit your profile" : `Edit ${editedUser.fullname} <${editedUser.email}>`
          }
          initialData={editedUser}
          showRoleInput={false}
          showCompanyContactInput={activeUser.role === USER_ROLE_BIZONE_ADMIN}
          showPasswordChangeButton={editedUser.id === activeUser.id || activeUser.role === USER_ROLE_BIZONE_ADMIN}
          showChangeCompaniesButton={activeUser.role === USER_ROLE_BIZONE_ADMIN}
          submitting={isSubmitting}
          allowToCancel
          onSubmit={handleSubmit}
          onCancel={() => dispatch(closeEditUserDialog())}
          onChangePassword={() => {
            dispatch(closeEditUserDialog());
            dispatch(openChangePasswordDialog(userId));
          }}
          onChangeCompanies={() => {
            dispatch(closeEditUserDialog());
            dispatch(openUserCompaniesDialog(userId));
          }}
        />
      </ModalDialog>,
      findPortal()
    )
  );
};
