import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/layout/Header";
import LoadingIndicator from "./components/misc/LoadingIndicator";
import LoggedInRoute from "./components/routes/LoggedInRoute";
import NotLoggedInRoute from "./components/routes/NotLoggedInRoute";
import AddCompanyAdminDialog from "./modules/administration/AddCompanyAdminDialog";
import AddCompanyDialog from "./modules/administration/AddCompanyDialog";
import AddUserDialog from "./modules/administration/AddUserDialog";
import CompaniesPage from "./modules/administration/AdministrationPage";
import SwitchUserCompaniesDialog from "./modules/administration/SwitchUserCompaniesDialog";
import ApiKeysPage from "./modules/api-keys/ApiKeysPage";
import AssetsPage from "./modules/assets/AssetsPage";
import CompanyPage from "./modules/company/CompanyPage";
import DashboardPage from "./modules/dashboard/DashboardPage";
import LoginPage from "./modules/login/LoginPage";
import FinishUserRegistrationPage from "./modules/registration/FinishUserRegistrationPage";
import DashboardSettingsPage from "./modules/settings/DashboardSettingsPage";
import ChangePasswordDialog from "./modules/shared/ChangePasswordDialog";
import EditUserDialog from "./modules/shared/EditUserDialog";
import SingleVulnerabilityPage from "./modules/vulnerabilities/SingleVulnerabilityPage";
import VulnerabilitiesPage from "./modules/vulnerabilities/VulnerabilitiesPage";
import PageNotFound from "./modules/errors/PageNotFound";
import { fetchActiveUserProfileRequest } from "./redux/auth/actions";
import { USER_ROLE_BIZONE_ADMIN } from "./utils/enums";
import useActiveUser from "./utils/useActiveUser";

export default () => {
  const isUserProfileLoading = useSelector(state => state.auth.pending);
  const userProfile = useActiveUser();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActiveUserProfileRequest());
  }, [dispatch]);

  if (isUserProfileLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Router>
      {userProfile && <Header />}

      <Switch>
        <NotLoggedInRoute path="/login" exact component={LoginPage} />
        <NotLoggedInRoute path="/register/:userId/:token" exact component={FinishUserRegistrationPage} />

        <LoggedInRoute path="/" exact component={DashboardPage} />
        <LoggedInRoute path="/assets/:status" exact component={AssetsPage} />
        <LoggedInRoute path="/vulnerabilities/:status" exact component={VulnerabilitiesPage} />
        <LoggedInRoute path="/vulnerabilities/view/:vulnerabilityId" exact component={SingleVulnerabilityPage} />
        <LoggedInRoute path="/companies/:companyId" component={CompanyPage} />
        <LoggedInRoute path="/api-keys" exact component={ApiKeysPage} />
        <LoggedInRoute path="/settings/dashboard" exact component={DashboardSettingsPage} />

        {userProfile && userProfile.role === USER_ROLE_BIZONE_ADMIN && (
          <Route path="/admin" exact component={CompaniesPage} />
        )}

        <Route path="/404" exact component={PageNotFound} />
        <Redirect to="/404" />
      </Switch>

      {/* @TODO: Show only the opened modal dialog. Store info about which dialog is open in redux store. */}
      {userProfile && (
        <React.Fragment>
          <AddCompanyDialog />
          <AddCompanyAdminDialog />
          <AddUserDialog />
          <EditUserDialog />
          <SwitchUserCompaniesDialog />
          <ChangePasswordDialog />
        </React.Fragment>
      )}
    </Router>
  );
};
