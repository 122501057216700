import React, { useCallback, useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { performUsersGet } from "../../api";
import { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import FormInput from "../../components/controls/Input";
import FormSelect from "../../components/controls/Select";
import FormField from "../../components/forms/FormVerticalField";
import LoadingIndicator from "../../components/misc/LoadingIndicator";
import ModalDialog from "../../components/modal/ModalDialog";
import { addCompanyRequest, closeAddCompanyDialog } from "../../redux/company-new/actions";
import { USER_ROLE_BIZONE_EMPLOYEE } from "../../utils/enums";
import { findPortal } from "../../utils/ui";
import useFocus from "../../utils/useFocus";
import { validate } from "../../utils/validation";
import { showErrorToast } from "../../components/Toast";

const validationScheme = yup.object().shape({
  name: yup.string().required("Please enter the company's name")
});

export default () => {
  const [isLoading, setLoading] = useState(true);
  const [bizoneEmployees, setBizoneEmployees] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const isOpen = useSelector(state => state.addCompany.companyStep.isDialogOpen);
  const isSubmitting = useSelector(state => state.addCompany.companyStep.isSubmitting);
  const companyCreationError = useSelector(state => state.addCompany.companyStep.error);

  const dispatch = useDispatch();

  const companyNameInput = useRef(null);
  const bizoneEmployeeInput = useRef(null);

  const loadBizoneEmployees = useCallback(async () => {
    try {
      const response = await performUsersGet({
        role: USER_ROLE_BIZONE_EMPLOYEE
      });
      setBizoneEmployees(response.data);

      setLoading(false);
    } catch (error) {
      showErrorToast(`Unable to load bizone employees: ${error.message}`);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      loadBizoneEmployees();
    }
  }, [isOpen, loadBizoneEmployees]);

  useEffect(() => {
    if (companyCreationError) {
      showErrorToast(`Unable to create company: ${companyCreationError.message}`);
    }
  }, [companyCreationError]);

  useFocus(companyNameInput, isOpen && bizoneEmployees.length > 0, [isOpen, bizoneEmployees]);

  const handleSubmit = async event => {
    event.preventDefault();

    const formData = {
      name: companyNameInput.current.value,
      bizoneEmployeeId: bizoneEmployeeInput.current.value
    };

    try {
      await validate(validationScheme, formData);
    } catch (errors) {
      setValidationErrors(errors);
      return;
    }

    dispatch(addCompanyRequest(formData));
  };

  return (
    isOpen &&
    ReactDOM.createPortal(
      <form onSubmit={handleSubmit}>
        <ModalDialog>
          <ContentBlockTitle>Add company</ContentBlockTitle>

          {isLoading && (
            <ContentSection>
              <LoadingIndicator />
            </ContentSection>
          )}

          {!isLoading && bizoneEmployees.length === 0 && (
            <React.Fragment>
              <ContentSection>
                <p>
                  There are no bizone employees.
                  <br />
                  <br />
                  First you must create at least one bizone employee to assign to the new company.
                </p>
              </ContentSection>
              <ContentSection>
                <ButtonsRow>
                  <Button onClick={() => dispatch(closeAddCompanyDialog())} type="button">
                    Cancel
                  </Button>
                </ButtonsRow>
              </ContentSection>
            </React.Fragment>
          )}

          {!isLoading && bizoneEmployees.length > 0 && (
            <React.Fragment>
              <ContentSection>
                <FormField
                  label="Name"
                  input={<FormInput ref={companyNameInput} type="text" />}
                  error={validationErrors.name}
                />

                <FormField
                  label="Bi.Zone employee"
                  input={
                    <FormSelect ref={bizoneEmployeeInput}>
                      {bizoneEmployees.map((employee, index) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.fullname}
                        </option>
                      ))}
                    </FormSelect>
                  }
                />
              </ContentSection>

              <ContentSection>
                <ButtonsRow>
                  <Button as="input" accented={true} type="submit" value="Submit" disabled={isSubmitting} />
                  <Button onClick={() => dispatch(closeAddCompanyDialog())} type="reset">
                    Cancel
                  </Button>
                </ButtonsRow>
              </ContentSection>
            </React.Fragment>
          )}
        </ModalDialog>
      </form>,
      findPortal()
    )
  );
};
