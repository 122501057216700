import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import ToastCloseIconPath from "../assets/images/toast-close.svg";
import ToastSuccessIconPath from "../assets/images/toast-success.svg";
import ToastWarningIconPath from "../assets/images/toast-warning.svg";
import ToastErrorIconPath from "../assets/images/toast-error.svg";

const ToastCloseButtonInner = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-items: center;
`;

export const ToastCloseButton = () => {
  return (
    <ToastCloseButtonInner>
      <img src={ToastCloseIconPath} alt="" />
    </ToastCloseButtonInner>
  );
};

const ToastBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const ToastIcon = styled.img`
  margin-right: 8px;
`;

const ToastText = styled.span`
  margin-top: 3px;
`;

const showToast = (toastFunc, text, iconPath) => {
  toastFunc(
    <ToastBody>
      <ToastIcon src={iconPath} alt="" />
      <ToastText>{text}</ToastText>
    </ToastBody>
  );
};

export const showSuccessToast = text => {
  showToast(toast.success, text, ToastSuccessIconPath);
};

export const showWarningToast = text => {
  showToast(toast.warn, text, ToastWarningIconPath);
};

export const showErrorToast = text => {
  showToast(toast.error, text, ToastErrorIconPath);
};
