import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingContainer from "../../components/containers/LoadingContainer";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import { DeleteButton } from "../../components/controls/IconButtons";
import ContentBlock, { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import Table, { DeleteActionTableCell, TableCell } from "../../components/Table";
import { openAddUserDialog } from "../../redux/user-add/actions";
import { deleteUserRequest, loadAllUsersRequest } from "../../redux/user-list/actions";
import { openEditUserDialog } from "../../redux/user-edit/actions";
import { userRoleToString } from "../../utils/enums";

export default () => {
  const usersById = useSelector(state => state.entities.usersById);
  const userIds = useSelector(state => state.usersList.userIds);
  const loading = useSelector(state => state.usersList.loading);
  const error = useSelector(state => state.usersList.error);

  const dispatch = useDispatch();

  const handleUserDeletion = useCallback(
    user => {
      if (window.confirm(`Are you sure you want to delete user "${user.fullname}"?`)) {
        dispatch(deleteUserRequest(user));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadAllUsersRequest());
  }, [dispatch]);

  return (
    <ContentBlock>
      <ContentBlockTitle>
        Users
        <ButtonsRow>
          <Button onClick={() => dispatch(openAddUserDialog())} accented={true} type="button">
            Add
          </Button>
        </ButtonsRow>
      </ContentBlockTitle>

      <ContentSection>
        <LoadingContainer
          loading={loading}
          hasError={!!error}
          renderError={() => <div>Got error {JSON.stringify(error)}</div>}
          renderData={() => (
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {userIds.length > 0 ? (
                  userIds.map(userId => {
                    const user = usersById[userId];

                    return (
                      <tr onClick={() => dispatch(openEditUserDialog(user.id))} key={user.id}>
                        <TableCell>{user.fullname}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{userRoleToString(user.role)}</TableCell>
                        <DeleteActionTableCell>
                          <DeleteButton
                            onClick={event => {
                              event.stopPropagation();
                              handleUserDeletion(user);
                            }}
                          />
                        </DeleteActionTableCell>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3">No users to show.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        />
      </ContentSection>
    </ContentBlock>
  );
};
