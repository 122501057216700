import { FETCH_ACTIVE_USER_PROFILE_SUCCESS } from "../auth/actions";
import {
  LOAD_WIDGET_TYPES_FAILURE,
  LOAD_WIDGET_TYPES_REQUEST,
  LOAD_WIDGET_TYPES_SUCCESS,
  SAVE_ENABLED_WIDGETS_FAILURE,
  SAVE_ENABLED_WIDGETS_REQUEST,
  SAVE_ENABLED_WIDGETS_SUCCESS
} from "./actions";

const initialState = {
  widgetTypes: {
    loading: true,
    allWidgetTypes: null,
    enabledWidgets: null,
    error: null
  },
  widgetSettings: {
    saving: false,
    enabledWidgets: null,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        widgetSettings: {
          ...state.widgetSettings,
          enabledWidgets: action.userProfile ? action.userProfile.widgets : null
        }
      };
    }

    case LOAD_WIDGET_TYPES_REQUEST: {
      return {
        ...state,
        widgetTypes: {
          ...state.widgetTypes,
          loading: true,
          allWidgetTypes: null,
          error: null
        }
      };
    }

    case LOAD_WIDGET_TYPES_FAILURE: {
      return {
        ...state,
        widgetTypes: {
          ...state.widgetTypes,
          loading: false,
          allWidgetTypes: null,
          error: action.error
        }
      };
    }

    case LOAD_WIDGET_TYPES_SUCCESS: {
      return {
        ...state,
        widgetTypes: {
          ...state.widgetTypes,
          loading: false,
          allWidgetTypes: action.widgetTypes,
          error: null
        }
      };
    }

    case SAVE_ENABLED_WIDGETS_REQUEST:
      return {
        ...state,
        widgetSettings: {
          ...state.widgetSettings,
          saving: true,
          error: null
        }
      };

    case SAVE_ENABLED_WIDGETS_FAILURE:
      return {
        ...state,
        widgetSettings: {
          ...state.widgetSettings,
          saving: false,
          error: action.error
        }
      };

    case SAVE_ENABLED_WIDGETS_SUCCESS:
      return {
        ...state,
        widgetSettings: {
          ...state.widgetSettings,
          saving: false,
          error: null,
          enabledWidgets: action.widgetTypes.map(widgetType => widgetType.name)
        }
      };

    default: {
      return state;
    }
  }
};
