export const FETCH_ACTIVE_USER_PROFILE_REQUEST = "FETCH_ACTIVE_USER_PROFILE_REQUEST";
export const FETCH_ACTIVE_USER_PROFILE_SUCCESS = "FETCH_ACTIVE_USER_PROFILE_SUCCESS";
export const FETCH_ACTIVE_USER_PROFILE_FAILURE = "FETCH_ACTIVE_USER_PROFILE_FAILURE";

export const CLEAR_ACTIVE_USER_PROFILE = "CLEAR_ACTIVE_USER_PROFILE";

export const fetchActiveUserProfileRequest = () => ({ type: FETCH_ACTIVE_USER_PROFILE_REQUEST });

export const fetchActiveUserProfileSuccess = (userProfile) => ({ type: FETCH_ACTIVE_USER_PROFILE_SUCCESS, userProfile });

export const fetchActiveUserProfileFailure = error => ({ type: FETCH_ACTIVE_USER_PROFILE_FAILURE, error });

export const clearActiveUserProfile = () => ({ type: CLEAR_ACTIVE_USER_PROFILE });
