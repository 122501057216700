import React from "react";
import styled from "styled-components/macro";
import radioButtonMarkedSvgPath from "../../assets/images/radio-button-selected.svg";
import radioButtonSvgPath from "../../assets/images/radio-button.svg";

const RadioButtonHiddenInput = styled.input.attrs({ type: "radio" })`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  width: 30px;
  height: 30px;
`;

const RadioButtonShape = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 3px;
  top: 3px;
  opacity: 0.4;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
`;

const RadioButtonSelectedShape = styled.img`
  opacity: 0;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 2;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;
`;

const RadioButtonContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${RadioButtonHiddenInput}:checked:disabled ~ ${RadioButtonSelectedShape} {
    opacity: 0.4;
  }

  ${RadioButtonHiddenInput}:hover ~ ${RadioButtonSelectedShape} {
    filter: brightness(0.9);
  }

  ${RadioButtonHiddenInput}:checked ~ ${RadioButtonSelectedShape} {
    opacity: 1;
  }

  ${RadioButtonHiddenInput}:disabled ~ ${RadioButtonShape} {
    opacity: 0.16;
  }

  ${RadioButtonHiddenInput}:hover ~ ${RadioButtonShape} {
    opacity: 0.6;
  }

  ${RadioButtonHiddenInput}:checked ~ ${RadioButtonShape} {
    visibility: hidden;
  }
`;

export default (props) => {
  return (
    <RadioButtonContainer>
      <RadioButtonHiddenInput {...props} />
      <RadioButtonSelectedShape src={radioButtonMarkedSvgPath} />
      <RadioButtonShape src={radioButtonSvgPath} />
    </RadioButtonContainer>
  );
};
