import * as Observable from "rxjs";
import { catchError, map, mergeAll, switchMap, tap } from "rxjs/operators";
import { ajaxPostUserPassword } from "../../api";
import { showErrorToast, showSuccessToast } from "../../components/Toast";
import {
  CHANGE_PASSWORD_REQUEST,
  changePasswordFailure,
  changePasswordSuccess,
  closeChangePasswordDialog
} from "./actions";

export const changePasswordEpic = action$ =>
  action$.ofType(CHANGE_PASSWORD_REQUEST).pipe(
    switchMap(action =>
      ajaxPostUserPassword(action.userId, action.passwordData).pipe(
        map(result => result.response.data),
        tap(() => showSuccessToast(`Password is successfully changed`)),
        map(user => [closeChangePasswordDialog(), changePasswordSuccess(user)]),
        mergeAll(),
        catchError(error => {
          showErrorToast(`Unable to change user's password. ${error.response.message}`);
          return Observable.of(changePasswordFailure(error.response));
        })
      )
    )
  );
