import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { loadAssetsOrSnapshotsPageEpic } from "./assets-list/epics";
import assetsListReducer from "./assets-list/reducer";
import { fetchActiveUserProfileEpic } from "./auth/epics";
import authReducer from "./auth/reducer";
import { changePasswordEpic } from "./change-password/epics";
import changePasswordReducer from "./change-password/reducer";
import { deleteCompanyEpic, loadAllCompaniesEpic } from "./company-list/epics";
import companiesListReducer from "./company-list/reducer";
import { addCompanyAdminEpic, addCompanyEpic } from "./company-new/epics";
import addCompanyReducer from "./company-new/reducer";
import companySwitcherReducer from "./company-switcher/reducer";
import { loadCompanyEpic, loadCompanyUsersEpic } from "./company-view/epics";
import companyViewReducer from "./company-view/reducer";
import entitiesReducer from "./entities/reducer";
import { loadWidgetTypesEpic, saveEnabledWidgetsEpic } from "./settings/epics";
import settingsReducer from "./settings/reducer";
import { addUserEpic } from "./user-add/epics";
import addUserReducer from "./user-add/reducer";
import {changeUserCompaniesEpic} from "./user-change-companies/epics";
import { updateUserEpic } from "./user-edit/epic";
import editUserReducer from "./user-edit/reducer";
import { deleteUserEpic, loadAllUsersEpic } from "./user-list/epics";
import usersListReducer from "./user-list/reducer";
import changeUserCompaniesReducer from "./user-change-companies/reducer";

export const rootEpic = combineEpics(
  fetchActiveUserProfileEpic,
  loadAllCompaniesEpic,
  addCompanyEpic,
  addCompanyAdminEpic,
  deleteCompanyEpic,
  loadAllUsersEpic,
  addUserEpic,
  updateUserEpic,
  deleteUserEpic,
  changePasswordEpic,
  changeUserCompaniesEpic,
  loadCompanyEpic,
  loadCompanyUsersEpic,
  loadWidgetTypesEpic,
  saveEnabledWidgetsEpic,
  loadAssetsOrSnapshotsPageEpic
);

const rootReducer = combineReducers({
  auth: authReducer,
  companySwitcher: companySwitcherReducer,
  settings: settingsReducer,
  companyView: companyViewReducer,
  addUser: addUserReducer,
  editUser: editUserReducer,
  changePassword: changePasswordReducer,
  changeUserCompanies: changeUserCompaniesReducer,
  assetsList: assetsListReducer,
  companiesList: companiesListReducer,
  addCompany: addCompanyReducer,
  usersList: usersListReducer,
  entities: entitiesReducer
});

const epicMiddleware = createEpicMiddleware();

const makeStore = initialState => {
  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(epicMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
  );
};

const store = makeStore({});
epicMiddleware.run(rootEpic);

export default store;
