import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { performEventsGet } from "../../../api";
import CenteredVertically from "../../../components/containers/CenteredVertically";
import ContentBlock, { ContentSection, ContentBlockTitle } from "../../../components/containers/ContentBlock";
import LoadingIndicator from "../../../components/misc/LoadingIndicator";
import Table, { TableCell } from "../../../components/Table";
import {
  EVENT_TYPE_CHANGE_VULNERABILITY_STATUS,
  EVENT_TYPE_CREATE_VULNERABILITY,
  EVENT_TYPE_CREATE_VULNERABILITY_COMMENT
} from "../../../utils/enums";
import { formatDate } from "../../../utils/ui";

export default withRouter(({ companyId, history }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [events, setEvents] = useState(undefined);

  const loadEvents = useCallback(async () => {
    setLoading(true);

    try {
      const response = await performEventsGet(companyId);
      const events = response.data;

      setEvents(events);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  const goToEvent = useCallback(
    event => {
      switch (event.type) {
        case EVENT_TYPE_CREATE_VULNERABILITY_COMMENT:
          history.push(`/vulnerabilities/view/${event.entityId}`);
          break;
        case EVENT_TYPE_CREATE_VULNERABILITY:
          history.push(`/vulnerabilities/view/${event.entityId}`);
          break;
        case EVENT_TYPE_CHANGE_VULNERABILITY_STATUS:
          history.push(`/vulnerabilities/view/${event.entityId}`);
          break;
        default:
          break;
      }
    },
    [history]
  );

  return (
    <ContentBlock>
      <ContentBlockTitle>Feed</ContentBlockTitle>

      <ContentSection flex={"1 0"}>
        <CenteredVertically expand>
          {loading && <LoadingIndicator />}

          {loading && error && <React.Fragment>{JSON.stringify(error)}</React.Fragment>}

          {!loading && !error && (
            <React.Fragment>
              {events.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Event</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map(event => (
                      <tr onClick={() => goToEvent(event)} key={event.id}>
                        <TableCell>{event.author.fullname}</TableCell>
                        <TableCell>{event.description}</TableCell>
                        <TableCell>{formatDate(event.createdAt)}</TableCell>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div>Nothing to show</div>
              )}
            </React.Fragment>
          )}
        </CenteredVertically>
      </ContentSection>
    </ContentBlock>
  );
});
