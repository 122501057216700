import { arrayCopyWithoutElement } from "../../utils/collections";
import { DELETE_COMPANY_SUCCESS } from "../company-list/actions";
import { DELETE_USER_SUCCESS } from "../user-list/actions";
import { FETCH_COMPANY_USERS_SUCCESS, TOGGLE_USER_ACTIVATION_SUCCESS } from "../company-view/actions";
import { STORE_COMPANIES, STORE_USERS } from "./actions";

const initialState = {
  usersById: {},
  companiesById: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_USERS: {
      const usersById = { ...state.usersById };
      const companiesById = { ...state.companiesById };

      action.users.forEach(user => {
        const cachedUser = usersById[user.id];

        if (cachedUser) {
          const cachedCompanyIds = cachedUser.companyIds || [];
          const newCompanyIds = user.companyIds || [];

          cachedCompanyIds.forEach(companyId => {
            const cachedCompany = companiesById[companyId];
            if (cachedCompany) {
              cachedCompany.userIds = arrayCopyWithoutElement(cachedCompany.userIds, user.id);
            }
          });

          newCompanyIds.forEach(companyId => {
            const cachedCompany = companiesById[companyId];
            if (cachedCompany) {
              cachedCompany.userIds = [...cachedCompany.userIds, user.id];
            }
          });
        }

        usersById[user.id] = user;
      });

      return { ...state, usersById };
    }

    case DELETE_USER_SUCCESS: {
      const usersById = { ...state.usersById };
      const companiesById = { ...state.companiesById };

      const cachedUser = usersById[action.userId];
      cachedUser.companyIds.forEach(companyId => {
        const cachedCompany = companiesById[companyId];
        cachedCompany.userIds = arrayCopyWithoutElement(cachedCompany.userIds, cachedUser.id);
      });

      usersById[action.userId] = undefined;
      return { ...state, companiesById, usersById };
    }

    case STORE_COMPANIES: {
      const companiesById = { ...state.companiesById };

      action.companies.forEach(company => {
        const cachedCompany = companiesById[company.id];

        company.userIds = cachedCompany ? cachedCompany.userIds : [];
        companiesById[company.id] = company;
      });

      return { ...state, companiesById };
    }

    case DELETE_COMPANY_SUCCESS: {
      const usersById = { ...state.usersById };
      const companiesById = { ...state.companiesById };

      const cachedCompany = companiesById[action.companyId];
      if (cachedCompany && cachedCompany.userIds) {
        cachedCompany.userIds.forEach(id => (usersById[id] = undefined));
      }

      companiesById[action.companyId] = undefined;

      return { ...state, usersById, companiesById };
    }

    case FETCH_COMPANY_USERS_SUCCESS: {
      const usersById = { ...state.usersById };
      const companiesById = { ...state.companiesById };

      action.users.forEach(user => {
        const cachedCompany = companiesById[action.companyId];
        if (!cachedCompany.userIds) {
          cachedCompany.userIds = [];
        }

        if (!cachedCompany.userIds.includes(user.id)) {
          cachedCompany.userIds = [...cachedCompany.userIds, user.id];
        }
      });

      return {
        usersById,
        companiesById
      };
    }

    case TOGGLE_USER_ACTIVATION_SUCCESS: {
      const usersById = { ...state.usersById };
      usersById[action.userId].active = action.active;

      return {
        ...state,
        usersById
      };
    }

    default: {
      return state;
    }
  }
};
