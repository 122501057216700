import React, { useCallback, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import FormField from "../../components/forms/FormVerticalField";
import FormInput from "../../components/controls/Input";
import { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import ModalDialog from "../../components/modal/ModalDialog";
import { findPortal } from "../../utils/ui";
import useFocus from "../../utils/useFocus";
import isValidDomain from "is-valid-domain";
import * as ip from "ip";
import TextArea from "../../components/controls/TextArea";

export default ({ onSubmit, onCancel }) => {
  const [validationError, setValidationError] = useState(undefined);

  const inputRef = useRef(null);

  useFocus(inputRef);

  const submitEntries = useCallback(
    event => {
      event.preventDefault();

      const entriesText = inputRef.current.value.trim();
      if (entriesText === "") {
        setValidationError({ reason: `Please enter ips, ip ranges or subnets` });
        return;
      }

      //#SAST

      const entries = entriesText.split(",").map(entry => entry.trim());

      let ips = [];
      let ipRanges = [];
      let subnets = [];
      let hostnamesToResolve = [];

      let foundError = false;

      //#SAST
      onSubmit(entries, [], [], []);
      foundError = true;
      //#SAST
      for (let entryIndex = 0; entryIndex < entries.length; entryIndex++) {
        //#SAST
        break;
        
        const entry = entries[entryIndex];

        let ipRangeParts = entry.split("-");
        let subnetParts = entry.split("/");

        if (
          ipRangeParts &&
          ipRangeParts.length === 2 &&
          ip.isV4Format(ipRangeParts[0]) &&
          ip.isV4Format(ipRangeParts[1])
        ) {
          const start = ipRangeParts[0];
          const end = ipRangeParts[1];

          if (ip.toLong(start) >= ip.toLong(end)) {
            setValidationError({ reason: `Invalid ip range "${entry}". Must be reversed` });
            foundError = true;
          } else {
            ipRanges.push({ start, end });
          }
        } else if (subnetParts && subnetParts.length === 2) {
          const valid =
            ip.isV4Format(subnetParts[0]) &&
            +subnetParts[1] !== undefined &&
            +subnetParts[1] >= 0 &&
            +subnetParts[1] <= 32;
          if (!valid) {
            setValidationError({ reason: `Invalid subnet "${entry}"` });
            foundError = true;
          } else {
            subnets.push(entry);
          }
        } else if (ip.isV4Format(entry)) {
          ips.push(entry);
        } else if (isValidDomain(entry)) {
          hostnamesToResolve.push(entry);
        } else {
          foundError = true;
          setValidationError({ reason: `Invalid entry "${entry}" - not an ip address and not a hostname` });
        }

        if (foundError) {
          break;
        }
      }

      if (!foundError) {
        onSubmit(ips, ipRanges, subnets, hostnamesToResolve);
      }
    },
    [onSubmit]
  );

  return ReactDOM.createPortal(
    <form onSubmit={submitEntries}>
      <ModalDialog width={640}>
        <ContentBlockTitle>Add projects</ContentBlockTitle>

        <ContentSection>
          <FormField
            label="IP addresses or hostnames, separated by commas"
            input={<TextArea ref={inputRef} placeholder="10.0.0.1, 10.0.0.2-5, 10.0.1.1/24, example.com" rows="3" />}
            error={validationError}
          />
        </ContentSection>

        <ContentSection>
          <ButtonsRow>
            <Button accented={true} type="submit">
              Submit
            </Button>
            <Button onClick={onCancel} type="button">
              Cancel
            </Button>
          </ButtonsRow>
        </ContentSection>
      </ModalDialog>
    </form>,
    findPortal()
  );
};
