import React from "react";
import styled from "styled-components/macro";

const FactoidDeltaText = styled.span`
  font-size: 24px;
  line-height: 1.5;
  margin-left: 6px;
`;

export default ({ delta }) => {
  const deltaString = delta >= 0 ? "+" + delta : "-" + delta;

  let textColor = "#999999";
  if (delta > 0) {
    textColor = "#4bb04f";
  } else if (delta < 0) {
    textColor = "#df4a3f";
  }

  return <FactoidDeltaText style={{ color: textColor }}>{deltaString}</FactoidDeltaText>;
};
