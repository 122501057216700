import { toast } from "react-toastify/index";
import * as Observable from "rxjs/index";
import { catchError, map, mergeAll, switchMap, tap } from "rxjs/operators/index";
import { ajaxPostCompany, ajaxPostUser } from "../../api";
import { storeCompanies, storeUsers } from "../entities/actions";
import {
  ADD_COMPANY_ADMIN_REQUEST,
  ADD_COMPANY_REQUEST,
  addCompanyAdminFailure,
  addCompanyAdminSuccess,
  addCompanyFailure,
  addCompanySuccess
} from "./actions";

export const addCompanyEpic = action$ =>
  action$.ofType(ADD_COMPANY_REQUEST).pipe(
    switchMap(action =>
      ajaxPostCompany(action.companyData).pipe(
        map(result => result.response.data),
        tap(company => toast.success(`Company ${company.name} was successfully created`)),
        map(company => [storeCompanies([company]), addCompanySuccess(company)]),
        mergeAll(),
        catchError(error => {
          toast.error(`Unable to create the company. ${error.message}`);
          return Observable.of(addCompanyFailure(error.response));
        })
      )
    )
  );

export const addCompanyAdminEpic = action$ =>
  action$.ofType(ADD_COMPANY_ADMIN_REQUEST).pipe(
    switchMap(action =>
      ajaxPostUser(action.userData).pipe(
        map(result => result.response.data),
        map(user => [storeUsers([user]), addCompanyAdminSuccess(user)]),
        mergeAll(),
        catchError(error => {
          return Observable.of(addCompanyAdminFailure(error.response));
        })
      )
    )
  );
