import { arrayCopyWithoutElement } from "../../utils/collections";
import { ADD_COMPANY_SUCCESS } from "../company-new/actions";
import {
  DELETE_COMPANY_SUCCESS,
  FETCH_ALL_COMPANIES_FAILURE,
  FETCH_ALL_COMPANIES_REQUEST,
  FETCH_ALL_COMPANIES_SUCCESS
} from "./actions";

const initialState = {
  loading: true,
  companyIds: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_COMPANIES_REQUEST: {
      return {
        ...state,
        loading: true,
        companyIds: null,
        error: null
      };
    }

    case FETCH_ALL_COMPANIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyIds: action.companies.map(company => company.id),
        error: null
      };
    }

    case FETCH_ALL_COMPANIES_FAILURE: {
      return {
        ...state,
        loading: false,
        companyIds: null,
        error: action.error
      };
    }

    case ADD_COMPANY_SUCCESS: {
      return {
        ...state,
        companyIds: [action.company.id, ...state.companyIds]
      };
    }

    case DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        companyIds: arrayCopyWithoutElement(state.companyIds, action.companyId)
      };
    }

    default: {
      return state;
    }
  }
};
