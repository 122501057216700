import React from "react";
import styled from "styled-components/macro";
import DeleteSvgPath from "../../assets/images/delete.svg";
import EditSvgPath from "../../assets/images/edit.svg";

const Icon = styled.img`
  height: 18px;
  width: auto;
  display: inline-block;
  object-fit: contain;
`;

const IconHitbox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.5;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const DeleteButton = ({ onClick }) => {
  return (
    <IconHitbox onClick={onClick}>
      <Icon src={DeleteSvgPath} alt="Delete" />
    </IconHitbox>
  );
};

export const EditButton = ({ onClick }) => {
  return (
    <IconHitbox onClick={onClick}>
      <Icon src={EditSvgPath} alt="Edit" />
    </IconHitbox>
  );
};
