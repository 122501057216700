import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import bizoneLogo from "../../assets/images/bizone-logo.svg";

const BiZoneLogoImage = styled.img`
  width: 48px;
  height: auto;
  object-fit: contain;
`;

export default () => {
  return (
    <Link to="/">
      <BiZoneLogoImage src={bizoneLogo} alt="Bi.Zone" />
    </Link>
  );
};
