import styled from "styled-components/macro";

export default styled.span`
  tr & {
    opacity: 0;
  }

  tr:hover & {
    opacity: 1;
  }
`;
