import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ContentBlockTitle, ContentSection } from "../../components/containers/ContentBlock";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import Checkbox from "../../components/controls/Checkbox";
import FormInput from "../../components/controls/Input";
import FormPhoneNumberInput from "../../components/controls/PhoneNumberInput";
import FormSelect from "../../components/controls/Select";
import FormHorizontalField from "../../components/forms/FormHorizontalField";
import FormField from "../../components/forms/FormVerticalField";
import { fetchAllCompaniesRequest } from "../../redux/company-list/actions";
import {
  allUserRoles,
  USER_ROLE_BIZONE_ADMIN,
  USER_ROLE_BIZONE_EMPLOYEE,
  USER_ROLE_COMPANY_ADMIN,
  USER_ROLE_COMPANY_EMPLOYEE,
  USER_ROLE_COMPANY_MANAGER,
  userRoleToString
} from "../../utils/enums";
import useFocus from "../../utils/useFocus";
import { isValidPhoneNumber, preprocessPhoneNumber, validate } from "../../utils/validation";

const createValidationScheme = (emailFieldRequired, companyFieldRequired) => {
  const shape = {
    fullname: yup.string().required("Please enter user's full name"),
    phoneNumber: yup
      .string()
      .required("Please enter user's phone number")
      .test("is-phone-number", "Please enter phone number in format +X (XXX) XXX-XX-XX", value =>
        isValidPhoneNumber(value)
      )
  };

  if (emailFieldRequired) {
    shape.email = yup
      .string()
      .email("Invalid email format")
      .required("Please enter the user's email");
  }

  if (companyFieldRequired) {
    shape.companyId = yup
      .number("Please select user's company")
      .required("Please select user's company")
      .min(0, "Please select user's company");
  }

  return yup.object().shape(shape);
};

export default ({
  title,
  initialData,
  showRoleInput,
  showEmailInput,
  showCompanySelect,
  showCompanyContactInput,
  showPasswordChangeButton,
  showChangeCompaniesButton,
  submitting,
  allowToCancel,
  onSubmit,
  onCancel,
  onChangePassword,
  onChangeCompanies
}) => {
  initialData = initialData || {};

  const [selectedRole, setSelectedRole] = useState(initialData.role || USER_ROLE_BIZONE_ADMIN);
  const [validationErrors, setValidationErrors] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(initialData.phoneNumber || "");
  const [companyContact, setCompanyContact] = useState(initialData.companyContact || false);

  const fullNameInput = useRef(null);
  const emailInput = useRef(null);
  const companySelect = useRef(null);

  const companiesById = useSelector(state => state.entities.companiesById);

  const dispatch = useDispatch();

  const companySelectEnabled =
    showRoleInput &&
    showCompanySelect &&
    (selectedRole === USER_ROLE_COMPANY_ADMIN ||
      selectedRole === USER_ROLE_COMPANY_MANAGER ||
      selectedRole === USER_ROLE_COMPANY_EMPLOYEE);

  const handleSubmit = async event => {
    event.preventDefault();

    const formData = {
      fullname: fullNameInput.current.value,
      email: showEmailInput ? emailInput.current.value : undefined,
      role: showRoleInput ? selectedRole : undefined,
      companyId: companySelectEnabled ? +companySelect.current.value : undefined,
      companyContact: showCompanyContactInput ? companyContact : undefined,
      phoneNumber: preprocessPhoneNumber(phoneNumber)
    };

    try {
      const validationScheme = createValidationScheme(showEmailInput, companySelectEnabled);

      await validate(validationScheme, formData);
    } catch (errors) {
      setValidationErrors(errors);
      return;
    }

    onSubmit(formData);
  };

  useEffect(() => {
    dispatch(fetchAllCompaniesRequest());
  }, [dispatch]);

  useFocus(fullNameInput);

  return (
    <form onSubmit={handleSubmit}>
      <ContentBlockTitle>{title}</ContentBlockTitle>

      <ContentSection>
        <FormField
          label="Full Name"
          input={<FormInput ref={fullNameInput} type="text" defaultValue={initialData.fullname} />}
          error={validationErrors.fullname}
        />

        {showEmailInput && (
          <FormField
            label="Email"
            input={<FormInput ref={emailInput} type="text" defaultValue={initialData.email} />}
            error={validationErrors.email}
          />
        )}

        {showRoleInput && (
          <FormField
            label="Role"
            input={
              <FormSelect value={selectedRole} onChange={event => setSelectedRole(event.target.value)}>
                {allUserRoles.map((role, index) => (
                  <option key={index} value={role}>
                    {userRoleToString(role)}
                  </option>
                ))}
              </FormSelect>
            }
          />
        )}

        {companySelectEnabled && (
          <FormField
            label="Company"
            input={
              <FormSelect ref={companySelect} defaultValue={"-1"}>
                <option value={-1}>Not selected</option>
                {companiesById &&
                  Object.keys(companiesById).map(companyId => {
                    const company = companiesById[companyId];
                    return (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </FormSelect>
            }
            error={validationErrors.companyId}
          />
        )}

        <FormField
          label="Phone Number"
          input={
            <FormPhoneNumberInput
              onAccept={value => setPhoneNumber(value)}
              unmask={false}
              mask={"+{0} (000) 000-00-00"}
              lazy={false}
              value={phoneNumber}
            />
          }
          error={validationErrors.phoneNumber}
        />

        {showCompanyContactInput && (
          <FormHorizontalField
            label="Company contact"
            input={<Checkbox checked={companyContact} onChange={() => setCompanyContact(!companyContact)} />}
            error={validationErrors.companyContact}
          />
        )}
      </ContentSection>

      <ContentSection>
        <ButtonsRow>
          {showPasswordChangeButton && (
            <Button onClick={onChangePassword} type="button">
              Password
            </Button>
          )}

          {showChangeCompaniesButton && initialData.role && initialData.role === USER_ROLE_BIZONE_EMPLOYEE && (
            <Button onClick={onChangeCompanies} type="button">
              Companies
            </Button>
          )}

          <Button accented={true} type="submit" disabled={submitting}>
            Submit
          </Button>

          {allowToCancel && (
            <Button onClick={onCancel} type="button">
              Cancel
            </Button>
          )}
        </ButtonsRow>
      </ContentSection>
    </form>
  );
};
