import React from "react";
import { Redirect, Route } from "react-router-dom";
import useActiveUser from "../../utils/useActiveUser";

export default ({ component: Component, ...props }) => {
  const activeUser = useActiveUser();

  return (
    <Route {...props} render={props => (!activeUser ? <Component {...props} /> : <Redirect to="/" />)} />
  );
};
