import React from "react";
import styled from "styled-components/macro";
import Centered from "./Content";

const TopActionBarShape = styled.div`
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
`;

const TopActionBarInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
`;

export const TopActionBarLeft = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TopActionBarRight = styled.div`
  margin-left: auto;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TopActionBarButtonsContainer = styled.div`
  margin: 7px 0;
`;

export default ({ children }) => {
  return (
    <TopActionBarShape>
      <Centered>
        <TopActionBarInner>{children}</TopActionBarInner>
      </Centered>
    </TopActionBarShape>
  );
};
