import React, { useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { ContentBlockTitle, ContentSection } from "../../components/containers/ContentBlock";
import TwoColumnLayout, { TwoColumnLayoutColumn } from "../../components/containers/TwoColumnLayout";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import RadioButton from "../../components/controls/RadioButton";
import FormTextArea from "../../components/controls/TextArea";
import FormField from "../../components/forms/FormVerticalField";
import ModalDialog from "../../components/modal/ModalDialog";
import { findPortal } from "../../utils/ui";
import { validate } from "../../utils/validation";

const validationScheme = yup.object().shape({
  comment: yup
    .string()
    .min(1)
    .required("Please enter the comment")
});

const RadioButtonWithLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    margin-right: 8px;
  }
`;

const CommentFieldContainer = styled.div`
  margin-top: 30px;
`;

export default ({ onApproveOrCancel, onClose }) => {
  const [approveSelected, setApproveSelected] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});

  const commentInput = useRef(null);

  const handleSubmit = async event => {
    event.preventDefault();

    const comment = commentInput.current.value;

    try {
      await validate(validationScheme, { comment });
    } catch (errors) {
      setValidationErrors(errors);
      return;
    }

    onApproveOrCancel(approveSelected, comment);
  };

  return ReactDOM.createPortal(
    <form onSubmit={handleSubmit}>
      <ModalDialog>
        <ContentBlockTitle>Approve or cancel vulnerability resolution</ContentBlockTitle>

        <ContentSection>
          <TwoColumnLayout onChange={event => setApproveSelected(event.target.value === "approve")}>
            <TwoColumnLayoutColumn>
              <RadioButtonWithLabelContainer>
                <RadioButton value="approve" name="choice" defaultChecked={true} /> Approve
              </RadioButtonWithLabelContainer>
            </TwoColumnLayoutColumn>
            <TwoColumnLayoutColumn>
              <RadioButtonWithLabelContainer>
                <RadioButton value="cancel" name="choice" /> Decline
              </RadioButtonWithLabelContainer>
            </TwoColumnLayoutColumn>
          </TwoColumnLayout>

          <CommentFieldContainer>
            <FormField
              label="Comment"
              input={<FormTextArea ref={commentInput} rows={5} />}
              error={validationErrors.comment}
            />
          </CommentFieldContainer>
        </ContentSection>

        <ContentSection>
          <ButtonsRow>
            <Button accented={true} type="submit">
              Submit
            </Button>
            <Button onClick={onClose} type="button">
              Cancel
            </Button>
          </ButtonsRow>
        </ContentSection>
      </ModalDialog>
    </form>,
    findPortal()
  );
};
