import React, { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { postUserConfirmation } from "../../api";
import Centered from "../../components/containers/Centered";
import ContentBlock, { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import FormInput from "../../components/controls/Input";
import FormField from "../../components/forms/FormVerticalField";
import Main from "../../components/layout/Main";
import { pageTitle } from "../../utils/ui";
import useFocus from "../../utils/useFocus";
import { validate } from "../../utils/validation";

const validationScheme = yup.object().shape({
  password: yup
    .string()
    .min(10)
    .required("Please enter you password"),
  confirmPassword: yup
    .string()
    .min(10)
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Please confirm your password")
});

const PasswordForm = styled.form`
  min-width: 464px;
`;

const FinishUserRegistrationPage = ({ match, history }) => {
  const userId = match.params.userId;
  const registrationToken = match.params.token;

  const [validationErrors, setValidationErrors] = useState({});

  const passwordInputRef = useRef(null);
  const confirmPasswordInputRef = useRef(null);

  useFocus(passwordInputRef);

  const submitForm = useCallback(
    async event => {
      event.preventDefault();

      const password = passwordInputRef.current.value;
      const confirmPassword = confirmPasswordInputRef.current.value;

      const formData = {
        password,
        registrationToken
      };

      try {
        await validate(validationScheme, { ...formData, confirmPassword });
      } catch (errors) {
        setValidationErrors(errors);
        return;
      }

      setValidationErrors({});

      try {
        await postUserConfirmation(userId, formData);
        history.replace("/login");
      } catch (error) {
        window.alert(JSON.stringify(error));
      }
    },
    [history, userId, registrationToken]
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle("Registration")}</title>
      </Helmet>

      <Main>
        <Centered>
          <ContentBlock>
            <PasswordForm onSubmit={submitForm}>
              <ContentBlockTitle>Create your password</ContentBlockTitle>

              <ContentSection>
                <FormField
                  label="Password"
                  input={<FormInput ref={passwordInputRef} type="password" />}
                  error={validationErrors.password}
                />
                <FormField
                  label="Confirm Password"
                  input={<FormInput ref={confirmPasswordInputRef} type="password" />}
                  error={validationErrors.confirmPassword}
                />
              </ContentSection>
              <ContentSection>
                <ButtonsRow>
                  <Button accented={true} type="submit">
                    Submit
                  </Button>
                </ButtonsRow>
              </ContentSection>
            </PasswordForm>
          </ContentBlock>
        </Centered>
      </Main>
    </React.Fragment>
  );
};

export default withRouter(FinishUserRegistrationPage);
