import distanceInWordsToNow from "date-fns/distance_in_words_to_now";

export const findPortal = () => {
  return document.getElementById("portal");
};

export const pageTitle = title => `${title} | BiZone`;

export const formatDate = datetimeString => {
  const datetime = new Date(datetimeString);
  return datetime.toLocaleDateString(undefined, { day: "numeric", month: "numeric", year: "numeric" });
};

export const formatDateTime = datetimeString => {
  const datetime = new Date(datetimeString);
  return datetime.toLocaleDateString(undefined, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
};

export const formatDateDeltaFromNow = datetime => {
  return distanceInWordsToNow(datetime, { includeSeconds: true }) + " ago";
};

export const fullAssetName = (asset, service) => {
  const hostnames = asset.hosts.map(host => host.name).join(", ");

  let result = `${asset.ip}`;
  if (hostnames !== "") {
    result += ` ${hostnames}`;
  }

  return result;
};

export const formatVulnerabilityServiceName = vulnerability => {
  return `${vulnerability.service.asset.ip}`;
};
