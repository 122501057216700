import { ADD_USER_FAILURE, ADD_USER_REQUEST, CLOSE_ADD_USER_DIALOG, OPEN_ADD_USER_DIALOG } from "./actions";

const initialState = {
  open: false,
  submitting: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_REQUEST: {
      return {
        ...state,
        submitting: true
      };
    }

    case ADD_USER_FAILURE: {
      return {
        ...state,
        open: true,
        submitting: false,
        error: action.error
      };
    }

    case OPEN_ADD_USER_DIALOG:
      return {
        ...state,
        open: true,
        submitting: false,
        error: null
      };

    case CLOSE_ADD_USER_DIALOG:
      return {
        ...state,
        open: false,
        submitting: false,
        error: null
      };

    default: {
      return state;
    }
  }
};
