import React from "react";
import ReactMarkdown from "react-markdown";
import { css } from "styled-components";
import styled from "styled-components/macro";
import { ContentSection } from "../../components/containers/ContentBlock";
import { DeleteButton } from "../../components/controls/IconButtons";
import { userRoleToString } from "../../utils/enums";
import { formatDateDeltaFromNow } from "../../utils/ui";

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const CommentAuthor = styled.div`
  font-size: 14px;
  position: relative;

  & div {
    display: none;
  }

  &:hover div {
    display: block;
  }

  ${({ deleted }) =>
    deleted
      ? css`
          color: #cc5356;
        `
      : css`
          color: #1696cc;
        `}
`;

const CommentAuthorTooltip = styled.div`
  position: absolute;
  top: 50%;
  font-size: 12px;
  transform: translateY(-50%);
  left: 100%;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #b3b3b3;
  background-color: #ffffff;
  padding: 8px 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: #4d4d4d;
  line-height: 1.5;
  min-width: 200px;
`;

const CommentEvent = styled.div`
  margin-left: 20px;
  font-size: 14px;
  color: #999999;
`;

const CommentDelete = styled.div`
  margin-left: auto;

  & {
    visibility: hidden;
  }

  *:hover > * > & {
    visibility: visible;
  }
`;

const CommentDate = styled.div`
  margin-left: 16px;
  font-size: 14px;
  color: #999999;
`;

const CommentBody = styled.div`
  margin-bottom: 16px;
`;

export default ({ comment, onDeleteClick }) => {
  let actionDescription;

  switch (comment.type) {
    case "resolution_approval": {
      actionDescription = "accepted resolution and posted a comment";
      break;
    }
    case "resolution_cancellation": {
      actionDescription = "declined resolution and posted a comment";
      break;
    }
    default: {
      actionDescription = "posted a comment"
    }
  }

  return (
    <ContentSection>
      <CommentHeader>
        <CommentAuthor deleted={!comment.author}>
          {comment.author ? (
            <React.Fragment>
              {comment.author.fullname}
              <CommentAuthorTooltip>
                {userRoleToString(comment.author.role)}
                <br />
                {comment.author.phoneNumber}
                <br />
                {comment.author.email}
              </CommentAuthorTooltip>
            </React.Fragment>
          ) : (
            "Deleted User"
          )}
        </CommentAuthor>
        <CommentEvent>{actionDescription}</CommentEvent>
        <CommentDelete>
          <DeleteButton onClick={onDeleteClick} />
        </CommentDelete>
        <CommentDate>{formatDateDeltaFromNow(comment.createdAt)}</CommentDate>
      </CommentHeader>
      <CommentBody>
        <ReactMarkdown source={comment.text} />
      </CommentBody>
    </ContentSection>
  );
};
