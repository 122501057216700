import * as Observable from "rxjs/index";
import { catchError, map, mergeAll, switchMap, tap } from "rxjs/operators/index";
import { ajaxDeleteCompany, ajaxGetCompanies } from "../../api";
import { showSuccessToast } from "../../components/Toast";
import { storeCompanies } from "../entities/actions";
import {
  DELETE_COMPANY_REQUEST,
  deleteCompanyFailure,
  deleteCompanySuccess,
  FETCH_ALL_COMPANIES_REQUEST,
  fetchAllCompaniesFailure,
  fetchAllCompaniesSuccess
} from "./actions";

export const loadAllCompaniesEpic = action$ =>
  action$.ofType(FETCH_ALL_COMPANIES_REQUEST).pipe(
    switchMap(() =>
      ajaxGetCompanies().pipe(
        map(result => result.response.data),
        map(companies => [storeCompanies(companies), fetchAllCompaniesSuccess(companies)]),
        mergeAll(),
        catchError(error => Observable.of(fetchAllCompaniesFailure(error.response)))
      )
    )
  );

export const deleteCompanyEpic = action$ =>
  action$.ofType(DELETE_COMPANY_REQUEST).pipe(
    switchMap(action =>
      ajaxDeleteCompany(action.company.id).pipe(
        map(() => deleteCompanySuccess(action.company.id)),
        tap(() => showSuccessToast(`Company "${action.company.name}" was deleted`)),
        catchError(error => Observable.of(deleteCompanyFailure(error.response)))
      )
    )
  );
