export const OPEN_ADD_COMPANY_DIALOG = "OPEN_ADD_COMPANY_DIALOG";
export const CLOSE_ADD_COMPANY_DIALOG = "CLOSE_ADD_COMPANY_DIALOG";

export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

export const ADD_COMPANY_ADMIN_REQUEST = "ADD_COMPANY_ADMIN_REQUEST";
export const ADD_COMPANY_ADMIN_SUCCESS = "ADD_COMPANY_ADMIN_SUCCESS";
export const ADD_COMPANY_ADMIN_FAILURE = "ADD_COMPANY_ADMIN_FAILURE";

export const openAddCompanyDialog = () => ({ type: OPEN_ADD_COMPANY_DIALOG });

export const closeAddCompanyDialog = () => ({ type: CLOSE_ADD_COMPANY_DIALOG });

export const addCompanyRequest = companyData => ({ type: ADD_COMPANY_REQUEST, companyData });

export const addCompanySuccess = company => ({ type: ADD_COMPANY_SUCCESS, company });

export const addCompanyFailure = error => ({ type: ADD_COMPANY_FAILURE, error });

export const addCompanyAdminRequest = userData => ({ type: ADD_COMPANY_ADMIN_REQUEST, userData });

export const addCompanyAdminSuccess = user => ({ type: ADD_COMPANY_ADMIN_SUCCESS, user });

export const addCompanyAdminFailure = error => ({ type: ADD_COMPANY_ADMIN_FAILURE, error });
