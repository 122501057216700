import * as Observable from "rxjs";
import { catchError, map, mergeAll, switchMap } from "rxjs/operators";
import { getCompanyAssetsAndServices, getCompanyDiscoveryScansComparison } from "../../api";
import {
  LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_REQUEST,
  loadNextAssetsOrSnapshotsPageFailure,
  loadNextAssetsOrSnapshotsPageSuccess
} from "./actions";

export const loadAssetsOrSnapshotsPageEpic = (action$, state) => {
  return action$.ofType(LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_REQUEST).pipe(
    switchMap(action => {
      const assetsList = state.value.assetsList;

      const queryParams = {
        page: action.page,
        sortField: action.sortField,
        sortDirection: action.sortDirection,
        text: action.textSearch
      };

      const shouldLoadServiceSnapshots =
        assetsList.selectedLeftScanId !== null && assetsList.selectedRightScanId !== null;

      return (shouldLoadServiceSnapshots
        ? getCompanyDiscoveryScansComparison(
            action.companyId,
            assetsList.selectedLeftScanId,
            assetsList.selectedRightScanId,
            queryParams
          )
        : getCompanyAssetsAndServices(action.companyId, {
            status: assetsList.shownAssetStatuses,
            ...queryParams
          })
      ).pipe(
        map(result => result.response),
        map(response => [loadNextAssetsOrSnapshotsPageSuccess(response.data, response.metadata.pagesCount)]),
        mergeAll(),
        catchError(error => Observable.of(loadNextAssetsOrSnapshotsPageFailure(error.response)))
      );
    })
  );
};
