import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import {
  performSingleVulnerabilityGet,
  performVulnerabilityCommentDelete,
  performVulnerabilityCommentPost,
  performVulnerabilityCommentsGet,
  performVulnerabilityDelete,
  performVulnerabilityPut,
  performVulnerabilityStatusPut
} from "../../api";
import HavingUserRoles from "../../components/access/HavingUserRoles";
import ContentBlock, { ContentBlockTitle, ContentSection } from "../../components/containers/ContentBlock";
import CodeBlockFactory from "../../components/containers/CodeBlock";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import FormTextArea from "../../components/controls/TextArea";
import FormField from "../../components/forms/FormVerticalField";
import Main from "../../components/layout/Main";
import TopActionBar, {
  TopActionBarButtonsContainer,
  TopActionBarLeft,
  TopActionBarRight
} from "../../components/layout/TopActionBar";
import LoadingIndicator from "../../components/misc/LoadingIndicator";
import TextWithColorIndicator from "../../components/misc/TextWithColorIndicator";
import {
  getVulnerabilitySeverityColor,
  USER_ROLE_BIZONE_ADMIN,
  USER_ROLE_BIZONE_EMPLOYEE,
  VULNERABILITY_STATUS_PENDING,
  VULNERABILITY_STATUS_RESOLVE_APPROVED,
  VULNERABILITY_STATUS_RESOLVED,
  VULNERABILITY_STATUS_WONT_FIX,
  VULNERABILITY_STATUS_FALSE_POSITIVE,
  vulnerabilitySeverityToString,
  vulnerabilityStatusToString,
  userRoleIsOnBizoneSide
} from "../../utils/enums";
import { withDefaultErrorHandling } from "../../utils/error-handling";
import { formatDate, pageTitle } from "../../utils/ui";
import useActiveUser from "../../utils/useActiveUser";
import ApproveOrCancelVulnerabilityDialog from "./VulnerabilityApprovalDialog";
import VulnerabilityComment from "./VulnerabilityComment";
import VulnerabilityDialog from "./VulnerabilityDialog";

function Code(props) {
  return 
}

const VulnerabilitySubheader = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0 0 16px;
`;

const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const PropertyDisplay = styled.div`
  flex: 0 0 ${({ fullWidth }) => (fullWidth ? "100%" : "22%")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PropertyLabel = styled.div`
  flex: 0 0 120px;
  font-size: 14px;
  font-stretch: normal;
  color: #999999;
`;

const PropertyValue = styled.div`
  flex: 1 1;
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Property = ({ label, value, fullWidth }) => {
  return (
    <PropertyDisplay fullWidth={fullWidth}>
      <PropertyLabel>{label}</PropertyLabel>
      <PropertyValue>{value}</PropertyValue>
    </PropertyDisplay>
  );
};

const VulnerabilityTopActionBar = ({ backHref, showResolveButton, onDelete, onEdit, onResolve, userRole }) => {
  return (
    <TopActionBar>
      <TopActionBarLeft>
        <Button as={Link} to={backHref}>
          Back
        </Button>
      </TopActionBarLeft>
      <TopActionBarRight>
        <TopActionBarButtonsContainer>
          <ButtonsRow>
            {showResolveButton && (
              <Button accented={true} type="button" onClick={onResolve}>
                Resolve
              </Button>
            )}
            <HavingUserRoles roles={[USER_ROLE_BIZONE_ADMIN, USER_ROLE_BIZONE_EMPLOYEE]} userRole={userRole}>
              <Button type="button" onClick={onEdit}>
                Edit
              </Button>
              <Button type="button" onClick={onDelete}>
                Delete
              </Button>
            </HavingUserRoles>
          </ButtonsRow>
        </TopActionBarButtonsContainer>
      </TopActionBarRight>
    </TopActionBar>
  );
};

export default withRouter(({ match, history }) => {
  const userRole = useActiveUser().role;

  const vulnerabilityId = +match.params.vulnerabilityId;

  const companyId = useSelector(state => state.companySwitcher.activeCompany.id);

  const [vulnerability, setVulnerability] = useState(undefined);
  const [comments, setComments] = useState(undefined);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const [formOpen, setFormOpen] = useState(false);
  const [approvalDialogIsOpen, setApprovalDialogIsOpen] = useState(false);

  const backHref = vulnerability ? `/vulnerabilities/${vulnerability.status}` : `/vulnerabilities/pending`;

  const commentInputRef = useRef(null);

  const refreshComments = useCallback(async () => {
    const commentsResponse = await performVulnerabilityCommentsGet(companyId, vulnerabilityId);
    setComments(commentsResponse.data);
  }, [companyId, vulnerabilityId]);

  const loadVulnerability = async () => {
    setLoading(true);

    try {
      const vulnerabilityResponse = await performSingleVulnerabilityGet(companyId, vulnerabilityId);
      setVulnerability(vulnerabilityResponse.data);

      await refreshComments();
    } catch (error) {
      if (error.statusCode === 404) {
        history.replace("/404");
      }

      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadVulnerability().then();
  }, []);

  const handleCommentSubmit = async () => {
    try {
      const text = commentInputRef.current.value;
      await performVulnerabilityCommentPost(companyId, vulnerabilityId, { text });

      commentInputRef.current.value = "";
      await refreshComments();
    } catch (error) {
      window.alert(JSON.stringify(error));
    }
  };

  const handleCommentDeletion = useCallback(
    async commentId => {
      if (!window.confirm("Are you sure you want to delete this comment?")) {
        return;
      }

      try {
        await performVulnerabilityCommentDelete(companyId, commentId);
        await refreshComments();
      } catch (error) {
        window.alert(JSON.stringify(error));
      }
    },
    [companyId, refreshComments]
  );

  const handleVulnerabilityDeletion = async () => {
    if (!window.confirm("Are you sure you want to delete this vulnerability?")) {
      return;
    }

    try {
      await performVulnerabilityDelete(companyId, vulnerabilityId);
      history.replace(backHref);
    } catch (error) {
      window.alert(JSON.stringify(error));
    }
  };

  const handleVulnerabilityUpdate = async (formData, onError) => {
    try {
      const response = await performVulnerabilityPut(companyId, vulnerabilityId, formData);
      setFormOpen(false);
      setVulnerability(response.data);
    } catch (error) {
      onError(error);
    }
  };

  const handleVulnerabilityApproval = async (shouldApprove, comment) =>
    withDefaultErrorHandling(async () => {
      const nextStatus = shouldApprove ? VULNERABILITY_STATUS_RESOLVE_APPROVED : VULNERABILITY_STATUS_PENDING;

      await performVulnerabilityStatusPut(companyId, vulnerabilityId, nextStatus, comment);

      setApprovalDialogIsOpen(false);
      await loadVulnerability();
    });

  const closeApprovalDialog = () => {
    setApprovalDialogIsOpen(false);
  };

  return (
    <React.Fragment>
      <VulnerabilityTopActionBar
        backHref={backHref}
        showResolveButton={
          vulnerability && vulnerability.status === VULNERABILITY_STATUS_RESOLVED && userRoleIsOnBizoneSide(userRole)
        }
        onDelete={handleVulnerabilityDeletion}
        onEdit={() => setFormOpen(true)}
        onResolve={() => setApprovalDialogIsOpen(true)}
        userRole={userRole}
      />

      <Main>
        {loading && <LoadingIndicator />}

        {!loading && error && <div>{JSON.stringify(error)}</div>}

        {!loading && !error && (
          <React.Fragment>
            <Helmet>
              <title>{pageTitle(vulnerability.name)}</title>
            </Helmet>

            <ContentBlock>
              <ContentBlockTitle>{vulnerability.name}</ContentBlockTitle>

              <ContentSection>
                <PropertiesContainer>
                  <Property label="Status" value={vulnerabilityStatusToString(vulnerability.status)} />
                  <Property label="Project" value={vulnerability.service.asset.ip} />
                  <Property
                    label="Severity"
                    value={
                      <TextWithColorIndicator
                        text={vulnerabilitySeverityToString(vulnerability.severity)}
                        color={getVulnerabilitySeverityColor(vulnerability.severity)}
                      />
                    }
                  />
                  <Property label="Date" value={formatDate(vulnerability.createdAt)} />
                  
                </PropertiesContainer>
              </ContentSection>

              <ContentSection>
                <VulnerabilitySubheader>Description</VulnerabilitySubheader>
                <ReactMarkdown source={vulnerability.description} escapeHtml={false} />
              </ContentSection>

              <ContentSection>
                <VulnerabilitySubheader>Fix recommendations</VulnerabilitySubheader>
                <ReactMarkdown source={vulnerability.fixRecommendations} escapeHtml={false} />
              </ContentSection>

              <ContentSection>
                <VulnerabilitySubheader>Expert comments</VulnerabilitySubheader>
                <ReactMarkdown source={vulnerability.expertComments} />
              </ContentSection>

              <ContentSection>
                <VulnerabilitySubheader>Vulnerable code</VulnerabilitySubheader>
                <ReactMarkdown 
                  source={vulnerability.codeSnippet}
                  renderers={{ code: CodeBlockFactory(vulnerability.vulnLine) }} />
              </ContentSection>
            </ContentBlock>

            <ContentBlock>
              <ContentBlockTitle>Comments</ContentBlockTitle>

              {comments.map(comment => (
                <VulnerabilityComment
                  key={comment.id}
                  comment={comment}
                  onDeleteClick={() => handleCommentDeletion(comment.id)}
                />
              ))}

              <ContentSection>
                <FormField label="Your comment" input={<FormTextArea ref={commentInputRef} rows={5} />} />
              </ContentSection>
              <ContentSection>
                <ButtonsRow>
                  <Button accented={true} type="submit" onClick={handleCommentSubmit}>
                    Submit
                  </Button>
                </ButtonsRow>
              </ContentSection>
            </ContentBlock>
          </React.Fragment>
        )}
      </Main>

      {formOpen && (
        <VulnerabilityDialog
          editedVulnerability={vulnerability}
          onClose={() => setFormOpen(false)}
          onSubmit={handleVulnerabilityUpdate}
        />
      )}

      {approvalDialogIsOpen && (
        <ApproveOrCancelVulnerabilityDialog
          onApproveOrCancel={handleVulnerabilityApproval}
          onClose={closeApprovalDialog}
        />
      )}
    </React.Fragment>
  );
});
