import React, { useEffect, useState } from "react";
import Centered from "../containers/Centered";
import ContentBlock from "../containers/ContentBlock";
import ModalOverlay from "./ModalOverlay";

export default ({ children, width, height }) => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => setAnimated(true), []);

  const style = { maxHeight: "calc(100vh - 120px)" };

  if (width) {
    style.width = width
  }
  if (height) {
    style.height = height
  }

  return (
    <ModalOverlay>
      <Centered>
        <ContentBlock style={style} modal={true} animated={animated}>
          {children}
        </ContentBlock>
      </Centered>
    </ModalOverlay>
  );
};
