import React from "react";
import styled from "styled-components/macro";

const FormFieldContainer = styled.div`  
  position: relative;
  display: none;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const ValidationError = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: #c8473b;
`;

export default ({ label, input, error }) => {
  return (
    <FormFieldContainer>
      <FormLabel>{label}</FormLabel>
      {input}
      {error && <ValidationError>{error.reason}</ValidationError>}
    </FormFieldContainer>
  );
};
