import React from "react";
import TextWithColorIndicator from "../../components/misc/TextWithColorIndicator";
import Table, { ColumnHeader, TableCell } from "../../components/Table";
import { getServiceStatusColor, serviceStatusToString } from "../../utils/enums";
import styled from "styled-components/macro";
import { fullAssetName } from "../../utils/ui";

const NewData = styled.div`
  & * {
    font-weight: 600;
  }
`;

const ChangedDataOld = styled.div`
  & * {
    text-decoration: line-through;
  }
`;

const DataDiff = ({ oldSnapshot, newSnapshot, selectorFunc, renderFunc }) => {
  const oldValue = oldSnapshot && selectorFunc(oldSnapshot);
  const newValue = selectorFunc(newSnapshot);

  const isNew = !oldValue;
  const changed = oldValue && oldValue !== newValue;

  if (isNew) {
    return <NewData>{renderFunc(newValue)}</NewData>;
  }

  if (changed) {
    return (
      <React.Fragment>
        <ChangedDataOld>{renderFunc(oldValue)}</ChangedDataOld>
        {renderFunc(newValue)}
      </React.Fragment>
    );
  }

  return renderFunc(newValue);
};

const ServiceSnapshotRow = ({ snapshotsPair }) => {
  const before = snapshotsPair.before;
  const after = snapshotsPair.after;

  return (
    <tr>
      <TableCell>
        <DataDiff
          oldSnapshot={before}
          newSnapshot={after}
          selectorFunc={snapshot => snapshot.asset.ip}
          renderFunc={() => <span>{fullAssetName(after.asset)}</span>}
        />
      </TableCell>
      <TableCell>
        <DataDiff
          oldSnapshot={before}
          newSnapshot={after}
          selectorFunc={snapshot => snapshot.port}
          renderFunc={port => <span>{port}</span>}
        />
      </TableCell>
      <TableCell>
        <DataDiff
          oldSnapshot={before}
          newSnapshot={after}
          selectorFunc={snapshot => snapshot.name}
          renderFunc={name => <span>{name}</span>}
        />
      </TableCell>
      <TableCell>
        <DataDiff
          oldSnapshot={before}
          newSnapshot={after}
          selectorFunc={snapshot => snapshot.status}
          renderFunc={status => (
            <TextWithColorIndicator color={getServiceStatusColor(status)} text={serviceStatusToString(status)} />
          )}
        />
      </TableCell>
    </tr>
  );
};

export default ({ serviceSnapshots, sortField, sortDirection, onSortingChanged }) => {
  return (
    <Table>
      <thead>
        <tr>
          <ColumnHeader
            sortField="ip"
            sortDirection={sortDirection}
            sorted={sortField === "ip"}
            onSortingChange={onSortingChanged}
          >
            Project
          </ColumnHeader>
          <ColumnHeader
            sortField="port"
            sortDirection={sortDirection}
            sorted={sortField === "port"}
            onSortingChange={onSortingChanged}
          >
            Port
          </ColumnHeader>
          <ColumnHeader
            sortField="name"
            sortDirection={sortDirection}
            sorted={sortField === "name"}
            onSortingChange={onSortingChanged}
          >
            Service
          </ColumnHeader>
          <ColumnHeader
            sortField="status"
            sortDirection={sortDirection}
            sorted={sortField === "status"}
            onSortingChange={onSortingChanged}
          >
            Status
          </ColumnHeader>
        </tr>
      </thead>
      <tbody>
        {serviceSnapshots.map(snapshotsPair => {
          const leftKey = snapshotsPair.before ? snapshotsPair.before.id : "none";
          const rightKey = snapshotsPair.after.id;
          const key = `${leftKey}-${rightKey}`;

          return <ServiceSnapshotRow key={key} snapshotsPair={snapshotsPair} />;
        })}
      </tbody>
    </Table>
  );
};
