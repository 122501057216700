export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const OPEN_ADD_USER_DIALOG = "OPEN_ADD_USER_DIALOG";
export const CLOSE_ADD_USER_DIALOG = "CLOSE_ADD_USER_DIALOG";

export const addUserRequest = userData => ({ type: ADD_USER_REQUEST, userData });

export const addUserSuccess = user => ({ type: ADD_USER_SUCCESS, user });

export const addUserFailure = error => ({ type: ADD_USER_FAILURE, error });

export const openAddUserDialog = () => ({ type: OPEN_ADD_USER_DIALOG });

export const closeAddUserDialog = () => ({ type: CLOSE_ADD_USER_DIALOG });
