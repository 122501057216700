export const FETCH_ALL_COMPANIES_REQUEST = "FETCH_ALL_COMPANIES_REQUEST";
export const FETCH_ALL_COMPANIES_SUCCESS = "FETCH_ALL_COMPANIES_SUCCESS";
export const FETCH_ALL_COMPANIES_FAILURE = "FETCH_ALL_COMPANIES_FAILURE";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const fetchAllCompaniesRequest = () => ({ type: FETCH_ALL_COMPANIES_REQUEST });

export const fetchAllCompaniesSuccess = companies => ({ type: FETCH_ALL_COMPANIES_SUCCESS, companies });

export const fetchAllCompaniesFailure = error => ({ type: FETCH_ALL_COMPANIES_FAILURE, error });

export const deleteCompanyRequest = company => ({ type: DELETE_COMPANY_REQUEST, company });

export const deleteCompanySuccess = companyId => ({ type: DELETE_COMPANY_SUCCESS, companyId });

export const deleteCompanyFailure = error => ({ type: DELETE_COMPANY_FAILURE, error });
