import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import workspaceSvgPath from "../../assets/images/workspace.svg";
import { chooseActiveCompanyAction } from "../../redux/company-switcher/actions";
import NavigationLink from "./NavigationLink";
import NavigationLinkIcon from "./NavigationLinkIcon";
import NavigationPopupMenu from "./NavigationPopupMenu";
import NavigationPopupMenuRow from "./NavigationPopupMenuRow";

export default withRouter(({ history }) => {
  const companySwitcher = useSelector(state => state.companySwitcher);

  const dispatch = useDispatch();

  const companiesAreAvailable = companySwitcher.availableCompanies && companySwitcher.availableCompanies.length > 0;
  if (!companiesAreAvailable) {
    return <NavigationLink as="div">No companies available</NavigationLink>;
  }

  const handleCompanySelection = company => {
    if (companySwitcher.activeCompany && company.id === companySwitcher.activeCompany.id) {
      history.push(`/companies/${company.id}`);
    } else {
      dispatch(chooseActiveCompanyAction(company));
    }
  };

  const text = companySwitcher.activeCompany ? companySwitcher.activeCompany.name : "None";

  return (
    <NavigationPopupMenu fixedWidth="240px" text={text} icon={<NavigationLinkIcon src={workspaceSvgPath} />}>
      <React.Fragment>
        {companySwitcher.availableCompanies.map(company => (
          <NavigationPopupMenuRow
            selected={companySwitcher.activeCompany.id === company.id}
            onClick={() => handleCompanySelection(company)}
            key={company.id}
          >
            <span>{company.name}</span>
          </NavigationPopupMenuRow>
        ))}
      </React.Fragment>
    </NavigationPopupMenu>
  );
});
