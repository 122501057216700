import styled from "styled-components/macro";
import { css } from "styled-components";

export const ContentSection = styled.div`
  width: 100%;
  padding: 18px 20px;
  min-height: 78px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${({ flex }) => flex && `flex: ${flex};`}

  ${({ scrollable }) =>
    scrollable
      ? css`
          overflow-y: scroll;
        `
      : css`
          overflow: hidden;
        `};

  &:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
  }

  /* Instead of requiring all elements in content sections to provide their own padding, we assign
     default padding for all content sections and make tables override this padding with their own margins.
     This is unique for the tables because they must cover all space in their content sections. */
  & table {
    min-width: calc(100% + 40px);
    max-width: calc(100% + 40px);
    position: relative;
    left: -20px;
    right: -20px;
    margin-top: -18px;
    margin-bottom: -18px;
  }
`;

export const ContentBlockTitle = styled.h2`
  margin: 0;
  padding: 0 20px;
  height: 64px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4d4d4d;
  display: flex;
  width: 100%;
  min-height: 78px;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
`;

export default styled.div`
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  ${({ modal, animated }) =>
    modal
      ? css`
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
          width: 464px;

          ${!animated
            ? css`
                opacity: 0;
                transform: translateY(-30px);
              `
            : css`
                transition: 0.15s ease-in-out;
                opacity: 1;
                transform: translateY(0);
              `}
        `
      : css`
          border: 1px solid #e6e6e6;
        `}
`;
