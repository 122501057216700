import React from "react";
import { css } from "styled-components";
import styled from "styled-components/macro";
import triangleImage from "../assets/images/triangle.svg";

const TableCellTextWrap = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const TableCellText = styled.span`
  padding: 0 20px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  overflow: hidden;
  width: 100%;
`;

const TableCellInner = styled.td`
  position: relative;
  height: 48px;
`;

export const TableCell = ({ showChildrenAsTooltip, children }) => {
  const extraCellProps = showChildrenAsTooltip ? { title: children } : {};

  return (
    <TableCellInner {...extraCellProps}>
      <TableCellTextWrap>
        <TableCellText>{children}</TableCellText>
      </TableCellTextWrap>
    </TableCellInner>
  );
};

export const DeleteActionTableCell = styled.td`
  width: 30px;
  text-align: center;

  tr > & {
    opacity: 0;
  }

  tr:hover > & {
    opacity: 1;
  }
`;

export const ColumnHeaderInner = styled.th`
  ${({ sortable }) =>
    sortable
      ? css`
          cursor: pointer;
          color: #4d4d4d !important;

          &:hover {
            background: rgba(0, 0, 0, 0.06);
          }
        `
      : css`
          color: #999999 !important;
        `}

  & div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & img {
    margin-left: 8px;
  }

  ${({ sorted, sortDirection, sortable }) => {
    sortDirection = sortDirection || "asc";

    if (!sorted || !sortable) {
      return css`
        & img {
          display: none;
        }
      `;
    }

    return sortDirection === "desc"
      ? css``
      : css`
          & img {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        `;
  }}
`;

export const ColumnHeader = ({ sortField, onSortingChange, children, sorted, sortDirection }) => {
  return (
    <ColumnHeaderInner
      onClick={() => {
        if (sortField && onSortingChange) {
          const currentSortDirection = sortDirection || "asc";
          const newSortDirection = currentSortDirection === "asc" ? "desc" : "asc";
          onSortingChange(sortField, newSortDirection);
        }
      }}
      sortable={onSortingChange}
      sorted={sorted}
      sortDirection={sortDirection}
    >
      <div>
        <span>{children}</span>
        <img src={triangleImage} alt="" />
      </div>
    </ColumnHeaderInner>
  );
};

export const GridBodyRow = styled.tr`
  ${({ selected }) => {
    if (selected)
      return css`
        background-color: rgba(24, 168, 204, 0.12);
      `;
  }}

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
    cursor: pointer;
  }
`;

export default styled.table`
  border-collapse: collapse;

  thead tr {
    border-bottom: 1px solid #f5f5f5;
  }

  th {
    height: 48px;
    color: #999999;
    font-weight: normal;
    text-align: left;
    padding: 12px 20px;
  }

  ${({ notSelectable }) =>
    !notSelectable &&
    css`
      tbody tr:hover {
        background-color: rgba(0, 0, 0, 0.06);
        cursor: pointer;
      }
    `}
`;
