import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalDialog from "../../components/modal/ModalDialog";
import { showErrorToast } from "../../components/Toast";
import { addUserRequest, closeAddUserDialog } from "../../redux/user-add/actions";
import { findPortal } from "../../utils/ui";
import UserForm from "./UserForm";

export default () => {
  const isOpen = useSelector(state => state.addUser.open);
  const isSubmitting = useSelector(state => state.addUser.submitting);
  const error = useSelector(state => state.addUser.error);

  const dispatch = useDispatch();

  const handleSubmit = formData => {
    let companyIds = [];

    if (formData.companyId !== undefined) {
      companyIds = [formData.companyId];
      delete formData.companyId;
    }

    const userData = { ...formData, companyIds };
    dispatch(addUserRequest(userData));
  };

  useEffect(() => {
    if (error) {
      showErrorToast(`Unable to create new user. ${error.message}`);
    }
  }, [error]);

  return (
    isOpen &&
    ReactDOM.createPortal(
      <ModalDialog>
        <UserForm
          title={"Add user"}
          showRoleInput
          showEmailInput
          showCompanySelect
          showCompanyContactInput
          submitting={isSubmitting}
          allowToCancel
          onSubmit={handleSubmit}
          onCancel={() => dispatch(closeAddUserDialog())}
        />
      </ModalDialog>,
      findPortal()
    )
  );
};
