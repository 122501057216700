import React from "react";
import TwoColumnLayout, { TwoColumnLayoutColumn } from "../../../components/containers/TwoColumnLayout";
import AssetsStatsWidget from "./AssetsStatsWidget";
import VulnerabilitiesStatsWidget from "./VulnerabilitiesStatsWidget";

export default ({ companyId }) => {
  return (
    <TwoColumnLayout>
      <TwoColumnLayoutColumn fillVertically={true} percentage={"27%"}>
        <AssetsStatsWidget companyId={companyId} />
      </TwoColumnLayoutColumn>

      <TwoColumnLayoutColumn fillVertically={true} percentage={"72%"}>
        <VulnerabilitiesStatsWidget companyId={companyId} />
      </TwoColumnLayoutColumn>
    </TwoColumnLayout>
  );
};
