import styled from "styled-components/macro";
import { css } from "styled-components";

export default styled.button`
  padding: 10px 16px;
  font-weight: 500;
  text-decoration: none;
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  line-height: 1.43;
  border-radius: 4px;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }

  ${({ disabled, accented }) => {
    if (accented && !disabled) {
      return css`
        color: #ffffff;
        background-color: #1696cc;
        border: 1px solid #1696cc;
        transition: filter 0.2s ease-in-out;

        &:hover {
          filter: brightness(115%);
        }
      `;
    } else if (!accented && !disabled) {
      return css`
        color: #4d4d4d;
        background: none;
        border: none;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: #2c67ca;
        }

        &:disabled {
          color: #999;

          &:hover {
            cursor: default;
            color: #999;
          }
        }
      `;
    } else if (accented && disabled) {
      return css`
        color: #c0c0c0;
        cursor: pointer;
        background-color: #919191;
        border: 1px solid #919191;
      `;
    } else if (!accented && disabled) {
      return css`
        color: #c0c0c0;
        background: none;
        border: none;
        
        &:hover {
            cursor: default;
          }
      `;
    }
  }}
`;
