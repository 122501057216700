import styled from "styled-components/macro";

export default styled.button`
  font-size: 14px;
  line-height: 1.43;
  color: #1383b3;
  border: none;
  background: none;
  transition: color 0.2s ease-in-out;
  padding: 4px 26px;

  &:hover {
    cursor: pointer;     
    color: #0b6f88;
  }
`;
