import styled from "styled-components/macro";

export default styled.input`
  font-size: 14px;
  padding: 8px 16px;
  color: #4d4d4d;
  line-height: 1.43;
  border-radius: 4px;
  border: solid 1px #b3b3b3;
  background-color: #ffffff;
  width: 100%;
`;
