import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentBlock, { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import LoadingContainer from "../../components/containers/LoadingContainer";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import { DeleteButton } from "../../components/controls/IconButtons";
import Table, { DeleteActionTableCell, TableCell } from "../../components/Table";
import { deleteCompanyRequest, fetchAllCompaniesRequest } from "../../redux/company-list/actions";
import { openAddCompanyDialog } from "../../redux/company-new/actions";

const CompaniesList = ({ history }) => {
  const companiesById = useSelector(state => state.entities.companiesById);
  const companyIds = useSelector(state => state.companiesList.companyIds);
  const loading = useSelector(state => state.companiesList.loading);
  const error = useSelector(state => state.companiesList.error);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCompaniesRequest());
  }, [dispatch]);

  const handleCompanyDeletion = company => {
    if (window.confirm(`Are you sure you want to delete company "${company.name}"?`)) {
      dispatch(deleteCompanyRequest(company));
    }
  };

  return (
    <ContentBlock>
      <ContentBlockTitle>
        Companies
        <ButtonsRow>
          <Button accented={true} type="button" onClick={() => dispatch(openAddCompanyDialog())}>
            Add
          </Button>
        </ButtonsRow>
      </ContentBlockTitle>

      <ContentSection>
        <LoadingContainer
          loading={loading}
          hasError={!!error}
          renderError={() => <div>Got error {JSON.stringify(error)}</div>}
          renderData={() => (
            <Table>
              <thead>
                <tr>
                  <th>Company</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {companyIds.length > 0 ? (
                  companyIds.map(companyId => {
                    const company = companiesById[companyId];

                    return (
                      <tr key={company.id} onClick={() => history.push(`/companies/${company.id}`)}>
                        <TableCell>{company.name}</TableCell>
                        <DeleteActionTableCell>
                          <DeleteButton
                            onClick={event => {
                              event.stopPropagation();
                              handleCompanyDeletion(company);
                            }}
                          />
                        </DeleteActionTableCell>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="2">No companies to show.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        />
      </ContentSection>
    </ContentBlock>
  );
};

export default withRouter(CompaniesList);
