import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { performLoginPost } from "../../api";
import Centered from "../../components/containers/Centered";
import ContentBlock, { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import FormInput from "../../components/controls/Input";
import FormField from "../../components/forms/FormVerticalField";
import Main from "../../components/layout/Main";
import { fetchActiveUserProfileRequest } from "../../redux/auth/actions";
import { pageTitle } from "../../utils/ui";
import useFocus from "../../utils/useFocus";
import { showErrorToast } from "../../components/Toast";

const LoginForm = styled.form`
  min-width: 464px;
`;

const LoginPage = ({ fetchActiveUserProfile }) => {
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  useFocus(emailInputRef);

  const handleFormSubmit = async event => {
    event.preventDefault();

    const email = emailInputRef.current.value;
    const password = passwordInputRef.current.value;

    try {
      await performLoginPost({ email, password });
      fetchActiveUserProfile();
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle("Log In")}</title>
      </Helmet>

      <Main>
        <Centered>
          <ContentBlock>
            <LoginForm onSubmit={handleFormSubmit}>
              <ContentBlockTitle>Login</ContentBlockTitle>

              <ContentSection>
                <FormField label="Email" input={<FormInput ref={emailInputRef} type="text" />} />
                <FormField label="Password" input={<FormInput ref={passwordInputRef} type="password" />} />
              </ContentSection>
              <ContentSection>
                <ButtonsRow>
                  <Button accented={true} type="submit">
                    Log In
                  </Button>
                </ButtonsRow>
              </ContentSection>
            </LoginForm>
          </ContentBlock>
        </Centered>
      </Main>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchActiveUserProfile: () => dispatch(fetchActiveUserProfileRequest())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LoginPage);
