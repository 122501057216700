import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalDialog from "../../components/modal/ModalDialog";
import { showErrorToast } from "../../components/Toast";
import { addCompanyAdminRequest } from "../../redux/company-new/actions";
import { USER_ROLE_COMPANY_ADMIN } from "../../utils/enums";
import { findPortal } from "../../utils/ui";
import UserForm from "./UserForm";

export default () => {
  const companyId = useSelector(state => state.addCompany.companyStep.createdCompanyId);
  const isOpen = useSelector(state => state.addCompany.companyAdminStep.isDialogOpen);
  const isSubmitting = useSelector(state => state.addCompany.companyAdminStep.isSubmitting);
  const error = useSelector(state => state.addCompany.companyAdminStep.error);

  const dispatch = useDispatch();

  const handleSubmit = formData => {
    const userData = { ...formData, companyIds: [companyId], role: USER_ROLE_COMPANY_ADMIN };
    dispatch(addCompanyAdminRequest(userData));
  };

  useEffect(() => {
    if (error) {
      showErrorToast(`Unable to create new user. ${error.message}`);
    }
  }, [error]);

  return (
    isOpen &&
    ReactDOM.createPortal(
      <ModalDialog>
        <UserForm
          title={"Add company administrator"}
          showRoleInput={false}
          showEmailInput
          showCompanyContactInput
          submitting={isSubmitting}
          allowToCancel={false}
          onSubmit={handleSubmit}
        />
      </ModalDialog>,
      findPortal()
    )
  );
};
