import React from "react";
import * as ReactDOM from "react-dom";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import ModalDialog from "../../components/modal/ModalDialog";
import { findPortal, fullAssetName } from "../../utils/ui";

export default ({ asset, onApprove, onClose }) => {
  return ReactDOM.createPortal(
    <form
      onSubmit={event => {
        event.preventDefault();
        onApprove(asset);
      }}
    >
      <ModalDialog>
        <ContentBlockTitle>Approve asset</ContentBlockTitle>

        <ContentSection>Are you sure you want to approve asset {fullAssetName(asset)}?</ContentSection>

        <ContentSection>
          <ButtonsRow>
            <Button accented={true} type="submit">
              Approve
            </Button>
            <Button onClick={onClose} type="button">
              Cancel
            </Button>
          </ButtonsRow>
        </ContentSection>
      </ModalDialog>
    </form>,
    findPortal()
  );
};
