import { stringify } from "querystring";
import {useDispatch} from "react-redux";
import { throwError } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import { clearActiveUserProfile } from "../redux/auth/actions";
import store from "../redux/store";

const stripUndefined = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
}

const performFetch = async (url, options) => {
  const response = await fetch(url, {
    ...options,
    credentials: "include"
  });

  if (!response.ok) {
    if (response.status === 401 && store.auth) {
      store.dispatch(clearActiveUserProfile());
    }
    throw await response.json();
  }

  return await response.json();
};

export const performGet = (url, params = undefined) => {
  if (params) {
    stripUndefined(params);
    url += "?" + stringify(params);
  }

  return performFetch(url, {
    method: "GET"
  });
};

export const performPost = (url, body) => {
  return performFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });
};

export const performPut = (url, body) => {
  return performFetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });
};

export const performDelete = url => {
  return performFetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const request = options => {
  options = {
    ...options,
    headers: {
      ...options.headers,
      "Content-Type": "application/json"
    },
    withCredentials: true
  };

  return ajax(options).pipe(
    map(action => action),
    catchError(error => {
      if (error.status === 401) {
        const dispatch = useDispatch();
        dispatch(clearActiveUserProfile());
      }
      return throwError(error);
    })
  );
};

export const getRequest = (url, params) => {
  if (params) {
    stripUndefined(params);
    url += "?" + stringify(params);
  }

  return request({ url, method: "GET" });
};

export const postRequest = (url, data = {}) => {
  return request({ url, method: "POST", body: JSON.stringify(data) });
};

export const putRequest = (url, data = {}) => {
  return request({ url, method: "PUT", body: JSON.stringify(data) });
};

export const deleteRequest = url => {
  return request({ url, method: "DELETE" });
};
