export const ASSETS_KIND_AGREED = "agreed";

export const CHANGE_SHOWN_ASSETS_KIND = "CHANGE_SHOWN_ASSETS_KIND";
export const SELECT_DISCOVERY_SCANS_FOR_COMPARISON = "SELECT_DISCOVERY_SCANS_FOR_COMPARISON";

export const LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_REQUEST = "LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_REQUEST";
export const LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_SUCCESS = "LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_SUCCESS";
export const LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_FAILURE = "LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_FAILURE";

export const changeShownAssetsKind = kind => ({ type: CHANGE_SHOWN_ASSETS_KIND, kind });

export const selectDiscoveryScansForComparison = (leftScanId, rightScanId) => ({
  type: SELECT_DISCOVERY_SCANS_FOR_COMPARISON,
  leftScanId,
  rightScanId
});

export const loadAssetsOrSnapshotsPageRequest = (companyId, page, textSearch, sortField, sortDirection) => ({
  type: LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_REQUEST,
  companyId,
  page,
  textSearch,
  sortField,
  sortDirection
});

export const loadNextAssetsOrSnapshotsPageSuccess = (items, pagesCount) => ({
  type: LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_SUCCESS,
  items,
  pagesCount
});

export const loadNextAssetsOrSnapshotsPageFailure = error => ({
  type: LOAD_NEXT_ASSETS_OR_SNAPSHOTS_PAGE_FAILURE,
  error
});
