import React, { useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as yup from "yup";
import { ContentBlockTitle, ContentSection } from "../../components/containers/ContentBlock";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import FormInput from "../../components/controls/Input";
import FormField from "../../components/forms/FormVerticalField";
import ModalDialog from "../../components/modal/ModalDialog";
import { showErrorToast } from "../../components/Toast";
import { changePasswordRequest, closeChangePasswordDialog } from "../../redux/change-password/actions";
import { USER_ROLE_BIZONE_ADMIN } from "../../utils/enums";
import { findPortal } from "../../utils/ui";
import useActiveUser from "../../utils/useActiveUser";
import useFocus from "../../utils/useFocus";
import { validate } from "../../utils/validation";

const ChangePasswordForm = styled.form`
  min-width: 464px;
`;

const createValidationScheme = validateCurrentPassword => {
  const shape = {
    newPassword: yup
      .string()
      .min(10)
      .required("Please enter you new password"),
    confirmNewPassword: yup
      .string()
      .min(10)
      .oneOf([yup.ref("newPassword")], "Passwords do not match")
      .required("Please confirm your new password")
  };

  if (validateCurrentPassword) {
    shape.currentPassword = yup.string().required("Please enter you current password");
  }

  return yup.object().shape(shape);
};

export default () => {
  const userId = useSelector(state => state.changePassword.userId);
  const isOpen = useSelector(state => state.changePassword.dialogOpen);
  const isSubmitting = useSelector(state => state.changePassword.submitting);
  const error = useSelector(state => state.changePassword.error);

  const activeUser = useActiveUser();

  const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = useState({});

  const currentPasswordInput = useRef(null);
  const newPasswordInput = useRef(null);
  const confirmNewPasswordInput = useRef(null);

  const isAdmin = activeUser.role === USER_ROLE_BIZONE_ADMIN;

  const handleSubmit = async event => {
    event.preventDefault();

    const passwordData = {
      currentPassword: !isAdmin ? currentPasswordInput.current.value : undefined,
      newPassword: newPasswordInput.current.value
    };

    const formData = {
      ...passwordData,
      confirmNewPassword: confirmNewPasswordInput.current.value
    };

    try {
      const validationScheme = createValidationScheme(!isAdmin);
      await validate(validationScheme, formData);
    } catch (errors) {
      setValidationErrors(errors);
      return;
    }

    dispatch(changePasswordRequest(userId, passwordData));
  };

  useEffect(() => {
    if (error) {
      showErrorToast(JSON.stringify(error));
    }
  }, [error]);

  useFocus(currentPasswordInput, isOpen, [isOpen]);

  return (
    isOpen &&
    ReactDOM.createPortal(
      <ModalDialog>
        <ChangePasswordForm onSubmit={handleSubmit}>
          <ContentBlockTitle>Change your password</ContentBlockTitle>

          <ContentSection>
            {!isAdmin && (
              <FormField
                label="Current password"
                input={<FormInput ref={currentPasswordInput} type="password" />}
                error={validationErrors.currentPassword}
              />
            )}

            <FormField
              label="New password"
              input={<FormInput ref={newPasswordInput} type="password" />}
              error={validationErrors.newPassword}
            />

            <FormField
              label="Confirm new password"
              input={<FormInput ref={confirmNewPasswordInput} type="password" />}
              error={validationErrors.confirmNewPassword}
            />
          </ContentSection>

          <ContentSection>
            <ButtonsRow>
              <Button accented={true} type="submit" disabled={isSubmitting}>
                Submit
              </Button>
              <Button onClick={() => dispatch(closeChangePasswordDialog())} type="button">
                Cancel
              </Button>
            </ButtonsRow>
          </ContentSection>
        </ChangePasswordForm>
      </ModalDialog>,
      findPortal()
    )
  );
};
