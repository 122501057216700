import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { performApiKeyDelete, performApiKeyPost, performApiKeysGet } from "../../api";
import ContentBlock, { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import LoadingContainer from "../../components/containers/LoadingContainer";
import Main from "../../components/layout/Main";
import { arrayCopyWithoutElement } from "../../utils/collections";
import { withDefaultErrorHandling } from "../../utils/error-handling";
import { pageTitle } from "../../utils/ui";
import ApiKeysTable from "./ApiKeysTable";
import ApiKeysTopActionBar from "./ApiKeysTopActionBar";

export default () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [apiKeys, setApiKeys] = useState(undefined);

  const loadApiKeys = useCallback(async () => {
    setLoading(true);

    try {
      const apiKeysResponse = await performApiKeysGet();
      setApiKeys(apiKeysResponse.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [setApiKeys, setError, setLoading]);

  useEffect(() => {
    loadApiKeys();
  }, [loadApiKeys]);

  const createApiKey = useCallback(
    async () =>
      await withDefaultErrorHandling(async () => {
        await performApiKeyPost();
        loadApiKeys();
      }),
    [loadApiKeys]
  );

  const deleteApiKey = useCallback(
    async apiKey =>
      await withDefaultErrorHandling(async () => {
        await performApiKeyDelete(apiKey.id);

        setApiKeys(arrayCopyWithoutElement(apiKeys, apiKey));
      }),
    [apiKeys, setApiKeys]
  );

  const title = "API Keys";

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle(title)}</title>
      </Helmet>

      <ApiKeysTopActionBar onCreate={createApiKey} />

      <Main>
        <LoadingContainer
          loading={loading}
          error={!!error}
          renderError={() => <div>Got error {JSON.stringify(error)}</div>}
          renderData={() => (
            <ContentBlock>
              <ContentBlockTitle>{title}</ContentBlockTitle>

              <ContentSection>
                {apiKeys.length > 0 ? (
                  <ApiKeysTable apiKeys={apiKeys} onDelete={deleteApiKey} />
                ) : (
                  <div>No api keys to show.</div>
                )}
              </ContentSection>
            </ContentBlock>
          )}
        />
      </Main>
    </React.Fragment>
  );
};
