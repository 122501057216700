export const OPEN_CHANGE_PASSWORD_DIALOG = "OPEN_CHANGE_PASSWORD_DIALOG";
export const CLOSE_CHANGE_PASSWORD_DIALOG = "CLOSE_CHANGE_PASSWORD_DIALOG";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const openChangePasswordDialog = (userId) => ({type: OPEN_CHANGE_PASSWORD_DIALOG, userId});

export const closeChangePasswordDialog = () => ({type: CLOSE_CHANGE_PASSWORD_DIALOG});

export const changePasswordRequest = (userId, passwordData) => ({type: CHANGE_PASSWORD_REQUEST, userId, passwordData});

export const changePasswordSuccess = (user) => ({type: CHANGE_PASSWORD_SUCCESS, user});

export const changePasswordFailure = (userId) => ({type: CHANGE_PASSWORD_FAILURE, userId});