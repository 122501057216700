import styled from "styled-components/macro";

export default styled.div`
  max-width: 1280px;
  min-width: 1000px;
  margin: 0 auto;
  padding: 0 32px;
  width: 100%;
  flex: 1 0;
  display: flex;
  flex-direction: column;
`;
