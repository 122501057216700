export const STORE_AVAILABLE_COMPANIES = "STORE_AVAILABLE_COMPANIES";
export const CHOOSE_ACTIVE_COMPANY = "CHOOSE_ACTIVE_COMPANY";

export const storeAvailableCompaniesAction = companies => {
  return { type: STORE_AVAILABLE_COMPANIES, companies };
};

export const chooseActiveCompanyAction = company => {
  return { type: CHOOSE_ACTIVE_COMPANY, company };
};
