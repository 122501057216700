import { useSelector } from "react-redux";

export default () => {
  return useSelector(state => {
    const usersById = state.entities.usersById;
    const auth = state.auth;

    if (auth && auth.userId) {
      return usersById[auth.userId];
    }
  });
};
