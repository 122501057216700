import * as Observable from "rxjs";
import { catchError, map, mergeAll, switchMap } from "rxjs/operators";
import { ajaxGetCompanyUsers, ajaxGetSingleCompany } from "../../api";
import { storeCompanies, storeUsers } from "../entities/actions";
import {
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_USERS_REQUEST,
  fetchCompanyFailure,
  fetchCompanySuccess,
  fetchCompanyUsersFailure,
  fetchCompanyUsersSuccess
} from "./actions";

export const loadCompanyEpic = action$ =>
  action$.ofType(FETCH_COMPANY_REQUEST).pipe(
    switchMap(action =>
      ajaxGetSingleCompany(action.companyId).pipe(
        map(result => result.response.data),
        map(company => [storeCompanies([company]), fetchCompanySuccess(company)]),
        mergeAll(),
        catchError(error => Observable.of(fetchCompanyFailure(error.response)))
      )
    )
  );

export const loadCompanyUsersEpic = action$ =>
  action$.ofType(FETCH_COMPANY_USERS_REQUEST).pipe(
    switchMap(action =>
      ajaxGetCompanyUsers(action.companyId).pipe(
        map(result => result.response.data),
        map(users => [storeUsers(users), fetchCompanyUsersSuccess(action.companyId, users)]),
        mergeAll(),
        catchError(error => Observable.of(fetchCompanyUsersFailure(error.response)))
      )
    )
  );
