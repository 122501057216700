import * as Observable from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { getWidgetTypes, postUserWidgets } from "../../api";
import { showSuccessToast } from "../../components/Toast";
import {
  LOAD_WIDGET_TYPES_REQUEST,
  loadWidgetTypesFailure,
  loadWidgetTypesSuccess,
  SAVE_ENABLED_WIDGETS_REQUEST,
  saveEnabledWidgetsFailure,
  saveEnabledWidgetsSuccess
} from "./actions";

export const loadWidgetTypesEpic = action$ =>
  action$.ofType(LOAD_WIDGET_TYPES_REQUEST).pipe(
    switchMap(() =>
      Observable.from(getWidgetTypes()).pipe(
        map(result => loadWidgetTypesSuccess(result.response.data)),
        catchError(error => Observable.of(loadWidgetTypesFailure(error.response)))
      )
    )
  );

export const saveEnabledWidgetsEpic = action$ =>
  action$.ofType(SAVE_ENABLED_WIDGETS_REQUEST).pipe(
    switchMap(action => {
      return Observable.from(
        postUserWidgets(action.userId, { ids: action.widgetTypes.map(widgetType => widgetType.id) })
      ).pipe(
        tap(() => showSuccessToast("Dashboard configuration was saved")),
        map(() => saveEnabledWidgetsSuccess(action.widgetTypes)),
        catchError(error => Observable.of(saveEnabledWidgetsFailure(error.response)))
      );
    })
  );
