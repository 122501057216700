export const OPEN_EDIT_USER_DIALOG = "OPEN_EDIT_USER_DIALOG";
export const CLOSE_EDIT_USER_DIALOG = "CLOSE_EDIT_USER_DIALOG";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const openEditUserDialog = userId => ({ type: OPEN_EDIT_USER_DIALOG, userId });

export const closeEditUserDialog = () => ({ type: CLOSE_EDIT_USER_DIALOG });

export const updateUserRequest = (userId, userData) => ({ type: UPDATE_USER_REQUEST, userId, userData });

export const updateUserSuccess = (userId, userData) => ({ type: UPDATE_USER_SUCCESS, userId, userData });

export const updateUserFailure = (userId, error) => ({ type: UPDATE_USER_FAILURE, userId, error });
