import React from "react";
import { Helmet } from "react-helmet";
import Main from "../../components/layout/Main";
import { pageTitle } from "../../utils/ui";

export default () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle("Page Not Found")}</title>
      </Helmet>

      <Main>
        <h1>Page Not Found</h1>
      </Main>
    </React.Fragment>
  );
};
