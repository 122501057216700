import styled from "styled-components/macro";
import { css } from "styled-components";

export default styled.div`
  display: inline-block;
  margin-right: 8px;

  ${({ color }) =>
    css`
      background-color: ${color};
    `}

  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
    border-radius: ${Math.floor(size / 2)}px;
  `}
`;
