import React from "react";
import { css } from "styled-components";
import styled from "styled-components/macro";
import triangleImagePath from "../../assets/images/triangle.svg";

const Grid = styled.table`
  display: block;
  width: 100%;
`;

const GridHeader = styled.thead`
  display: block;
  width: 100%;
`;

const GridBody = styled.tbody`
  display: block;
  width: 100%;
`;

const GridHeaderRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const GridHeaderCell = styled.th`
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;

  & span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & img {
    margin-left: 8px;
  }

  padding: 0 ${({ notPadded }) => (notPadded ? "0" : "20px")};
  justify-content: ${({ align }) => align || "left"};
  min-width: ${({ minWidth }) => minWidth || "unset"};
  max-width: ${({ maxWidth }) => maxWidth || "unset"};
  flex: ${({ widthPercentage }) => widthPercentage || "0"} 0 ${({ minWidth }) => minWidth || "auto"};

  ${({ sortable }) =>
    sortable
      ? css`
          cursor: pointer;
          color: #4d4d4d;

          &:hover {
            background: rgba(0, 0, 0, 0.06);
          }
        `
      : css`
          color: #999999;
        `}

  ${({ sortable, sorted, sortDirection }) => {
    if (!sortable || !sorted) {
      return css`
        & img {
          display: none;
        }
      `;
    }

    return sortDirection === "asc"
      ? css`
          & img {
            display: inline-block;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        `
      : css`
          & img {
            display: inline-block;
          }
        `;
  }}
`;

const GridBodyRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;

  ${({ selectable }) =>
    selectable &&
    css`
      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
        cursor: pointer;
      }
    `}
`;

const GridBodyCell = styled.td`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;

  & span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  padding: 0 ${({ notPadded }) => (notPadded ? "0" : "20px")};
  justify-content: ${({ align }) => align || "left"};
  min-width: ${({ minWidth }) => minWidth || "unset"};
  max-width: ${({ maxWidth }) => maxWidth || "unset"};
  flex: ${({ widthPercentage }) => widthPercentage || "0"} 0 ${({ minWidth }) => minWidth || "auto"};
`;

export default ({
  schema,
  items,
  renderItem,
  getItemKey,
  shouldShowColumn = () => true,
  onItemClick,
  onSortingChanged,
  sortKey,
  sortDirection
}) => {
  const handleColumnHeaderClick = column => {
    if (!column.sortable) return;

    let newSortField = column.key;
    let newSortDirection = "asc";

    if (column.key === sortKey) {
      if (sortDirection === "asc") {
        newSortDirection = "desc";
      } else if (sortDirection === "desc") {
        newSortField = undefined;
        newSortDirection = undefined;
      }
    }

    onSortingChanged && onSortingChanged(newSortField, newSortDirection);
  };

  return (
    <Grid>
      <GridHeader>
        <GridHeaderRow>
          {schema.columns.map(
            column =>
              shouldShowColumn(column.key) && (
                <GridHeaderCell
                  key={column.key}
                  align={column.align}
                  minWidth={column.minWidth}
                  maxWidth={column.maxWidth}
                  widthPercentage={column.widthPercentage}
                  notPadded={column.notPadded}
                  sortable={column.sortable}
                  sorted={column.key === sortKey}
                  sortDirection={sortDirection}
                  onClick={() => handleColumnHeaderClick(column)}
                >
                  {column.name}
                  <img src={triangleImagePath} alt="" />
                </GridHeaderCell>
              )
          )}
        </GridHeaderRow>
      </GridHeader>
      <GridBody>
        {items.map((item, itemIndex) => (
          <GridBodyRow selectable={onItemClick} key={getItemKey(item)} onClick={() => onItemClick && onItemClick(item)}>
            {schema.columns.map(
              column =>
                shouldShowColumn(column.key) && (
                  <GridBodyCell
                    key={`${getItemKey(item)}-${column.key}`}
                    align={column.align}
                    minWidth={column.minWidth}
                    maxWidth={column.maxWidth}
                    widthPercentage={column.widthPercentage}
                    notPadded={column.notPadded}
                  >
                    {renderItem(item, column.key, itemIndex)}
                  </GridBodyCell>
                )
            )}
          </GridBodyRow>
        ))}
      </GridBody>
    </Grid>
  );
};
