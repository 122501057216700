import {
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_USERS_FAILURE,
  FETCH_COMPANY_USERS_REQUEST,
  FETCH_COMPANY_USERS_SUCCESS
} from "./actions";

const initialState = {
  company: {
    id: null,
    loading: true,
    error: null
  },
  users: {
    userIds: null,
    loading: true,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_REQUEST: {
      return {
        ...state,
        company: {
          id: action.companyId,
          loading: true,
          error: null
        }
      };
    }

    case FETCH_COMPANY_SUCCESS: {
      return {
        ...state,
        company: {
          ...state.company,
          loading: false,
          error: null
        }
      };
    }

    case FETCH_COMPANY_FAILURE: {
      return {
        ...state,
        company: {
          ...state.company,
          loading: false,
          error: action.error
        }
      };
    }

    case FETCH_COMPANY_USERS_REQUEST: {
      return {
        ...state,
        users: {
          userIds: null,
          loading: true,
          error: null
        }
      };
    }

    case FETCH_COMPANY_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          userIds: action.users.map(user => user.id),
          loading: false,
          error: null
        }
      };
    }

    case FETCH_COMPANY_USERS_FAILURE: {
      return {
        ...state,
        users: {
          userIds: null,
          loading: false,
          error: action.error
        }
      };
    }

    default: {
      return state;
    }
  }
};
