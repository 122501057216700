import {
  ADD_COMPANY_ADMIN_SUCCESS,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  CLOSE_ADD_COMPANY_DIALOG,
  OPEN_ADD_COMPANY_DIALOG,
  ADD_COMPANY_FAILURE,
  ADD_COMPANY_ADMIN_FAILURE
} from "./actions";

const initialState = {
  companyStep: {
    isDialogOpen: false,
    isSubmitting: false,
    error: null,
    createdCompanyId: null
  },
  companyAdminStep: {
    isDialogOpen: false,
    isSubmitting: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ADD_COMPANY_DIALOG:
      return {
        companyStep: {
          isDialogOpen: true,
          isSubmitting: false,
          error: null,
          createdCompanyId: null
        },
        companyAdminStep: {
          isDialogOpen: false,
          isSubmitting: false,
          error: null
        }
      };

    case CLOSE_ADD_COMPANY_DIALOG:
      return {
        companyStep: {
          isDialogOpen: false,
          isSubmitting: false,
          error: null,
          createdCompanyId: null
        },
        companyAdminStep: {
          isDialogOpen: false,
          isSubmitting: false,
          error: null
        }
      };

    case ADD_COMPANY_REQUEST: {
      return {
        ...state,
        companyStep: {
          ...state.companyStep,
          isSubmitting: true,
          error: null
        }
      };
    }

    case ADD_COMPANY_SUCCESS: {
      return {
        companyStep: {
          isDialogOpen: false,
          isSubmitting: false,
          error: null,
          createdCompanyId: action.company.id
        },
        companyAdminStep: {
          isDialogOpen: true,
          isSubmitting: false,
          error: null
        }
      };
    }

    case ADD_COMPANY_FAILURE: {
      return {
        ...state,
        companyStep: {
          ...state.companyStep,
          error: action.error,
          isSubmitting: false
        }
      };
    }

    case ADD_COMPANY_ADMIN_SUCCESS: {
      return {
        companyStep: {
          isDialogOpen: false,
          isSubmitting: false,
          error: null,
          createdCompanyId: null
        },
        companyAdminStep: {
          isDialogOpen: false,
          isSubmitting: false,
          error: null
        }
      };
    }

    case ADD_COMPANY_ADMIN_FAILURE: {
      return {
        ...state,
        companyAdminStep: {
          ...state.companyAdminStep,
          isSubmitting: false,
          error: action.error
        }
      };
    }

    default: {
      return state;
    }
  }
};
