import { mergeAll, mergeMap } from "rxjs/operators";
import { getMe, performCompaniesGet } from "../../api";
import { storeAvailableCompaniesAction } from "../company-switcher/actions";
import { storeUsers } from "../entities/actions";
import {
  FETCH_ACTIVE_USER_PROFILE_REQUEST,
  fetchActiveUserProfileFailure,
  fetchActiveUserProfileSuccess
} from "./actions";

export const fetchActiveUserProfileEpic = action$ =>
  action$.ofType(FETCH_ACTIVE_USER_PROFILE_REQUEST).pipe(
    mergeMap(async () => {
      try {
        const userProfile = (await getMe()).data;
        const availableCompanies = (await performCompaniesGet()).data;

        return [
          storeUsers([userProfile]),
          storeAvailableCompaniesAction(availableCompanies),
          fetchActiveUserProfileSuccess(userProfile)
        ];
      } catch (error) {
        return [fetchActiveUserProfileFailure(undefined)];
      }
    }),
    mergeAll()
  );
