import React, { useCallback, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import Button from "../../components/controls/Button";
import ButtonsRow from "../../components/controls/ButtonsRow";
import Checkbox from "../../components/controls/Checkbox";
import { ContentSection, ContentBlockTitle } from "../../components/containers/ContentBlock";
import ModalDialog from "../../components/modal/ModalDialog";
import Table, { TableCell } from "../../components/Table";
import { arrayCopyWithoutElement } from "../../utils/collections";
import { findPortal } from "../../utils/ui";
import { showWarningToast } from "../../components/Toast";

export default ({ onCancel, onSubmit, assets }) => {
  const [ipsAndHostnamePairs, setIpsAndHostnamePairs] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);

  useEffect(() => {
    let hostnamesWithoutIpsCount = 0;

    setIpsAndHostnamePairs(
      assets.reduce((result, host) => {
        if (host.ips.length === 0) {
          hostnamesWithoutIpsCount++;
        }

        host.ips.forEach(ip => {
          const hostname = host.hostname;
          result.push({ ip, hostname });
        });
        return result;
      }, [])
    );

    if (hostnamesWithoutIpsCount > 0) {
      showWarningToast(`${hostnamesWithoutIpsCount} hostnames were not resolved!`);
    }
  }, [assets, setIpsAndHostnamePairs]);

  const handleCheckboxChange = useCallback(
    (event, index) => {
      if (!event.target.checked) {
        setSelectedIndices(arrayCopyWithoutElement(selectedIndices, index));
      } else {
        setSelectedIndices([...selectedIndices, index]);
      }
    },
    [selectedIndices, setSelectedIndices]
  );

  const submitForm = useCallback(
    event => {
      event.preventDefault();

      const selectedIpAndHostnamePairs = selectedIndices.map(index => ipsAndHostnamePairs[index]);
      onSubmit(selectedIpAndHostnamePairs);
    },
    [ipsAndHostnamePairs, selectedIndices, onSubmit]
  );

  return ReactDOM.createPortal(
    <form onSubmit={submitForm}>
      <ModalDialog>
        <ContentBlockTitle>Add projects</ContentBlockTitle>

        <ContentSection scrollable>
          {ipsAndHostnamePairs.length === 0 ? (
            <div>No hostnames were resolved</div>
          ) : (
            <Table notSelectable>
              <thead>
                <tr>
                  <th>IP Address</th>
                  <th>Hostname</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {ipsAndHostnamePairs.map(({ ip, hostname }, index) => (
                  <tr key={`${hostname}-${ip}`}>
                    <TableCell>{ip}</TableCell>
                    <TableCell>{hostname}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedIndices.includes(index)}
                        onChange={event => handleCheckboxChange(event, index)}
                      />
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ContentSection>

        <ContentSection>
          <ButtonsRow>
            <Button accented={true} type="submit">
              Submit
            </Button>
            <Button onClick={onCancel} type="button">
              Cancel
            </Button>
          </ButtonsRow>
        </ContentSection>
      </ModalDialog>
    </form>,
    findPortal()
  );
};
