import React from "react";
import styled from "styled-components/macro";
import Circle from "../shapes/Circle";

const StatusIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

/**
 * Отображает круглый цветовой индикатор слева и текст справа.
 * Нужен преимущественно для отображения состояния сервиса или критичности уязвимости.
 */
export default ({ color, text }) => {
  return (
    <StatusIndicatorContainer>
      <Circle size={6} color={color} /> {text}
    </StatusIndicatorContainer>
  );
};
